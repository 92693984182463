import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { EncargosService } from '../../_services/encargos.service';
import {} from 'googlemaps';
import { from } from 'rxjs';

//Iconos para encargos por estado
var iconBase = 'http://maps.google.com/mapfiles/ms/icons/';
var icons = {
   Finalizado: {
      icon: iconBase + 'green-dot.png'
   },
   Archivado: {
      icon: iconBase + 'orange-dot.png'
   },
   Redaccion: {
      icon: iconBase + 'blue-dot.png'
   },
   Tramite: {
      icon: iconBase + 'yellow-dot.png'
   },
   Ejecucion: {
      icon: iconBase + 'red-dot.png'
   },
   Anulado: {
      icon: iconBase + 'ltblue-dot.png'
   }
};

const filtres = {
   Finalizado:true,
   Archivado:true,
   Redaccion:true,
   Tramite:true,
   Ejecucion:true,
   Anulado:true
};

function get_set_options() {
   const ret_array = []
   for (var option in filtres) {
      if (filtres[option]) {
         ret_array.push(option)
      }
   }
   return ret_array;
}
function setFiltres(id_val) {
   if (filtres[id_val]) {
      filtres[id_val] = false
   } else {
      filtres[id_val] = true
   }
}

@Component({
   selector: 'mapa',
   templateUrl: 'mapa.component.html',
   providers: [EncargosService],
   styleUrls: ['../../_helpers/components.component.css', 'mapa.component.css']
})

export class MapaComponent implements OnInit {

   @Input() proyecto;
   @Output() Lat = new EventEmitter<number>();
   @Output() Lng = new EventEmitter<number>();

   @ViewChild('map', {static: true}) mapElement: any;

   filtraFeatures() {
      var set_filters = get_set_options();
      this.puntsFiltrats = 0;  
      this.map.data.setStyle( (feature) => {
         const showFeature = set_filters.includes(feature.getProperty('estat'));
         if (showFeature){
            this.puntsFiltrats++;
         };
         return ({
            icon: icons[feature.getProperty("estat")].icon,
            visible: showFeature
         });
      });
   }
   
   setFiltre(estado){
      setFiltres(estado);
      this.filtraFeatures();
   }

   constructor(
      public encargoService: EncargosService,
      public route: Router,
      public http: HttpClient
   ){}
   
   //Variables globales
   map: google.maps.Map;
   mapOptions: any;
   totalFeatures: number;
   puntsFiltrats: number;
   WMS_Cadastre: any;
   WMS_IDEIB: any;
   WMS_UnidadesAdministrativas: any;
   referenciaCatastral: any;
   pc1: any;
   pc2: any;
   direccionCatastro: any;
   municipioCatastro: any;
   provinciaCatastro: any;
   loading = false;

   ngOnInit() {
      //Defineix el WMS del Cadastre
      var WMS_Cadastre = new google.maps.ImageMapType({
         getTileUrl(coord, zoom) {
            var proj = mapa.getProjection();
            var zfactor = Math.pow(2, zoom);
            // Obte les coordenades Long Lat
            var top = proj.fromPointToLatLng(new google.maps.Point(coord.x * 256 / zfactor, coord.y * 256 / zfactor));
            var bot = proj.fromPointToLatLng(new google.maps.Point((coord.x +1) * 256 / zfactor, (coord.y +1) * 256 / zfactor));
            //Crear la cadena del Bounding box
            var bbox = (top.lng()) + "," + (bot.lat()) + "," + (bot.lng()) + "," + (top.lat());
            //URL del servei WMS del Cadastre
            var url = "http://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx?";
            url += "REQUEST=GetMap"; //Operacio WMS
            url += "&SERVICE=WMS"; //Servei WMS
            url += "&VERSION=1.1.1"; //Versio WMS
            url += "&SRS=EPSG:4326"; //Estableix WGS84
            url += "&BBOX=" + bbox; //Estableix el bounding box
            url += "&WIDTH=256"; //Estableix ample
            url += "&HEIGHT=256"; //Estableix alt
            url += "&FORMAT=image/png"; //Format WMS
            url += "&TRANSPARENT=TRUE"; //Transparencia activada
            url += "&LAYERS=catastro";
            return url;
            },
            name: "cadastre",
            tileSize: new google.maps.Size(256, 256)
   //         opacity: .5,
            //isPng: false
      });
      this.WMS_Cadastre = WMS_Cadastre;
      
      //Defineix el WMS URBANISME BALEARS
      var WMS_IDEIB = new google.maps.ImageMapType({
         getTileUrl(coord, zoom) {
            var proj = mapa.getProjection();
            var zfactor = Math.pow(2, zoom);
            // Obte les coordenades Long Lat
            var top = proj.fromPointToLatLng(new google.maps.Point(coord.x * 256 / zfactor, coord.y * 256 / zfactor));
            var bot = proj.fromPointToLatLng(new google.maps.Point((coord.x +1) * 256 / zfactor, (coord.y +1) * 256 / zfactor));
            //Crear la cadena del Bounding box
            var bbox = (top.lng()) + "," + (bot.lat()) + "," + (bot.lng()) + "," + (top.lat());
            //URL del servei WMS del IDEIB
   //         var url = "http://ideib.caib.es/pub_ideib/public/MUIB/MapServer/WMSServer?";
            var url = "https://ideib.caib.es/geoserveis/services/public/GOIB_MUIB/MapServer/WMSServer?";
            url += "&VERSION=1.1.1"; //Versio WMS
            url += "&REQUEST=GetMap"; //Operacio WMS
            url += "&SERVICE=WMS"; //Servei WMS
            url += "&SRS=EPSG:4326"; //Estableix WGS84
            url += "&WIDTH=256"; //Estableix ample
            url += "&HEIGHT=256"; //Estableix alt
            url += "&LAYERS=1,2";
            url += "&STYLES=default, default";
            url += "&TRANSPARENT=TRUE"; //Transparencia activada
            url += "&FORMAT=image/png"; //Format WMS
            url += "&BBOX=" + bbox; //Estableix el bounding box
            return url;
            },
            name: "urbanisme",
            tileSize: new google.maps.Size(256, 256),
            opacity: .5
            //isPng: false
      });
      this.WMS_IDEIB = WMS_IDEIB;

      //Defineix el WMS de les Unitats Administratives
      var WMS_UnidadesAdministrativas = new google.maps.ImageMapType({
         getTileUrl: function(coord, zoom) {
            var proj = mapa.getProjection();
            var zfactor = Math.pow(2, zoom);
            // Obte les coordenades Long Lat
            var top = proj.fromPointToLatLng(new google.maps.Point(coord.x * 256 / zfactor, coord.y * 256 / zfactor));
            var bot = proj.fromPointToLatLng(new google.maps.Point((coord.x +1) * 256 / zfactor, (coord.y +1) * 256 / zfactor));
            //Crear la cadena del Bounding box
            var bbox = (top.lng()) + "," + (bot.lat()) + "," + (bot.lng()) + "," + (top.lat());
            //URL del servei WMS del IGN
            var url = "http://www.ign.es/wms-inspire/unidades-administrativas?map=/var/local/slp/krtinyWMS.map&";
            url += "REQUEST=GetMap"; //Operacio WMS
            url += "&SERVICE=WMS"; //Servei WMS
            url += "&VERSION=1.1.1"; //Versio WMS
            url += "&LAYERS=" + "AU.AdministrativeUnit";
            url += "&FORMAT=image/png"; //Format WMS
            url += "&BGCOLOR=0xFFFFFF"; //Color del fondo
            url += "&TRANSPARENT=TRUE"; //Transparencia activada
            url += "&SRS=EPSG:4326"; //Estableix WGS84
            url += "&BBOX=" + bbox; //Estableix el bounding box
            url += "&WIDTH=256"; //Estableix ample
            url += "&HEIGHT=256"; //Estableix alt
            return url;
            },
            name: "administrativas",
            tileSize: new google.maps.Size(256, 256)
            //isPng: true
      });
   this.WMS_UnidadesAdministrativas = WMS_UnidadesAdministrativas;


      //Caso se muestra un encargo
      if(this.proyecto){
         this.mapOptions = {
            center: new google.maps.LatLng(this.proyecto.geometry.coordinates[1], this.proyecto.geometry.coordinates[0]),
            zoom: 18,
            disableDoubleClickZoom: true,
            mapTypeId: google.maps.MapTypeId.HYBRID,
            mapTypeControlOptions: {
               mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID, 'PNOA', 'Raster'],
            }
         };
         this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);
         var mapa = this.map;
         var marker = new google.maps.Marker({
            position: {lat: this.proyecto.geometry.coordinates[1], lng: this.proyecto.geometry.coordinates[0]},
            map: this.map,
            title: this.proyecto.properties.codi,
            draggable: true,
            animation: google.maps.Animation.DROP              
            //icon: "/src/app/assets/brand/GesNormLogo40px.png"
         });
         //Actualitzar coordenades posicio amb es 'dragable' marker.
         google.maps.event.addListener(marker, 'drag', () => {
            this.Lat.emit(marker.getPosition().lat());
            this.Lng.emit(marker.getPosition().lng());
         });
         //Enviar dades a l'alert on es posicioni es marker.
         google.maps.event.addListener(marker, 'dragend', () => {
            geocodePosition(marker.getPosition());
         });
      } else {
         //Caso general todos los encargos
         this.mapOptions = {
            center: new google.maps.LatLng(38.89, 1.41),
            zoom: 10.8,
            disableDoubleClickZoom: true,
            mapTypeId: google.maps.MapTypeId.HYBRID,
            mapTypeControlOptions: {
               mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID, 'PNOA', 'Raster'],
            }
         };
         const observable$ = from(this.encargoService.getFeatures());
         this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);
         var mapa = this.map;
         observable$.subscribe(encargos => {
            this.totalFeatures = encargos.features.length;
            this.puntsFiltrats = this.totalFeatures;
            this.map.data.addGeoJson(encargos);
            //this.filtraFeatures();            
            this.map.data.setStyle( (feature) => {
               return ({
                  icon: icons[feature.getProperty('estat')].icon
               });
            });
         });
         this.map.addListener('dblclick', (e) => {
            this.referenciaCatastral = '';
            this.direccionCatastro = '';
            this.loading = true;
            var proj = mapa.getProjection();
            var zfactor = Math.pow(2, mapa.getZoom());
            // Obte les coordenades Long Lat
            var top = proj.fromPointToLatLng(new google.maps.Point(e.latLng.lng() * 256 / zfactor, e.latLng.lat() * 256 / zfactor));
            var bot = proj.fromPointToLatLng(new google.maps.Point((e.latLng.lng() +1) * 256 / zfactor, (e.latLng.lat() +1) * 256 / zfactor));
            //Crear la cadena del Bounding box
            var bbox = (top.lng()) + "," + (bot.lat()) + "," + (bot.lng()) + "," + (top.lat());
            //const catastroUrl = 'https://ovc.catastro.meh.es/ovcservweb/OVCSWLocalizacionRC/OVCCoordenadas.asmx/Consulta_RCCOOR?SRS=EPSG:4326&Coordenada_X=' + e.latLng.lng() + '&Coordenada_Y=' + e.latLng.lat();
            /*var catastroUrl = 'http://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx?';
            catastroUrl += "REQUEST=GetFeatureInfo";
            catastroUrl += "&SERVICE=WMS"; //Servei WMS
            catastroUrl += "&VERSION=1.1.1"; //Versio WMS
            catastroUrl += "&SRS=EPSG:4326"; //Estableix WGS84
            catastroUrl += "&X=" + e.latLng.lng();
            catastroUrl += "&Y=" + e.latLng.lat();
            catastroUrl += "&BBOX=" + bbox; //Estableix el bounding box
            catastroUrl += "&WIDTH=256"; //Estableix ample
            catastroUrl += "&HEIGHT=256"; //Estableix alt
            catastroUrl += "&INFO_FORMAT=text/html"; //Format WMS
            catastroUrl += "&QUERY_LAYERS=Catastro"; //Transparencia activada
            catastroUrl += "&LAYERS=Catastro";
            
            var catastroUrl = 'https://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx?';
            catastroUrl += "REQUEST=GetFeatureInfo";
            catastroUrl += "&VERSION=1.0"; //Versio WMS
            catastroUrl += "&SRS=EPSG:4326"; //Estableix WGS84
            catastroUrl += "&X=" + e.latLng.lng();
            catastroUrl += "&Y=" + e.latLng.lat();
            catastroUrl += "&BBOX=" + bbox; //Estableix el bounding box
            catastroUrl += "&WIDTH=256"; //Estableix ample
            catastroUrl += "&HEIGHT=256"; //Estableix alt
            catastroUrl += "&INFO_FORMAT=text/html"; //Format WMS
            catastroUrl += "&QUERY_LAYERS=Catastro"; //Transparencia activada
            catastroUrl += "&LAYERS=Catastro";
            
            var catastroUrl = 'https://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx?';
            catastroUrl += "REQUEST=GetFeatureInfo";
            catastroUrl += "&VERSION=1.0"; //Versio WMS
            catastroUrl += "&SRS=EPSG:4326"; //Estableix WGS84
            catastroUrl += "&X=" + e.latLng.lng();
            catastroUrl += "&Y=" + e.latLng.lat();
            catastroUrl += "&BBOX=" + bbox; //Estableix el bounding box
            catastroUrl += "&WIDTH=256"; //Estableix ample
            catastroUrl += "&HEIGHT=256"; //Estableix alt
            catastroUrl += "&INFO_FORMAT=text/html"; //Format WMS
            catastroUrl += "&QUERY_LAYERS=Catastro"; //Transparencia activada
            catastroUrl += "&LAYERS=Catastro";
            */
            const catastroUrl = 'https://ovc.catastro.meh.es/ovcservweb/OVCSWLocalizacionRC/OVCCoordenadas.asmx/Consulta_RCCOOR?SRS=EPSG:4326&Coordenada_X=' + e.latLng.lng() + '&Coordenada_Y=' + e.latLng.lat();
            const api = '/api/ovcservweb/OVCSWLocalizacionRC/OVCCoordenadas.asmx/Consulta_RCCOOR?SRS=EPSG:4326&Coordenada_X=' + e.latLng.lng() + '&Coordenada_Y=' + e.latLng.lat();
            const catastroUrl2 = 'https://ovc.catastro.meh.es/ovcservweb/OVCSWLocalizacionRC/OVCCallejero.asmx/Consulta_DNPRC?Provincia=&Municipio=&RC=';
            const httpOptions = {
               headers: new HttpHeaders({ 'Content-Type': 'text/xml; charset=utf-8' }),
            };
            this.http.get(api, {responseType: 'text'}).subscribe(result => {
               this.loading = false;
               this.pc1 = result.slice(result.indexOf('<pc1>')+5, result.indexOf('</pc1>'));
               this.pc2 = result.slice(result.indexOf('<pc2>')+5, result.indexOf('</pc2>'));
               this.referenciaCatastral =  this.pc1 + this.pc2;
               this.direccionCatastro = result.slice(result.indexOf('<ldt>')+5, result.indexOf('</ldt>'));
               this.http.get(catastroUrl2 + this.referenciaCatastral, {responseType: 'text'}).subscribe(res => {
                  console.log(res);
                  this.provinciaCatastro = res.slice(res.indexOf('<cp>')+4, res.indexOf('</cp>'));
                  this.municipioCatastro = res.slice(res.indexOf('<cm>')+4, res.indexOf('</cm>'));
               });
            });
         });
         
         //Afegeix infoWindow on click a cada projecte
         var lastInfoWindow = new google.maps.InfoWindow(); //ultima infoWindow oberta
         this.map.data.addListener('click', (event) => {
            if (lastInfoWindow) {
               lastInfoWindow.close();
            }
            var infoWindow = new google.maps.InfoWindow({
               //content: 'Ref.Proyecto: ' + event.feature.getProperty("codi") + '</br>Objeto: ' + event.feature.getProperty("descripcio") + '</br>Dirección: ' + event.feature.getProperty("direccio") + '</br><a href="/encargoDetalle/' + event.feature.getProperty("id") +'">Ir al Proyecto</a>'
               content: 'Ref.Proyecto: ' + event.feature.getProperty("codi") + '</br>Objeto: ' + event.feature.getProperty("descripcio") + '</br>Dirección: ' + event.feature.getProperty("direccio") + '</br><strong>Doble Click para abrir proyecto</strong>'
            });
            infoWindow.setPosition(event.feature.getGeometry().get());
            infoWindow.setOptions({pixelOffset: new google.maps.Size(0, -30)});
            infoWindow.open(this.map);
            lastInfoWindow = infoWindow;
         });
      }
         //Afexeix capes WMS
         //this.map.overlayMapTypes.push(WMS_UnidadesAdministrativas);
         //this.map.overlayMapTypes.push(WMS_Cadastre);
         //this.map.overlayMapTypes.push(WMS_IDEIB);
         //this.map.overlayMapTypes.push(WMS_PTI);

      this.map.data.addListener('dblclick', (event) => {
         this.route.navigate(['/encargoDetalle/' + event.feature.getProperty("id")]);
      });
      
      //Treu adreça referenciada
      var geocoder = new google.maps.Geocoder();
      function geocodePosition(pos) {
         geocoder.geocode({
            location: pos
         }, function(responses) {
            if (responses && responses.length > 0) {
               alert ('La dirección seleccionada es:\n' + responses[0].formatted_address);
            } else {
               alert ('No ha sido posible obtener la dirección de la posición indicada');
            }
         });
      }
   }

   setCatastro(){
      switch(this.map.overlayMapTypes.getLength()){
         case 0:
            this.map.overlayMapTypes.push(this.WMS_Cadastre);
            break;
         case 1:
            if(this.map.overlayMapTypes.getAt(0).name === "cadastre"){
               this.map.overlayMapTypes.clear();
            } else {
               this.map.overlayMapTypes.push(this.WMS_Cadastre);
            }
            break;
         case 2:
            if(this.map.overlayMapTypes.getAt(0).name === "cadastre"){
               this.map.overlayMapTypes.removeAt(0);
            } else {
               if(this.map.overlayMapTypes.getAt(1).name === "cadastre"){
                  this.map.overlayMapTypes.removeAt(1);
               } else {
               this.map.overlayMapTypes.push(this.WMS_Cadastre);
               }
            }
            break;
         case 3:
            if(this.map.overlayMapTypes.getAt(0).name === "cadastre"){
               this.map.overlayMapTypes.removeAt(0);
            } else {
               if(this.map.overlayMapTypes.getAt(1).name === "cadastre"){
                  this.map.overlayMapTypes.removeAt(1);
               } else {
                  if( this.map.overlayMapTypes.getAt(2).name === "cadastre"){
                     this.map.overlayMapTypes.removeAt(2);
                  } else {
                     this.map.overlayMapTypes.push(this.WMS_Cadastre);
                  }
               }
            }
            break;
      }
   }

   setUrbanismo(){
      switch(this.map.overlayMapTypes.getLength()){
         case 0:
            this.map.overlayMapTypes.push(this.WMS_IDEIB);
            break;
         case 1:
            if(this.map.overlayMapTypes.getAt(0).name === "urbanisme"){
               this.map.overlayMapTypes.clear();
            } else {
               this.map.overlayMapTypes.push(this.WMS_IDEIB);
            }
            break;
         case 2:
            if(this.map.overlayMapTypes.getAt(0).name === "urbanisme"){
               this.map.overlayMapTypes.removeAt(0);
            } else {
               if(this.map.overlayMapTypes.getAt(1).name === "urbanisme"){
                  this.map.overlayMapTypes.removeAt(1);
               } else {
               this.map.overlayMapTypes.push(this.WMS_IDEIB);
               }
            }
            break;
         case 3:
            if(this.map.overlayMapTypes.getAt(0).name === "urbanisme"){
               this.map.overlayMapTypes.removeAt(0);
            } else {
               if(this.map.overlayMapTypes.getAt(1).name === "urbanisme"){
                  this.map.overlayMapTypes.removeAt(1);
               } else {
                  if( this.map.overlayMapTypes.getAt(2).name === "urbanisme"){
                     this.map.overlayMapTypes.removeAt(2);
                  } else {
                     this.map.overlayMapTypes.push(this.WMS_IDEIB);
                  }
               }
            }
            break;
      }
   }

   setUnidadesAdministrativas(){
      switch(this.map.overlayMapTypes.getLength()){
         case 0:
            this.map.overlayMapTypes.push(this.WMS_UnidadesAdministrativas);
            break;
         case 1:
            if(this.map.overlayMapTypes.getAt(0).name === "administrativas"){
               this.map.overlayMapTypes.clear();
            } else {
               this.map.overlayMapTypes.push(this.WMS_UnidadesAdministrativas);
            }
            break;
         case 2:
            if(this.map.overlayMapTypes.getAt(0).name === "administrativas"){
               this.map.overlayMapTypes.removeAt(0);
            } else {
               if(this.map.overlayMapTypes.getAt(1).name === "administrativas"){
                  this.map.overlayMapTypes.removeAt(1);
               } else {
               this.map.overlayMapTypes.push(this.WMS_UnidadesAdministrativas);
               }
            }
            break;
         case 3:
            if(this.map.overlayMapTypes.getAt(0).name === "administrativas"){
               this.map.overlayMapTypes.removeAt(0);
            } else {
               if(this.map.overlayMapTypes.getAt(1).name === "administrativas"){
                  this.map.overlayMapTypes.removeAt(1);
               } else {
                  if( this.map.overlayMapTypes.getAt(2).name === "administrativas"){
                     this.map.overlayMapTypes.removeAt(2);
                  } else {
                     this.map.overlayMapTypes.push(this.WMS_UnidadesAdministrativas);
                  }
               }
            }
            break;
      }
   }
   
}