import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { Interaccion, Encargo, Entidad } from '../../../_models';
import { stitch, EntidadesService, InteraccionesService } from '../../../_services';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'formInteraccion',
    templateUrl: 'formInteraccion.component.html',
    providers: [InteraccionesService, EntidadesService],
    styleUrls: ['formInteraccion.component.css']
})

export class FormInteraccionComponent {
    
    @Input() interaccion: Interaccion;
    @Input() encargo: Encargo;
    @Input() pare: Entidad;
    @Input() titular: Entidad;
    @Output() interaccionSet = new EventEmitter<any[]>();

    interaccionForm : FormGroup;
    submitted = false;
    loading = false;
    fechaInteraccion: any;
    _id: any;
    emailEntidadAutorizado: String;
    administrador: Boolean;

    constructor(
        public stitch: stitch,
        public interaccionService: InteraccionesService,
        public entidadService: EntidadesService,
        public modalService: NgbModal,
        public router: Router,
        public fb: FormBuilder
        ){
            this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
        }

    open(content) {
        this.submitted = false;
        if (this.interaccion._id === ''){
            if (!this.administrador){
                alert('El Timeline del Encargo no es modificable.');
                return;
            };
            this.interaccionForm = this.fb.group({
                _id: [{value: this.interaccion._id, disabled: true}],
                fecha: ['', Validators.required],
                texto: [''],
                asunto: ['', Validators.required],
                estado: ['', Validators.required]
              });
        } else {
            this.drawInteraccionForm();
        };
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result;
    }

    get f() { return this.interaccionForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.loading = true;
        //this.commentForm.controls.fecha.setErrors(null);
        // stop here if form is invalid
        if (this.interaccionForm.invalid) {
            this.loading = false;
            return;
        }
        if(!this.administrador){
            alert('El Timeline del Encargo no es modificable.');
            return;
        }
        this.interaccionForm.disable();
        if (this.f._id.value === ''){
            this._id = new BSON.ObjectId();
        } else {
            this._id = this.f._id.value;
        };
        if ( this.f.fecha.value.length === 24 ) {
            this.fechaInteraccion = this.f.fecha.value;
        } else {
            const fecha1 = new Date(this.f.fecha.value.year, this.f.fecha.value.month - 1, this.f.fecha.value.day);
            this.fechaInteraccion = fecha1.toISOString();
        };
        const interaccion = [
            this._id,
            this.fechaInteraccion,
            this.f.asunto.value,
            this.f.texto.value,
            this.f.estado.value,
            this.encargo._id,
            this.encargo.properties.codi,
            this.encargo.properties.descripcio,
            this.encargo.emailsAutorizados];
        this.interaccionService.setInteraccionById(interaccion)
        .then(() => {
            this.loading = false;
            this.interaccionSet.emit(interaccion);
            this.submitted = false;
            this.modalService.dismissAll();
        });
    }
    
    /**
     * Elimina una interacció del projecte.
     * @param {ObjectId} id - El id de la interacció.
    */
    delInteraccion(id){
        this.interaccionForm.disable();
        this.loading = true;
        this.interaccionService.delInteraccionById(id)
        .then(result => {
            this.loading = false;
            this.interaccionSet.emit();
            this.submitted = false;
            this.modalService.dismissAll();
        });
    }

    drawInteraccionForm(){
        this.interaccionForm =  this.fb.group({
            _id: [{value: this.interaccion._id, disabled: true}],
            fecha: [{value: this.interaccion.fecha, disabled: true}],
            asunto: [this.interaccion.asunto, Validators.required],
            estado: [this.interaccion.estado, Validators.required],
            texto: [this.interaccion.texto]
        });
        if(!this.administrador){
            this.interaccionForm.disable();
        };
    }
}