export class Entidad {
    constructor(
        public _id: Object,
        public Rao: string,
        public CifNif: String,
        public Direccion: string,
        public Cp: String,
        public Localidad: string,
        public Municipio: string,
        public DQE: String,
        public email: string,
        public Telefono: string,
        public web: string,
        public emailsAutorizados: String[]
        )
    {}
}