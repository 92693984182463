import { Component, enableProdMode } from '@angular/core';
enableProdMode();
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { stitch, TicketsService } from '../_services';
import { environment } from '../environments/environment';
import { Ticket } from '../_models';
@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
    providers: [TicketsService],
    styleUrls: ['app.component.css']
})

export class AppComponent {
    
    currentUser: any;
    superUser: any;
    env: any;
    ticketActivo: Ticket;
    ticketsOberts: any;

    constructor (
        public router: Router,
        public stitch: stitch,
        public modalService: NgbModal,
        public ticketsService: TicketsService
    ) {
        this.stitch.currentUser.subscribe(x => {
            this.currentUser = x;
            this.env = environment;
            if(this.currentUser) {
                this.superUser = environment.superUsers.includes(this.currentUser.profile.email);
                if (this.env.features.tickets){
                    this.ticketsService.getTicketsObertsByEmail(this.currentUser.profile.email)
                    .then(ticket => {
                        if(ticket){
                            this.ticketActivo = {
                                _id: ticket._id,
                                fecha: ticket.fecha,
                                estat: ticket.estat,
                                admin: {
                                    _id: ticket.admin._id,
                                    email: ticket.admin.email
                                },
                                encargo: {
                                    _idEncargo: ticket.encargo._idEncargo,
                                    codi: ticket.encargo.codi
                                },
                                temps: ticket.temps,
                                tarea: ticket.tarea,
                                texto: ticket.texto
                            }; 
                        } else {
                            this.ticketActivo = {
                                _id: '',
                                fecha: null,
                                estat: '',
                                admin: {
                                    _id: '',
                                    email: ''
                                },
                                encargo: {
                                    _idEncargo: '',
                                    codi: ''
                                },
                                temps: null,
                                tarea: '',
                                texto: ''
                            }; 
                        }
                    });
                }
            }
        });
    }

    logout() {
        this.ticketsService.getTicketsAdminByEmail(this.currentUser.profile.data.email)
        .then( ticketsUser => {
            if(ticketsUser.find( ticket => (ticket.estat === 'Obert'))){
                alert('Existe un ticket abierto para este usuario. \nCierrelo antes de terminar la sesión.');
                return;
            } else {
                if(confirm('¿Está seguro que desea cerrar la sesión?')){
                    this.stitch.logout();
                    this.router.navigate(['/login']);
                };        
            };
        });
    }

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'}).result;
    }

    procesaSetUser(currentUser) {
        if(currentUser){
        alert('Datos del administrador actualizados correctamente');
        } else { return }
    }


    procesaSetTicket(ticket) {
        if(ticket) {
            this.ticketActivo = ticket;
        } else {
            this.ticketActivo._id = '';
        };
    }

}