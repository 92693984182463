import { Component, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Factura } from '../../../_models/factura.model';
import { stitch, FacturasService, EncargosService, FacturasTableService, EncargosTableService } from '../../../_services';
import { NgbdSortableHeader, SortEvent } from '../../../_helpers/sortable.directive';

import {AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import Chart from 'chart.js/auto';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'facturas',
    templateUrl: 'facturasTable.component.html',
    providers: [{provide: FacturasService, useClass: FacturasTableService}, {provide: EncargosService, useClass: EncargosTableService}],
    styleUrls: ['../../../_helpers/components.component.css']
})

export class FacturasComponent implements AfterViewInit {
    showSpinner = true;
    facturas: Factura[];
    facturas$: Observable<any[]>;
    total$: Observable<number>;
    totalAños$: Observable<number[]>;
    superUser: Boolean;
    env: any;


    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    @ViewChild('chart', {static: true})
    public chartRef: ElementRef;
    public chart: Chart;
    public data: any[] = [];

    constructor(
        public stitch: stitch,
        public route: Router,
        public facturasTableService: FacturasService,
        public encargosTableService: EncargosService,)
        {
            this.facturas$ = facturasTableService.facturas$;
            this.total$ = facturasTableService.total$;
            this.totalAños$ = facturasTableService.totalAños$;
            this.superUser = environment.superUsers.includes(this.stitch.client.auth.user.profile.email);
            this.env = environment; 
        }

    ngAfterViewInit() {
        if(environment.features.graphs){
            this.facturasTableService.getFacturas()
            .subscribe(facturas => {
                var años = facturas.map(facturas => new Date(facturas.fechafactura).getFullYear()).filter((v, i, a) => a.indexOf(v) === i).reverse();            
                for (let i of años){
                    var facturasTmp = facturas.filter(factura => new Date(factura.fechafactura).getFullYear() == i);
                    var totalAño = facturasTmp.map(factura => Number(factura.baseimponible)).reduce((acumulador ,base) => acumulador + base, 0);
                    this.data.push(totalAño);
                    this.chart.update();
                };
            });
            this.newChart(this.data);
        }
    }
    
    openProjecte(id) {
        this.route.navigate(['/encargoDetalle/' + id]);
    }

    updateChart(){
        
        if(this.facturasTableService.year == 0){
            this.facturasTableService.totalAños$
            .subscribe(result => {
                this.chart.destroy();
                this.newChart(result)
            });
        } else {
            this.facturasTableService.totalAños$
            .subscribe(result => {
                this.chart.destroy();
                this.newYearChart(result);
            });
        }
    }

    newChart(data){
        this.chart = new Chart(this.chartRef.nativeElement, {
            type: 'bar',
            data: {
            labels: ['2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'],
            datasets: [{
                label: 'Total Facturación',
                data: data,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
                }]
            },
        });     
    }

    newYearChart(data){
        this.chart = new Chart(this.chartRef.nativeElement, {
            type: 'bar',
            data: {
            labels: ['Enero', 'Febero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                datasets: [{
                    label: 'Total Facturación Anual',
                    data: data,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
        });     
    }


    onSort({column, direction}: SortEvent) {
        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });
        this.facturasTableService.sortColumn = column;
        this.facturasTableService.sortDirection = direction;
    }

    setEstado(estado){
        var estados = this.facturasTableService.estados;
        if(estados.includes(estado)){
            const index = estados.indexOf(estado);
            if( index > -1) {
                estados.splice(index,1);
            }
            this.facturasTableService.estados = estados;
        } else {
            estados.push(estado);
            this.facturasTableService.estados = estados;
        }
    }

    onSearchTermChange(newValue: string) {
        this.encargosTableService.searchTerm = newValue;
    }

    onYearChange(year: number) {
        this.encargosTableService.year = year;
    }
}