<div class="container-fluid mh-100">
    <div class="row">
        <div class="col-4">
            <div *ngIf="entidad">
                <h2 class="font-weight-light">{{ entidad?.Rao }} <span style="cursor: pointer"><svg (click)="copyElementToClipBoard(entidad?.Rao)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-copy" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg></span><formEntidad [entidad]="entidad" [user]="actorExistent" (entidadSet)="procesaSet($event)"></formEntidad></h2>
                <h2 class="font-weight-light">{{ entidad?.CifNif }}<span style="cursor: pointer"><svg (click)="copyElementToClipBoard(entidad?.CifNif)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-copy" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg></span></h2>
                <h4 class="font-weight-light">{{ entidad?.Direccion}}<span style="cursor: pointer"><svg (click)="copyElementToClipBoard(entidad?.Direccion)" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-copy" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg></span></h4>
                <h4 class="font-weight-light">{{ entidad?.Cp }} - {{ entidad?.Localidad }}<span style="cursor: pointer"><svg (click)="copyElementToClipBoard(entidad?.Cp + ' - ' + entidad?.Localidad)" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-copy" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg></span></h4>
            </div>
        </div>
        <div class="col-5">
            <canvas #chart width="auto" height="auto"></canvas>
        </div>
        <div *ngIf="(superUser || loading) && env.features.facturacion" class="col-3 d-flex justify-content-end">
            <div class="container">
                <div class="row justify-content-end">
                    <h6 class="font-weight-light" style="display: flex; align-items: center;"><span placement="left" ngbTooltip="Total presupuestado por los encargos en los que la entidad figura como padre, titular o ha liquidado alguna factura." style="cursor:pointer;"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"/></svg></span> Total Presupuestos: {{totalPresupuestado | currency:'EUR':'symbol':'1.2-2':'es' }}</h6>
                </div>
                <div class="row justify-content-end">
                <h6 class="font-weight-light">Total Facturado: {{ totalFacturado | currency:'EUR':'symbol':'1.2-2':'es' }}</h6>        
                </div>
                <div class="row justify-content-end">
                    <h6 class="font-weight-bolder text-danger" style="display: flex; align-items: center;"><span placement="left" ngbTooltip="De los encargos en los que la entidad figura como padre, titular o ha liquidado alguna factura." style="cursor:pointer;"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"/></svg></span> Pendiente Facturación: {{ totalPresupuestado - totalLiquidado | currency:'EUR':'symbol':'1.2-2':'es' }}</h6>        
                </div>
            </div>
        </div>
    </div>
    <hr class="my-4">
<ngb-tabset type="pills">
<ngb-tab title="Encargos">
    <ng-template ngbTabContent><span class="badge badge-warning" style="position: relative; bottom:3rem; left: 5.8rem;">{{encargosEntidad?.length}}</span><br>
        <h3 class="font-weight-light">Listado de encargos <formEncargo *ngIf="administrador" [encargo]="encargoBuit" [pare]="entidad" [titular]="entidad" (encargoSet)="procesaSetEncargo($event)"></formEncargo><formTitularidad *ngIf="administrador" [entidadTitular]="entidad" [encargo]="{id: ''}" (titularidadSet)="procesaSetTitularidad($event)"></formTitularidad></h3>
        <div *ngIf="encargosEntidad?.length > 0">
            <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [(pageSize)]="pageSize" [collectionSize]="encargosEntidad.length" [boundaryLinks]="true"></ngb-pagination>
            <table class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'>
                <thead>
                    <tr>
                        <th style="width: 5%;">Código</th>
                        <th>Objeto</th>
                        <th *ngIf="(superUser || loading) && env.features.facturacion">Presupuestado</th>
                        <th class="dataCol">Fecha</th>
                        <th data-visible="false">Estado</th>
                    </tr>
                </thead>
                <tbody>
                        <tr *ngFor="let proyecto of encargosEntidad | slice: (page-1) * pageSize: (page-1) * pageSize + pageSize" (click)="goToEncargo(proyecto._id)" [ngClass]="{'table-success': proyecto.properties.estat === 'Redaccion', 'table-danger': proyecto.properties.estat === 'Ejecucion', 'table-warning': proyecto.properties.estat === 'Tramite'}">
                        <td><a routerLink="/encargoDetalle/{{ proyecto._id }}">{{ proyecto.properties.codi }}</a></td>
                        <td>{{ proyecto.properties.descripcio }}</td>
                        <td *ngIf="(superUser || loading) && env.features.facturacion">{{ proyecto.properties.totalPresupuestado | currency:'EUR':'symbol':'1.2-2':'es' }}</td>
                        <td>{{ proyecto.properties.data | date:'yyy-MM-dd' }}</td>
                        <td data-visible="false">{{ proyecto.properties.estat }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-template>
</ngb-tab>
<ngb-tab title="Documentos">
    <ng-template ngbTabContent><span class="badge badge-warning" style="position: relative; bottom:3rem; left: 13.8rem;">{{documentosEntidad?.length}}</span><br>
        <div class="row">
            <div class="col-sm-12">
                <h3 class="font-weight-light">Documentación de la entidad <formDocumento *ngIf="administrador" [documento]="{_id: ''}" [user]="actorExistent" [encargo]="{_id: 'null'}" [entidadPare]="entidad" [entidadTitular]="{_id: ''}" (documentoSet)="procesaSetDocumento($event)"></formDocumento></h3>
                <div *ngIf="documentosEntidad?.length > 0">
                    <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [(pageSize)]="pageSize" [collectionSize]="documentosEntidad.length" [boundaryLinks]="true"></ngb-pagination>
                    <table class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'> 
                        <thead>
                            <tr>
                                <th class="dataCol">Fecha</th>
                                <th style="width: 2%">File</th>
                                <th>Descripción</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lineaDocumento of documentosEntidad | slice: (page-1) * pageSize: (page-1) * pageSize + pageSize" (click)="openToEditDocumento(editarDocumento, lineaDocumento)">
                                <td>{{ lineaDocumento.fecha | date:'yyy-MM-dd' }}</td>
                                <td><span style="cursor: pointer"><a href="{{lineaDocumento.url}}" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/><path d="M0 0h24v24H0z" fill="none"/></svg></a></span></td>
                                <td>{{ lineaDocumento.descripcion }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="showSpinner" class="d-flex justify-content-center">
                        <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>     
                </div>
            </div>
        </div>
    </ng-template>
</ngb-tab>
<ngb-tab title="Personal">
    <ng-template ngbTabContent><span class="badge badge-warning" style="position: relative; bottom:3rem; left: 20rem;">{{actoresEntidad?.length}}</span><br>
        <div class="row">
            <div class="col-sm-12">
                <h3 class="font-weight-light">Personal relacionado <formActor *ngIf="administrador" [actor]="{_id: ''}" [entidad]="entidad" (actorSet)="procesaSetActor($event)"></formActor></h3>
                <div *ngIf="actoresEntidad?.length > 0">
                    <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [(pageSize)]="pageSize" [collectionSize]="actoresEntidad.length" [boundaryLinks]="true"></ngb-pagination>
                    <table class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <!--<th>Apellido 1</th>
                                <th>Apellido 2</th>-->
                                <th>Teléfono</th>
                                <th>email</th>
                                <th>Rol</th>
                                <th>Notas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let actor of actoresEntidad | slice: (page-1) * pageSize: (page-1) * pageSize + pageSize" (click)="goToActor(actor._id)">
                                <td><a routerLink="/actorDetalle/{{actor._id}}">{{ actor.Nom }} {{actor.Cognom1}} {{actor.Cognom2}}</a></td>
                                <!--<td>{{ actor.Cognom1 }}</td>
                                <td>{{ actor.Cognom2 }}</td>-->
                                <td><a href= "tel: {{actor.Telefono}}">{{ actor.Telefono }}</a></td>
                                <td><a href= "mailto: {{ actor.email }}">{{ actor.email }}</a></td>
                                <td>{{ actor.Rol }}</td>
                                <td>{{ actor.notas | slice:0:80 }}...</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-template>
</ngb-tab>
<ngb-tab title="Participa">
    <ng-template ngbTabContent><br>
        <h3 class="font-weight-light">Listado de participaciones <formParticipacion [entidad]="entidad" [encargo]="{_id: ''}" (participacionSet)="procesaSetParticipacion($event)"></formParticipacion></h3>
        <div *ngIf="participacionesEntidad?.length > 0">
            <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [(pageSize)]="pageSize" [collectionSize]="participacionesEntidad.length" [boundaryLinks]="true"></ngb-pagination>
            <table class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'>
                <thead>
                    <tr>
                        <th style="width: 5%;">Código</th>
                        <th>Objeto</th>
                        <th class="dataCol">Fecha</th>
                        <th style="width: 10%;">Rol</th>
                        <th>Encargo</th>
                        <th class="dataCol" data-visible="false">Estado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let participacion of participacionesEntidad | slice: (page-1) * pageSize: (page-1) * pageSize + pageSize" [ngClass]="{'table-success': participacion.estat === 'Redaccion', 'table-danger': participacion.estat === 'Ejecucion', 'table-warning': participacion.estat === 'Tramite'}">
                        <td><a routerLink="/encargoDetalle/{{ participacion._id }}">{{ participacion.codi }}</a></td>
                        <td>{{ participacion.descripcio | slice:0:80 }}...</td>
                        <td>{{ participacion.data | date:'yyy-MM-dd' }}</td>
                        <td>{{ participacion.participacion[0].rol }}</td>
                        <td><a routerLink="/entidadDetalle/{{ participacion._idPare }}">{{ participacion.pareNom[0] }}</a></td>
                        <td data-visible="false">{{ participacion.estat }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-template>
</ngb-tab>
<ngb-tab *ngIf="superUser && env.features.facturacion" title="Facturación">
        <ng-template ngbTabContent><br>
            <h3 class="font-weight-light">Facturación a la entidad <span *ngIf="superUser" style="cursor: pointer"><svg (click)="open(factura)" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/></svg></span></h3>
    <div *ngIf="facturasEntidad?.length > 0">
        <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [(pageSize)]="pageSize" [collectionSize]="facturasEntidad.length" [boundaryLinks]="true"></ngb-pagination>
        <table class="table table-striped table-bordered" style="width:100%" data-page-length='25'>
        <thead>
            <tr>
                <th class="codiCol">Codi</th>
                <th style="width: 2%">File</th>
                <th class="dataCol">Data Factura</th>
                <th>Concepto</th>
                <th class="valCol">Base Imp.</th>
                <th class="valCol">Suplidos</th>
                <th class="dataCol">Liquidada</th>
                <th>Proyecto</th>
        </tr>
        </thead>
        <tbody>
            <tr *ngFor="let factura of facturasEntidad | slice: (page-1) * pageSize: (page-1) * pageSize + pageSize" [ngClass]="{'table-danger': factura.estado === 'Al cobro'}">
                <td>{{ factura.codifactura }}</td>
                <td><span style="cursor: pointer"><a href="{{factura.url}}" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/><path d="M0 0h24v24H0z" fill="none"/></svg></a></span></td>
                <td>{{ factura.fechafactura | date:'yyy-MM-dd' }}</td>
                <td>{{ factura.concepto | slice:0:80 }}...</td>
                <td>{{ factura.baseimponible | currency:'EUR':'symbol':'1.2-2':'es' }}</td>
                <td>{{ factura.gastossuplidos | currency:'EUR':'symbol':'1.2-2':'es' }}</td>
                <td>{{ factura.fechaliquida | date:'dd-MM-yyy'}}</td>
                <td><a routerLink="/encargoDetalle/{{factura.proyecto._idProyecto}}">{{ factura.proyecto.codi }}</a></td>
            </tr>
        </tbody>
    </table>
    </div>
</ng-template>
</ngb-tab>
</ngb-tabset>

<div *ngIf="showSpinner" class="d-flex justify-content-center" style="height: 100vh;">
    <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
    <span class="sr-only">Loading...</span>
    </div><br>
    <p class="lead"> Cargando datos...</p>
</div>

<ng-template #editarDocumento let-modal>
        <form [formGroup]="editDocumentoForm" (ngSubmit)="onEditDocumento()">
        <div class="modal-header">
            <h4 class="modal-title font-weight-light" id="modal-basic-title">Editar Documento <span style="cursor: pointer"><svg (click)="delDocumento(editDocumentoForm.controls.owner_name.value, editDocumentoForm.controls.key.value, editDocumentoForm.controls._id.value)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zm2-8h6v8H5v-8zm5-6H6L5 5H2v2h12V5h-3z"/></svg></span></h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-row">
                <div class="form-group col-md-4">
                <label for="fecha">Fecha</label>
                    <div class="input-group">
                        <input class="form-control" formControlName="fecha" value="{{ editDocumentoForm.controls.fecha.value | date:'yyy-MM-dd' }}" name="dp" ngbDatepicker #dp="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-8">
                    <label>Archivo</label>
                    <input type="text" formControlName="url" class="form-control"/>
                </div>
            </div>
            <div class="form-group">
                <label for="asunto">Descripcion</label>
                <input type="text" formControlName="descripcion" value="{{ editDocumentoForm.controls.descripcion.value }}" class="form-control"/>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
            <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar</button>
        </div>
        </form>
</ng-template>

</div>