import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { Entidad, Actor } from '../../../_models';
import { stitch, EntidadesService } from '../../../_services';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'formEntidad',
    templateUrl: 'formEntidad.component.html',
    providers: [EntidadesService],
    styleUrls: ['formEntidad.component.css']
})

export class FormEntidadComponent {
    
    @Input() entidad: Entidad;
    @Input() user: Actor;
    @Output() entidadSet = new EventEmitter<Entidad>();
    @Output() newEntidadSet = new EventEmitter<Entidad[]>();

    entidadesExistentes: Entidad[];
    entidadForm : FormGroup;
    submitted = false;
    loading = false;
    public subscription: Subscription;
    _id: any;
    administrador: Boolean;
    superUser: Boolean;

    constructor(
        public stitch: stitch,
        public entidadService: EntidadesService,
        public modalService: NgbModal,
        public router: Router,
        public fb: FormBuilder
        ){
            this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
            this.superUser = environment.superUsers.includes(this.stitch.client.auth.user.profile.email);
        }

    open(content) {
        this.loading = false;
        this.submitted = false;
        this.drawEntidadForm();
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result;
    }

    get f() { return this.entidadForm.controls; }

    onSubmit() {
        this.loading = true;
        this.submitted = true;
        if (this.entidadForm.invalid) {
            this.loading = false;
            return;
        }
        this.entidadForm.disable();
        if (this.f._id.value === ''){
            this._id = new BSON.ObjectId();
            const entidad = [
                this._id,
                this.f.Rao.value,
                this.f.CifNif.value,
                this.f.DQE.value,
                this.f.Direccion.value,
                this.f.Cp.value,
                this.f.Localidad.value,
                this.f.Municipio.value,
                this.f.Telefono.value,
                this.f.email.value,
                this.f.web.value
            ];
            this.entidadService.setEntidad(entidad)
            .then(() => {
                //Cuando quiero actualizar instantáneamente
                /*this.entidadService.getEntidades().subscribe(result => {
                    this.newEntidadSet.emit(result)
                });*/
                this.loading = false;
                this.newEntidadSet.emit();
                this.submitted = false;
                this.modalService.dismissAll();
            });
        } else {
            this._id = this.f._id.value;
            const entidad = [
                this._id,
                this.f.Rao.value,
                this.f.CifNif.value,
                this.f.DQE.value,
                this.f.Direccion.value,
                this.f.Cp.value,
                this.f.Localidad.value,
                this.f.Municipio.value,
                this.f.Telefono.value,
                this.f.email.value,
                this.f.web.value
            ];
            this.entidadService.setEntidad(entidad)
            .then(() => {
                this.entidadService.getEntidadById(this.entidad._id.toString()).then(result => {
                    this.entidadSet.emit(result)
                });
                this.submitted = false;
                this.modalService.dismissAll(); 
            });
        };
    }

    delEntidad(id){
        this.entidadForm.disable();
        this.loading = true;
        this.entidadService.delEntidadById(id)
        .then(() => {
            this.loading = false;
            this.submitted = false;
            this.modalService.dismissAll();
            this.router.navigate(['/entidades']);
        });
    }

    drawEntidadForm(){
        this.entidadForm =  this.fb.group({
          _id: [{value: this.entidad._id, disabled: true}],
          Rao: [ this.entidad.Rao, Validators.required ],
          CifNif: [ this.entidad.CifNif, Validators.required ],
          DQE: this.entidad.DQE,
          Direccion: [ this.entidad.Direccion, Validators.required ],
          Cp: this.entidad.Cp,
          Localidad: [ this.entidad.Localidad, Validators.required ],
          Municipio: [ this.entidad.Municipio, Validators.required ],
          Telefono: this.entidad.Telefono,
          email: [this.entidad.email, Validators.required],
          web: this.entidad.web
        });
        if(!this.superUser){
            this.entidadForm.disable();
        };
      }
}