<div *ngIf="actorExistent && entidadPare" class="container-fluid mh-100">
    <div class="row">
        <div class="col-8">
            <h1 class="font-weight-light">{{ actorExistent?.Nom }} {{actorExistent?.Cognom1}} {{actorExistent?.Cognom2}} <span style="cursor: pointer"><svg (click)="copyElementToClipBoard(actorExistent?.Nom + ' ' + actorExistent?.Cognom1 + ' ' + actorExistent?.Cognom2 )" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-copy" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg></span><formActor [actor]="actorExistent" [user]="user" [entidad]="entidadPare" (actorSet)="procesaSet($event)"></formActor></h1>
            <h4 class="font-weight-light">{{ actorExistent?.email }} <span style="cursor: pointer"><svg (click)="copyElementToClipBoard(actorExistent?.email)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-copy" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg></span></h4>
            <h2 class="font-weight-light"><a routerLink="/entidadDetalle/{{actorExistent?._idEntidad}}">{{ entidadPare?.Rao }}</a></h2>
        </div>
        <div *ngIf="administrador && env.features.tickets" class="col-4 d-flex justify-content-end">
            <div class="container">
                <div class="row justify-content-end">
                    <h6 class="font-weight-light">Total Tickets (hh:mm:ss): {{ ticketService.timeToString(totalTickets) }}</h6>        
                </div>
            </div>
        </div>
    </div>
    <hr class="my-4">
    <ngb-tabset type="pills">
        <ngb-tab *ngIf="env.features.autorizados" title="Comments">
            <ng-template ngbTabContent><span class="badge badge-warning" style="position: relative; bottom:3rem; left: 6.5rem;">{{commentsUser?.length}}</span><br>
            <h3 class="font-weight-light">Comentarios User<span style="cursor: pointer" ><svg (click)="alert('Funcionalitat no implementada')" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M13 11h-2v3H8v2h3v3h2v-3h3v-2h-3zm1-9H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z"/></svg></span></h3>
            <div *ngIf="commentsUser?.length > 0">
                <table class="table table-striped table-bordered" style="width:100%" data-page-length='25'>
                    <thead>
                        <tr>
                            <th class="dataCol">Fecha</th>
                            <th style="width: 20%">Asunto</th>
                            <th style="width: 67%">Texto</th>
                            <th class="codiCol">Proyecto</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let comment of commentsUser">
                            <td>{{ comment.fecha | date:'yyy-MM-dd' }}</td>
                            <td>{{ comment.asunto | slice:0:40 }}...</td>
                            <td>{{ comment.texto | slice:0:80 }}...</td>
                            <td><a routerLink="/encargoDetalle/{{comment.proyecto._id}}">{{ comment.proyecto.codi }}</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>       
            </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="env.features.autorizados" title="Autorizado">
            <ng-template ngbTabContent><span class="badge badge-warning" style="position: relative; bottom:3rem; left: 13.6rem;">{{encargosAutorizados?.length}}</span><br>
            <h3 class="font-weight-light">Listado de encargos</h3>
            <div *ngIf="encargosAutorizados?.length > 0">
                <table class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'>
                    <thead>
                    <tr>
                        <th class="codiCol">Código</th>
                        <th>Objeto</th>
                        <th class="dataCol">Fecha</th>
                        <th data-visible="false">Estado</th>
                        <th class="openIcon">Ver</th>
                    </tr>
                </thead>
                <tbody>
                        <tr *ngFor="let proyecto of encargosAutorizados" [ngClass]="{'table-success': proyecto.properties.estat === 'Redaccion', 'table-danger': proyecto.properties.estat === 'Ejecucion', 'table-warning': proyecto.properties.estat === 'Tramite'}">
                        <td><a routerLink="/encargoDetalle/{{ proyecto._id }}">{{ proyecto.properties.codi }}</a></td>
                        <td>{{ proyecto.properties.descripcio }}</td>
                        <td>{{ proyecto.properties.data | date:'yyy-MM-dd' }}</td>
                        <td data-visible="false">{{ proyecto.properties.estat }}</td>
                        <td><formAutorizado [encargo]="proyecto" [idPareEncargo]="proyecto._idPare" [idTitularEncargo]="proyecto._idTitular" [email]="actorExistent.email" (autorizadoSet)="procesaSetAutorizado($event)"></formAutorizado></td>
                    </tr>
                </tbody>
            </table>
        </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="administrador && env.features.tickets" title="Tickets">
            <ng-template ngbTabContent><span class="badge badge-warning" style="position: relative; bottom:3rem; left: 19rem;">{{ticketsAdmin?.length}}</span><br>
            <h3 class="font-weight-light">Tickets Administador <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="36px" height="36px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z"/><path d="M3 12c0-2.61 1.67-4.83 4-5.65V4.26C3.55 5.15 1 8.27 1 12s2.55 6.85 6 7.74v-2.09c-2.33-.82-4-3.04-4-5.65z"/></svg></h3>
            <div *ngIf="ticketsAdmin?.length > 0">
                <table class="table table-striped table-bordered" style="width:100%" data-page-length='25'>
                    <thead>
                        <tr>
                            <th style="width: 4%">Encargo</th>
                            <th>Tarea</th>
                            <th style="width: 20%">Fecha</th>
                            <th style="width: 10%">Temps</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ticket of ticketsAdmin" [ngClass]="{'table-success': ticket.estat === 'Obert', 'table-danger': ticket.estat === 'Pausa'}">
                            <td><a routerLink="/encargoDetalle/{{ticket.encargo._idEncargo}}">{{ ticket.encargo.codi }}</a></td>
                            <td>{{ ticket.tarea }}</td>
                            <td>{{ ticket.fecha | date:'EEEE, MMM d, y, h:mm:ss a'}}</td>
                            <td>{{ ticket.estat ==='Tancat' ? ticketService.timeToString(ticket.temps): '' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>       
            </ng-template>
        </ngb-tab>
</ngb-tabset>
</div>
