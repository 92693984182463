export class Factura {
    constructor(
        public _id: Object,
        public baseimponible: string,
        public codifactura: String,
        public gastossuplidos: string,
        public concepto: String,
        public estado: String,
        public notas: String,
        public fechaalcobro: Date,
        public fechafactura: Date,
        public fechaliquida: Date,
        public liquidador: {
            _idLiquidador: String,
            razon: String
        },
        public proyecto: {
            _idProyecto: String,
            codi: String,
            descripcio: String
        },
        public tipoirpf: String,
        public tipoiva: String,
        public emailsAutorizados: String[],
        public url: String
        )
    {}
}