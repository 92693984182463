import { Component, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Actor } from '../../../_models';
import { ActorsService, ActoresTableService } from '../../../_services';
import { NgbdSortableHeader, SortEvent } from '../../../_helpers/sortable.directive';

@Component({
    selector: 'actores',
    templateUrl: 'actoresTable.component.html',
    providers: [{provide: ActorsService, useClass: ActoresTableService}],
    styleUrls: ['../../../_helpers/components.component.css']
})

  export class ActoresComponent {
    
    actores: Actor[];
    actores$: Observable<Actor[]>;
    total$: Observable<number>;
    
    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
    
    constructor(
        public route: Router,
        public actoresTableService: ActorsService
        ){
            this.actores$ = actoresTableService.actores$;
            this.total$ = actoresTableService.total$;
        }

    onSort({column, direction}: SortEvent) {
        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
            header.direction = '';
            }
        });
        this.actoresTableService.sortColumn = column;
        this.actoresTableService.sortDirection = direction;
        }

    openEntidad(id){
        this.route.navigate(['/entidadDetalle/' + id]);
    }

    setRol(rol){
        var rols = this.actoresTableService.rols;
        if(rols.includes(rol)){
            const index = rols.indexOf(rol);
            if( index > -1) {
                rols.splice(index,1);
            }
            this.actoresTableService.rols = rols;
        } else {
            rols.push(rol);
            this.actoresTableService.rols = rols;
        }
    }
}