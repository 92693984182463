import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { map, startWith, distinctUntilChanged } from 'rxjs/operators';

import { Comment } from '../../../_models';
import { CommentsService } from '../../../_services';

@Component({
    selector: 'comments',
    templateUrl: 'commentsTable.component.html',
    providers: [CommentsService],
    styleUrls: ['../../../_helpers/components.component.css']
})

export class CommentsComponent implements OnInit, OnDestroy {

    showSpinner = true;
    comments: Comment[];
    comments$: Observable<Comment[]>;
    public subscription: Subscription;
    filter = new FormControl('');
    
    constructor(
        public commentService: CommentsService,
        public route: Router)
        {
             this.comments$ = this.filter.valueChanges.pipe(
                startWith(''),
                map(text => this.search(text)),
                distinctUntilChanged()       
            );
        }

    ngOnInit() {
        this.subscription = this.commentService.getComments()
        .subscribe(comments => {
            this.comments = comments;
            this.showSpinner = false;  
        });
    }

    search(text: String): Comment[]{
        return this.comments.filter(comment => {
            const term = text.toLowerCase();
            return comment.actor.email.includes(term)
            || comment.actor.nom.includes(term)
            || comment.asunto.includes(term)
            || comment.texto.includes(term);
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    openMatriz(comment) {
        this.route.navigate(['/encargoDetalle/' + comment.proyecto._id.$oid]);
    }
}