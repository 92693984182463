import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Factura, Actor, Encargo } from '../../../_models';
import { stitch, EntidadesService, FacturasService } from '../../../_services';

import { BSON } from 'mongodb-stitch-browser-sdk';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'formFactura',
    templateUrl: 'formFactura.component.html',
    providers: [FacturasService, EntidadesService],
    styleUrls: ['formFactura.component.css']
})

export class FormFacturaComponent {
    
    @Input() factura: Factura;
    @Input() user: Actor;
    @Input() encargo: Encargo;
    @Output() facturaSet = new EventEmitter<any[]>();

    facturaForm : FormGroup;
    submitted = false;
    baseimponibleValor: any;
    gastossuplidosValor: any;
    fechafactura: any;
    fechaalcobro: any;
    fechaliquida: any;
    liquidador: any;
    _id: any;
    emailEntidadAutorizado: String;
    entidadesToSelect: any;
    administrador: Boolean;

    constructor(
        public stitch: stitch,
        public facturaService: FacturasService,
        public entidadService: EntidadesService,
        public modalService: NgbModal,
        public router: Router,
        public fb: FormBuilder
        ){
            this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
            this.entidadService.getEntidadesToSelect()
            .then(result => {
                this.entidadesToSelect = result;
            });
        }

    open(content) {
        this.submitted = false;
        if (this.factura._id === ''){
            this.facturaForm = this.fb.group({
                _id: [{value: '', disabled: true}],
                codifactura: ['', Validators.required],
                concepto: ['', Validators.required],
                baseimponible: ['', Validators.required],
                gastossuplidos: '',
                estado: '',
                fechafactura: ['', Validators.required],
                fechaalcobro: '',
                fechaliquida: '',
                tipoiva: '',
                tipoirpf: '',
                notas: '',
                liquidador: ['', Validators.required]
            });
        } else {
            this.drawFacturaForm();
        }
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'}).result;
    }

    get f() { return this.facturaForm.controls; }

    onSubmit() {
        this.submitted = true;
        if (this.facturaForm.invalid) {
            return;
        }
        if (this.f._id.value === ''){
            this._id = new BSON.ObjectId();
        } else {
            this._id = this.f._id.value;
        };
        if ( this.f.liquidador.value === null ) {
            this.liquidador = '';
        } else {
            this.liquidador = this.f.liquidador.value;
        }
        if ( this.f.fechafactura.value.length === 24 ) {
            this.fechafactura = this.f.fechafactura.value;
        } else {
        const fecha1 = new Date(this.f.fechafactura.value.year, this.f.fechafactura.value.month - 1, this.f.fechafactura.value.day);
        this.fechafactura = fecha1.toISOString();
        }
        if ( this.f.fechaalcobro.value === null || this.f.fechaalcobro.value === '') {
        this.fechaalcobro = '';
        } else {
        if ( this.f.fechaalcobro.value.length === 24 ) {
            this.fechaalcobro = this.f.fechaalcobro.value;
        } else {
            const fecha2 = new Date(this.f.fechaalcobro.value.year, this.f.fechaalcobro.value.month - 1, this.f.fechaalcobro.value.day);
            this.fechaalcobro = fecha2.toISOString();
        }
        }
        if ( this.f.fechaliquida.value === null || this.f.fechaliquida.value === '') {
        this.fechaliquida = '';
        } else {
        if ( this.f.fechaliquida.value.length === 24 ) {
            this.fechaliquida = this.f.fechaliquida.value;
        } else {
            const fecha3 = new Date(this.f.fechaliquida.value.year, this.f.fechaliquida.value.month - 1, this.f.fechaliquida.value.day);
            this.fechaliquida = fecha3.toISOString();
        }
        }

        //Comprobar si el valor se ha modificado
        //Si es así, volver a darle formato.
        const regex = /^\d+\.\d+$/;
        if (regex.test(this.f.baseimponible.value)) {
            this.baseimponibleValor = this.f.baseimponible.value;
        } else {this.baseimponibleValor = this.convertirEuroParaAlmacenar(this.f.baseimponible.value)};
        if (regex.test(this.f.gastossuplidos.value)) {
            this.gastossuplidosValor = this.f.gastossuplidos.value;
        } else {this.gastossuplidosValor = this.convertirEuroParaAlmacenar(this.f.gastossuplidos.value)};

        const factura = [
            this._id,
            this.f.codifactura.value,
            this.baseimponibleValor,
            this.gastossuplidosValor,
            this.f.concepto.value,
            this.f.estado.value,
            this.f.notas.value,
            this.fechafactura,
            this.fechaalcobro,
            this.fechaliquida,
            this.encargo._id,
            this.encargo.properties.codi,
            this.encargo.properties.descripcio,
            this.liquidador,
            this.f.tipoirpf.value,
            this.f.tipoiva.value
        ];
        this.facturaService.setFacturaById(factura)
        .then(() => { 
            this.facturaSet.emit(factura);
            this.submitted = false;
            this.modalService.dismissAll();
        });
    }

    delFactura(id){
        this.facturaService.delFacturaById(id)
        .then(() => {
            this.facturaSet.emit();
            this.submitted = false;
            this.modalService.dismissAll();
        });
        this.router.navigate(['/encargoDetalle/' + this.encargo._id.toString()]);
    }

    drawFacturaForm(){
        this.facturaForm =  this.fb.group({
          _id: [{value: this.factura._id, disabled: true}],
          codifactura: [ this.factura.codifactura, Validators.required ],
          concepto: [this.factura.concepto, Validators.required ],
          baseimponible: [this.factura.baseimponible, Validators.required],
          gastossuplidos: this.factura.gastossuplidos,
          estado: this.factura.estado,
          fechafactura: [{value: this.factura.fechafactura, disabled: true}],
          fechaalcobro: this.factura.fechaalcobro,
          fechaliquida: this.factura.fechaliquida,
          tipoiva: this.factura.tipoiva,
          tipoirpf: this.factura.tipoirpf,
          notas: this.factura.notas,
          liquidador: [this.factura.liquidador._idLiquidador.toString(), Validators.required]
        });
        if(!this.administrador){
            this.facturaForm.disable();
        };
      }

      convertirEuroParaAlmacenar(value: string): string {
        // Implementa la lógica necesaria para convertir el valor formateado a una cadena
        // adecuada para el almacenamiento. Por ejemplo, podrías eliminar símbolos de moneda,
        // separadores de miles, etc., dependiendo de cómo se formateó el valor.
        // Este es un ejemplo simple que asume que deseas almacenar el número como un string plano.
        return value.replace(/[^\d,]/g, '').replace(',', '.');
      }
}