import { Component, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Encargo } from '../../../_models';
import { stitch, FacturasService, EncargosService, FacturasTableService, EncargosTableService } from '../../../_services';
import { NgbdSortableHeader, SortEvent } from '../../../_helpers/sortable.directive';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'encargos',
    templateUrl: 'encargosTable.component.html',
    providers: [{provide: EncargosService, useClass: EncargosTableService}],
    styleUrls: ['../../../_helpers/components.component.css', 'encargosTable.component.css']
})

export class EncargosComponent {
    showSpinner = true;
    encargos$: Observable<Encargo[]>;
    total$: Observable<number>;
    totalPresupuestos$: Observable<number>;
    totalAños$: Observable<number[]>;
    superUser: Boolean;
    env: any;
        
    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
    
    constructor(
        public stitch: stitch,
        public route: Router,
        public encargosTableService: EncargosService){
        this.encargos$ = encargosTableService.encargos$;
        this.total$ = encargosTableService.total$;
        this.totalPresupuestos$ = encargosTableService.totalPresupuestos$;
        this.totalAños$ = encargosTableService.totalAños$;
        this.superUser = environment.superUsers.includes(this.stitch.client.auth.user.profile.email);
        this.env = environment; 
        } 
    
    onSort({column, direction}: SortEvent) {
        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
            header.direction = '';
            }
        });
        this.encargosTableService.sortColumn = column;
        this.encargosTableService.sortDirection = direction;
    }

    setEstado(estado){
        var estados = this.encargosTableService.estados;
        if(estados.includes(estado)){
            const index = estados.indexOf(estado);
            if( index > -1) {
                estados.splice(index,1);
            }
            this.encargosTableService.estados = estados;
        } else {
            estados.push(estado);
            this.encargosTableService.estados = estados;
        }
    }

    procesaSetEncargo(){    
    }

    openProjecte(id) {
        this.route.navigate(['/encargoDetalle/' + id]);
    }   
}