<div class="container-fluid overflow-auto">
    <h1 class="font-weight-light">Gestión de Entidades <formEntidad *ngIf="administrador" [entidad]="{_id: ''}" [user]="{email: stitch.client.auth.user.profile.email}" (newEntidadSet)="procesaSet($event)"></formEntidad></h1>
    <form>
    <div class="form-group form-inline">
        <input class="font-weight-lighter" id="cercaTerm" type="text" name="searchTerm" placeholder="Búsqueda por término..." [(ngModel)]="entidadesTableService.searchTerm"/>
    </div>
    </form>
    <div class="d-flex justify-content-between">
        <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="entidadesTableService.pageSize">
            <option [ngValue]="10">Mostrar 10 registros</option>
            <option [ngValue]="25">Mostrar 25 registros</option>
            <option [ngValue]="50">Mostrar 50 registros</option>
        </select>
        <ngb-pagination class="d-flex justify-content-end" [(page)]="entidadesTableService.page" [(pageSize)]="entidadesTableService.pageSize" [collectionSize]="total$ | async" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>  
    </div>
    <div *ngIf="entidadesTableService.loading$ | async" class="d-flex justify-content-center" style="height: 100vh;">
        <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div><br>
        <p class="lead"> Cargando datos...</p>
    </div>    
    <table *ngIf= "entidades$" class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'>
        <thead>
            <tr>
                <th style="width: 25%" class="sortable entidadCol" sortable="Rao" (sort)="onSort($event)">Entidad</th>
                <th style="width: 5%">CIF/NIF</th>
                <th style="width: 25%">Dirección</th>
                <th style="width: 15%">Localidad</th>
                <th style="width: 15%">Municipio</th>
                <th style="width: 15%">Teléfono</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let entidad of entidades$ | async" (click)="openMatriz(entidad)">
                <td><a routerLink="/entidadDetalle/{{entidad._id.$oid}}"><ngb-highlight [result]="entidad.Rao" [term]="entidadesTableService.searchTerm"></ngb-highlight></a></td>
                <td><ngb-highlight [result]="entidad.CifNif" [term]="entidadesTableService.searchTerm"></ngb-highlight></td>
                <td><ngb-highlight [result]="entidad.Direccion" [term]="entidadesTableService.searchTerm"></ngb-highlight></td>
                <td><ngb-highlight [result]="entidad.Localidad" [term]="entidadesTableService.searchTerm"></ngb-highlight></td>
                <td><ngb-highlight [result]="entidad.Municipio" [term]="entidadesTableService.searchTerm"></ngb-highlight></td>
                <td><a href= "tel: {{entidad.Telefono}}"><ngb-highlight [result]="entidad.Telefono" [term]="entidadesTableService.searchTerm"></ngb-highlight></a></td>            </tr>
        </tbody>
    </table>
    <!--Caso 1: No hay búsqueda-->
    <h6 *ngIf="!entidadesTableService.searchTerm" class="font-weight-light">Mostrando registros del {{(entidadesTableService.page - 1 ) * entidadesTableService.pageSize + 1}} al {{((entidadesTableService.page - 1 ) * entidadesTableService.pageSize) + entidadesTableService.pageSize}} de un total de {{ entidadesTableService.total$ | async }}</h6>
    <!--Caso 2: Hay búsqueda y más de una página-->
    <h6 *ngIf="entidadesTableService.searchTerm && ((entidadesTableService.total$ | async) >= entidadesTableService.pageSize) && (entidadesTableService.page !== (((entidadesTableService.total$ | async) % entidadesTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(entidadesTableService.page - 1 ) * entidadesTableService.pageSize + 1}} al {{((entidadesTableService.page - 1 ) * entidadesTableService.pageSize) + ((entidadesTableService.total$ | async) % entidadesTableService.pageSize) + (entidadesTableService.pageSize - ((entidadesTableService.total$ | async) % entidadesTableService.pageSize))}} de un total de {{ entidadesTableService.total$ | async }} (filtrado de un total de {{entidadesTableService.totalEntidades}})</h6>
    <!--Caso 3: Hay búsqueda, más de una página y es la última page-->
    <h6 *ngIf="entidadesTableService.searchTerm && ((entidadesTableService.total$ | async) >= entidadesTableService.pageSize) && (entidadesTableService.page == (((entidadesTableService.total$ | async) % entidadesTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(entidadesTableService.page - 1 ) * entidadesTableService.pageSize + 1}} al {{((entidadesTableService.page - 1 ) * entidadesTableService.pageSize) + ((entidadesTableService.total$ | async) % entidadesTableService.pageSize)}} de un total de {{ entidadesTableService.total$ | async }} (filtrado de un total de {{entidadesTableService.totalEntidades}})</h6>
    <!--Caso 3: Hay búsqueda y solo una página-->
    <h6 *ngIf="entidadesTableService.searchTerm && entidadesTableService.pageSize >= (entidadesTableService.total$ | async)" class="font-weight-light">Mostrando registros del {{(entidadesTableService.page - 1 ) * entidadesTableService.pageSize + 1}} al {{((entidadesTableService.page - 1 ) * entidadesTableService.pageSize) + ((entidadesTableService.total$ | async) % entidadesTableService.pageSize)}} de un total de {{ entidadesTableService.total$ | async }} (filtrado de un total de {{entidadesTableService.totalEntidades}})</h6>
</div>
