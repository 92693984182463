export class Encargo {
    constructor(
        public _id: String,
        public _idPare: Object,
        public _idTitular: Object,
        public geometry: {
            coordinates: number[],
            type: String
        },
        public properties: {
            direccio: String,
            cp: String,
            data: string,
            municipi: String,
            refcat: String,
            descripcio: String,
            localitat: String,
            supcons: String,
            codi: String,
            estat: string,
            totalPresupuestado: string
        },
        public type: String,
        public participantes: [],
        
        public emailsAutorizados: string[]
        )
    {}
}