import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Actor } from '../../../_models';
import { stitch, ActorsService } from '../../../_services';
import { AwsServiceClient, AwsRequest } from 'mongodb-stitch-browser-services-aws';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'formCurrentUser',
    templateUrl: 'formCurrentUser.component.html',
    providers: [ActorsService],
    styleUrls: ['../../../_helpers/components.component.css', 'formCurrentUser.component.css']
})

export class FormCurrentUserComponent {
    
    @Input() currentUser: any;
    @Output() currentUserSet = new EventEmitter<any>();

    currentUserForm : FormGroup;
    submitted = false;
    loading = false;
    _id: any;
    administrador: Boolean;
    superUser: Boolean;
    awsCloudWatch: any;
    totalBucketSize: any;
    bucketNumberOfObjects: any;
    mongodbStats: any;
    mongoDb: any;
    awsBucket: any;
    admin: Actor;

    constructor(
        public stitch: stitch,
        public actorsService: ActorsService,
        public modalService: NgbModal,
        public router: Router,
        public fb: FormBuilder,
        ){
            this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
            this.superUser = environment.superUsers.includes(this.stitch.client.auth.user.profile.email);
        }

    open(content) {
        if(!environment.features.cloud){
            alert('Funcionalidad no contratada. Para activarla y obtener información sobre la nube dedicada de ' + environment.org + ' póngase en contacto con JLC - Ingenieros.');
            return;
        } else {
        this.actorsService.getActorByEmail(this.stitch.client.auth.user.profile.email)
        .then(result => {
            this.admin = result;
        })
        .then(() => {
        if(this.superUser){
            this.loading = true;
            this.getTotalBucketSize()
            .then(() => {
                this.getBucketNumberOfObjects()
                .then(() => {
                    this.getMongoDbStats()
                    .then(() => {
                        this.submitted = false;
                        this.loading = false;
                        this.drawcurrentUserForm();
                        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'}).result;            
                    })
                })
            });
            } else {
                this.submitted = false;
                this.loading = false;
                this.drawcurrentUserForm();
                if(!this.administrador){
                    this.currentUserForm.disable();
                    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'}).result;
                } else {
                    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'}).result;
                }
                
            };
            });
        }
    }

    get f() { return this.currentUserForm.controls; }

    onSubmit() {
        this.loading = true;
        this.submitted = true;
        if (this.currentUserForm.invalid) {
            return;
        }
        const administrador = [
            this.f.idAdmin.value,
            this.f.nomAdmin.value,
            this.f.cognom1Admin.value,
            this.f.cognom2Admin.value,
            this.f.nifAdmin.value,
            this.f.telAdmin.value,
            this.f.extAdmin.value,
            this.f.emailAdmin.value,
            this.admin.Rol,
            this.f.notasAdmin.value,
            this.admin._idEntidad,
            this.f.email.value
        ];
        this.actorsService.setActorById(administrador)
            .then(() => {
                this.loading = false;
                this.currentUserSet.emit(administrador);
                this.submitted = false;
                this.modalService.dismissAll();
            });
    }

    async getTotalBucketSize() {
        const startTime = new Date();
        const undia = 3*(24*60*60)*1000;
        startTime.setTime(startTime.getTime() - undia);
        const aws = this.stitch.client.getServiceClient(AwsServiceClient.factory, "AWS");
        const request = new AwsRequest.Builder()
        .withService("cloudwatch")
        .withAction("GetMetricStatistics")
        .withRegion('us-east-2')
        .withArgs({
            MetricName: 'BucketSizeBytes',
            Namespace: 'AWS/S3',
            Period: 24*60*60,
            Dimensions: [{
                Name: 'BucketName',
                Value: environment.awsBucket
            },
            {
                Name: 'StorageType',
                Value: 'StandardStorage'
            }],
            Statistics: [ 'Sum' ],
            StartTime: startTime,
            EndTime: new Date(),
            Unit: 'Bytes'
        });
        try {
            await aws.execute(request.build())
            .then(result => {
                this.totalBucketSize = result;
            })
        } catch (e) {
            console.log(e);
        }
    }

    async getBucketNumberOfObjects() {
        const startTime = new Date();
        const undia = 3*(24*60*60)*1000;
        startTime.setTime(startTime.getTime() - undia);
        const aws = this.stitch.client.getServiceClient(AwsServiceClient.factory, "AWS");
        const request = new AwsRequest.Builder()
        .withService("cloudwatch")
        .withAction("GetMetricStatistics")
        .withRegion('us-east-2')
        .withArgs({
            MetricName: 'NumberOfObjects',
            Namespace: 'AWS/S3',
            Period: 24*60*60,
            Dimensions: [{
                Name: 'BucketName',
                Value: environment.awsBucket
            },
            {
                Name: 'StorageType',
                Value: 'AllStorageTypes'
            }],
            Statistics: [ 'Average' ],
            StartTime: startTime,
            EndTime: new Date()
        });
        try {
            await aws.execute(request.build())
            .then(result => {
                this.bucketNumberOfObjects = result;
            })
        } catch (e) {
            console.log(e);
        }
    }

    async getMongoDbStats(){
        await this.stitch.client.callFunction('mongodbStats', [environment.mongoDb])
        .then(result => {
            this.mongodbStats = result;
        })
    }

    drawcurrentUserForm(){
        this.currentUserForm =  this.fb.group({
          _id: [{value: environment._id, disabled: true}],
          Rao: [ {value: environment.org, disabled: true} , Validators.required ],
          CifNif: [ {value: environment.cifNif, disabled: true } ],
          Direccion: [ {value: environment.direccionOrg, disabled: true } ],
          Cp: [ {value: environment.cpOrg, disabled: true}],
          Localidad: [ {value: environment.locOrg, disabled: true} ],
          Municipio: [ {value: environment.munOrg, disabled: true} ],
          Pais: [ {value: environment.countryOrg, disabled: true} ],
          Telefono: [ {value: environment.telOrg, disabled: true}],
          email: [ {value: environment.emailOrg, disabled: true} ],
          web: [{ value: environment.webOrg, disabled: true} ],
          mongoDb: [ {value: environment.mongoDb, disabled: true} ],
          awsBucket: [{value: environment.awsBucket, disabled: true}],
          stitchId: [{value: environment._id, disabled: true}],
          nomAdmin: [this.admin.Nom, Validators.required],
          idAdmin: [{value: this.admin._id, disabled: true}],
          emailAdmin: [{value: this.admin.email, disabled: true}],
          nifAdmin: [this.admin.NifNie],
          cognom1Admin: [this.admin.Cognom1],
          cognom2Admin: [this.admin.Cognom2],
          telAdmin: [this.admin.Telefono, Validators.required],
          extAdmin: [this.admin.Ext],
          notasAdmin: [this.admin.Notas],
          rolAdmin: [this.admin.Rol]          
        });
      }
}