import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Comment } from '../_models';
import { stitch } from './stitch.service';
import { BSON, RemoteMongoClient } from 'mongodb-stitch-browser-sdk';
import { environment } from '../environments/environment';

@Injectable()
export class CommentsService {

    user: any;
    db: any;

    constructor(
        public http: HttpClient,
        public stitch: stitch
    ){
        this.user = this.stitch.client.auth.user;
        this.db = this.stitch.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(environment.mongoDb);
    }

    /**
    * Obtiene els comments existents.
    * @return {Array} - Conjunt de comments enregistrats.
    */
    getComments (): Observable<Comment[]>{
        const commentsUrl = 'https://webhooks.mongodb-realm.com/api/client/v2.0/app/' + environment.appId + '/service/' + environment.webhookService + '/incoming_webhook/Comments';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: new HttpParams()
            .set('arg1', environment.mongoDb)
            .set('arg2', this.user.profile.email)
        };
        return this.http.get<Comment[]>(commentsUrl, httpOptions)
    }

    /**
    * Obté els comentaris de l'encàrrec.
    * @param {String} id - L'identificador de l'encàrrec.
    * @return {Array} - Conjunt de comentaris d'un encàrrec.
    */
    async getCommentsEncargoById(id): Promise<any[]> {
        const _id = new BSON.ObjectId(id);
        return await this.db.collection('Comments').aggregate([
            { $match: {
                'proyecto._id': _id
            }},
            { $project: {
                "actor" : {
                "_id": {"$toString": "$actor._id"},
                "nom": "$actor.nom",
                "email": "$actor.email"},
                fecha: 1,
                asunto: 1,
                texto: 1
            }},
            { $sort: { fecha: -1 }}
        ]).toArray()
    }

    /**
    * Obté els comentaris d'un determinat usuari.
    * @param {String} email - El email de l'usuari.
    * @return {Array} - Conjunt de comentaris d'un usuari.
    */
    async getCommentsUserByEmail(email): Promise<any[]>{
        return await this.db.collection('Comments').aggregate([
            { $match: {
                "actor.email": email
            }},
            { $project: {
                "actor" : {
                    "_id": {"$toString": "$actor._id"},
                    "nom": "$actor.nom",
                    "email": "$actor.email"},
                    fecha: 1,
                    asunto: 1,
                    texto: 1,
                    "proyecto._id": "$proyecto._id",
                    "proyecto.topic": "$proyecto.topic",
                    "proyecto.nom": "$proyecto.nom",
                    "proyecto.codi": "$proyecto.codi"
            }},
            { $sort: { fecha: -1 }}
        ]).toArray()
    }

    /**
    * Modifica el registre d'un comentari o afegeix un de nou.
    * @param {Object} comment - L'objecte comentari.
    * @param {String} user - L'usuari actual.
    * @param {String} owner - L'usuari que intenta eliminar el comentari.
    */
    async setCommentById(comment, user, owner): Promise<void> {
        if(user != owner){
            alert('No está permitido modificar comentarios ajenos.');
            return;
        };
        await this.db.collection('Comments').updateOne(
            { _id: comment[0] },
            { $set: {
                'actor': {
                    '_id': comment[1],
                    'nom': comment[2],
                    'email': comment[3]
                },
                asunto: comment[4],
                texto: comment[5],
                fecha: comment[6],
                'proyecto': {
                    '_id': comment[7],
                    'codi': comment[8],
                    'descripcio': comment[9]
                }
            }},
            { upsert: true }
        )
        .catch (e => {
            alert('Comentario no se ha podido registrar debido a: ' + e);
        });
    }

    /**
     * Elimina un comentari del projecte.
     * @param {ObjectId} id - El id del comentari i l'usuari actual.
     * @param {String} user - L'usuari actual.
     * @param {String} owner - L'usuari que intenta eliminar el comentari.
     */
    async delComment(id, user, owner): Promise<void> {
        if(user != owner){
            alert('No está permitido eliminar comentarios ajenos.');
            return;
        };
        if(confirm('Esta acción NO es recuperable. \nDesea eliminar el comentario?')) {
            await this.db.collection('Comments').deleteOne(
                { _id: id }
            )
            .catch (e => {
                alert('Comentario no se ha podido eliminar debido a: ' + e);
            });
        }
    }   
}