import { Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { stitch, EntidadesService, ActorsService, DocumentosService, FacturasService, EncargosService } from '../../../_services';
import { Encargo } from '../../../_models';
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';
import { environment } from '../../../environments/environment';

import Chart from 'chart.js/auto';

@Component({
  selector: 'entidadDetalle',
  templateUrl: './entidadDetalle.component.html',
  styleUrls: ['../../../_helpers/components.component.css'],
  providers: [EntidadesService, ActorsService, DocumentosService, FacturasService, EncargosService]
})

export class EntidadDetalleComponent implements AfterViewInit {

  administrador: Boolean;
  superUser: Boolean;
  @ViewChild('labelImport', {static: true}) labelImport: ElementRef;

  @ViewChild('chart', {static: true})
  public chartRef: ElementRef;
  public chart: Chart;
  public data: any[] = [];


  //Variables globals
  entidad: any;
  user: any;
  db: any;
  actorExistent: any;
  env: any;
  
  //elementosEntidad
  actoresEntidad: any;
  encargosEntidad: any;
  documentosEntidad: any;
  participacionesEntidad: any;
  facturasEntidad: any;
  totalFacturado: any;
  totalLiquidado: any;
  encargosQuePareTitularOLiquidaElCliente: any;
  totalPresupuestado: any;
    
  //ajudes
  _idTemp: any;
  owner_nameTemp: any;
  fechaDocumento: any;
  encargosToSelect: any;
  showSpinner = true;
  encargoBuit: Encargo;
  _id: any;

  //pagination
  page = 1;
  pageSize = 15;

  //Formularios
  documentoForm: FormGroup;
  editDocumentoForm: FormGroup;
  loading: Boolean = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public stitch: stitch,
    public fb: FormBuilder,
    public modalService: NgbModal,
    public entidadService: EntidadesService,
    public actorService: ActorsService,
    public facturaService: FacturasService,
    public documentoService: DocumentosService,
    public encargoService: EncargosService
  ) {
    this.db = this.stitch.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(environment.mongoDb);
    this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
    this.superUser = environment.superUsers.includes(this.stitch.client.auth.user.profile.email);
    this.env = environment; 

    route.params.subscribe(params => {
      const id = params['_id'];
      this._id = id;
      this.user = this.stitch.client.auth.user.profile.email;
      this.entidadService.getEntidadById(id)
      .then(result => {
        this.entidad = result;
        this.encargoBuit = {
          _id: '',
          geometry: {
              coordinates: [1.431853063473909, 38.91190643864037],
              type: 'Point'
          },
          properties: {
              direccio: '',
              cp: '',
              data: '',
              municipi: '',
              refcat: '',
              descripcio: '',
              localitat: '',
              supcons: '',
              codi: '',
              estat: '',
              totalPresupuestado: ''
          },
          _idPare: this.entidad._id,
          type: 'Feature',
          participantes: [],
          _idTitular: '',
          emailsAutorizados: environment.administradores.concat([this.entidad.email])
        };
        this.documentoService.getDocumentosEntidadById(this.entidad._id.toString())
          .then(result => {
            this.documentosEntidad = result;
          });
      });
      this.actorService.getActorsEntidadById(id)
      .then(result => {
        this.actoresEntidad = result;
      });
      this.actorService.getActorByEmail(this.user)
      .then( result => {
        this.actorExistent = result;
      });
      this.facturaService.getFacturasEntidadById(id)
      .then(result => {
        this.facturasEntidad = result;
        this.totalFacturado = 0;
        if(this.facturasEntidad.length != 0){
          this.totalFacturado = this.facturasEntidad.map(a => parseFloat(a.baseimponible)).reduce((acumulator, currentvalue) => acumulator + currentvalue, 0);
        }
      });
      this.encargoService.getProyectosEntidadEsPareTitularLiquidaById(environment.mongoDb, id)
      .then(result => {
        this.encargosQuePareTitularOLiquidaElCliente = result;
        this.totalPresupuestado = 0;
        if(this.encargosQuePareTitularOLiquidaElCliente.length != 0){
          this.totalPresupuestado = this.encargosQuePareTitularOLiquidaElCliente.map(a => parseFloat(a.properties.totalPresupuestado || 0)).reduce((acumulator, currentvalue) => acumulator + currentvalue, 0);
        };
        this.encargosQuePareTitularOLiquidaElCliente.forEach(encargo => {
          this.totalLiquidado = 0;
          this.facturaService.getFacturasEncargoById(encargo._id.toString())
          .then(result => {
            var totalFacturadoEncargo = result.map(a => parseFloat(a.baseimponible)).reduce((acumulator, currentvalue) => acumulator + currentvalue, 0);
            this.totalLiquidado = this.totalLiquidado + totalFacturadoEncargo;
          });
        });
      });
      this.encargoService.getEncargosEntidadById(id)
      .then(result => {
        this.encargosEntidad = result;
        this.showSpinner = false;
      });
      this.encargoService.getParticipacionesEntidadById(id)
      .then(result => {
        this.participacionesEntidad = result;
      });
      this.encargoService.getProyectosToSelect()
      .then(result => {
        this.encargosToSelect = result;
      });
      if(this.chart){
        this.chart.data.datasets.forEach((dataset) => {
          dataset.data = [];
          this.chart.update();
        });
        this.facturaService.getFacturasEntidadById(id)
        .then(facturas => {
            var años = [2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];            
            for (let i of años){
                var facturasTmp = facturas.filter(factura => new Date(factura.fechafactura).getFullYear() == i);
                if (facturasTmp.length === 0){
                  this.chart.data.datasets.forEach((dataset) => {
                    dataset.data.push(0);
                    this.chart.update();
                  });
                } else {
                var totalAño = facturasTmp.map(factura => Number(factura.baseimponible)).reduce((acumulador ,base) => acumulador + base, 0);
                this.chart.data.datasets.forEach((dataset) => {
                  dataset.data.push(totalAño);
                  this.chart.update();
                });
              }
            };
        });
      };
    });
    this.drawDocumentoForm();
    this.drawEditDocumentoForm();
  }

  ngAfterViewInit() {
    if(environment.features.graphs){
        this.facturaService.getFacturasEntidadById(this._id)
        .then(facturas => {
            var años = [2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];            
            for (let i of años){
                var facturasTmp = facturas.filter(factura => new Date(factura.fechafactura).getFullYear() == i);
                if (facturasTmp.length === 0){
                  this.data.push(0);
                  this.chart.update();  
                } else {
                var totalAño = facturasTmp.map(factura => Number(factura.baseimponible)).reduce((acumulador ,base) => acumulador + base, 0);
                this.data.push(totalAño);
                this.chart.update();
              }
            };
        });
        this.newChart(this.data);
    }
}

  newChart(data){
    this.chart = new Chart(this.chartRef.nativeElement, {
        type: 'bar',
        data: {
        labels: ['2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'],
            datasets: [{
                label: 'Total Facturación',
                data: data,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }]
        },
    });     
  }

  procesaSet(entidad) {
    if(entidad){
    this.entidadService.getEntidadById(entidad._id.toString())
      .then(result => {
        this.entidad = result;
      });
    }
  }

  procesaSetActor(actor) {
    this.actorService.getActorsEntidadById(this.entidad._id.toString())
      .then(result => {
        this.actoresEntidad = result;
      });
  }

  procesaSetEncargo(){
    this.encargoService.getEncargosEntidadById(this.entidad._id.toString())
    .then(result => {
      this.encargosEntidad = result;
    });
  }

  procesaSetTitularidad(){
    this.encargoService.getEncargosEntidadById(this.entidad._id.toString())
    .then(result => {
      this.encargosEntidad = result;
    });
  }

  procesaSetParticipacion() {
      this.encargoService.getParticipacionesEntidadById(this.entidad._id.toString())
      .then(result => {
        this.participacionesEntidad = result;
      });
  }

  procesaSetDocumento() {
    this.documentoService.getDocumentosEntidadById(this.entidad._id.toString())
    .then(result => {
      this.documentosEntidad = result;
      this.facturaService.getFacturasEntidadById(this.entidad._id.toString())
      .then(result => {
        this.facturasEntidad = result;
      })
    });
  }

  open(content){
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result;
  }

  onEditDocumento() {
    this.loading = true;
    this.editDocumentoForm.disable();
    if (this.editDocumentoForm.controls.fecha.value.length === 24) {
      this.fechaDocumento = this.editDocumentoForm.controls.fecha.value;
    } else {
      const fecha1 = new Date(this.editDocumentoForm.controls.fecha.value.year, this.editDocumentoForm.controls.fecha.value.month - 1, this.editDocumentoForm.controls.fecha.value.day);
      this.fechaDocumento = fecha1.toISOString();
    }
    const documento = [this._idTemp,
    this.fechaDocumento,
    this.editDocumentoForm.controls.descripcion.value
    ]
    this.documentoService.setDocumento(this.owner_nameTemp, documento)
    .then(() => {
      this.loading = false;
      this.drawDocumentoForm();
      this.modalService.dismissAll();
      this.documentoService.getDocumentosEntidadById(this.entidad._id.toString())
      .then(result => {
        this.documentosEntidad = result;
      });
    })
    .catch(e => {
      alert('Documento no se ha podido actualizar debido a: ' + e);
    });
  }

  delDocumento(owner_name, key, id) {
    this.loading = true;
    this.editDocumentoForm.disable();
    this.documentoService.delDocumento(owner_name, key, id)
    .then(() => {
      this.loading = false;
      this.modalService.dismissAll();
      this.documentoService.getDocumentosEntidadById(this.entidad._id.toString())
      .then(result => {
        this.documentosEntidad = result;
        this.facturaService.getFacturasEntidadById(this.entidad._id.toString())
        .then(result => {
          this.facturasEntidad = result;
        })
      });
    })
    .catch(e => {
        console.log(e);
    });
  }

  openToEditDocumento(editarDocumento, lineaDocumento) {
    this.editDocumentoForm.enable();
    this.editDocumentoForm.setValue({
      _id: lineaDocumento._id,
      key: lineaDocumento.s3.key,
      url: lineaDocumento.url,
      owner_name: lineaDocumento.owner_name,
      fecha: lineaDocumento.fecha,
      descripcion: lineaDocumento.descripcion
    });
    if (lineaDocumento.owner_name != this.user) {
      this.editDocumentoForm.disable();
    }
    this.editDocumentoForm.get('url').disable();
    this._idTemp = lineaDocumento._id;
    this.owner_nameTemp = lineaDocumento.owner_name;
    this.modalService.open(editarDocumento, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result;
  }


  /**
   * Inicialitzar formulari de document.
   * @assign Assigna valors buits a la variable @documentoForm
   */
  drawDocumentoForm() {
    this.documentoForm = this.fb.group({
      _id: [{ value: '', disabled: true }],
      fecha: ['', Validators.required],
      importFile: ['', Validators.required],
      descripcion: [''],
      ambito: [''],
      area: [''],
      tipo: ['']
    });
  }

  /**
 * Inicialitzar formulari de document.
 * @assign Assigna valors buits a la variable @documentoForm
 */
  drawEditDocumentoForm() {
    this.editDocumentoForm = this.fb.group({
      _id: [{ value: '', disabled: true }],
      key: [{ value: '', disabled: true }],
      url: [''],
      owner_name: [{ value: '', disabled: true }],
      fecha: ['', Validators.required],
      descripcion: ['']
    });
  }

  goToEncargo(id) {
    this.router.navigate(['/encargoDetalle/' + id]);
  }

  goToActor(id) {
    this.router.navigate(['/actorDetalle/' + id]);
  }

  copyElementToClipBoard(element) {
    navigator.clipboard.writeText(element)
    alert ('Texto copiado al portapapeles');
  }

}