import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Actor } from '../_models';
import { stitch } from './stitch.service';
import { RemoteMongoClient, BSON } from 'mongodb-stitch-browser-sdk';

import { SortDirection } from '../_helpers/sortable.directive';
import { environment } from '../environments/environment';

interface CercaResult {
    actores: any[];
    total: number;
}
  
interface Estado {
    page: number;
    pageSize: number;
    searchTerm: string;
    rols: string[];
    sortColumn: string;
    sortDirection: SortDirection;
}

function compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }
  
  function sort(actores: any[], column: string, direction: string): any[] {
    if (direction === '') {
      return actores;
    } else {
      return [...actores].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

function matchesActor(text: String, actor: any){
    const term = text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return actor.email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || actor.Nom.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || actor.Cognom1.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || actor.Cognom2.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || actor.Telefono.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || actor.raoEntidad.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term);
}

@Injectable()
export class ActorsService {

    user: any;
    administrador: Boolean;
    superUser: Boolean;
    db: any;
    administradores: string[];

    constructor(
        public http: HttpClient,
        public stitch: stitch,
    ){
        this.user = this.stitch.client.auth.user;
        this.db = this.stitch.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(environment.mongoDb);
        this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
        this.superUser = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
    }

    _loading$ = new BehaviorSubject<boolean>(true);
    _search$ = new Subject<void>();
    _actores$ = new BehaviorSubject<any[]>([]);
    _total$ = new BehaviorSubject<number>(0);  
    _estado: Estado = {
        page: 1,
        pageSize: 25,
        searchTerm: '',
        rols: ['Propiedad', 'Administrador', 'Comercial', 'Técnico', 'Administrativo', 'Jefe de Obra'],
        sortColumn: '',
        sortDirection: ''
    };
    
    actores: any[];

    get totalActores() { return this.actores.length; }
    get actores$() { return this._actores$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._estado.page; }
    get pageSize() { return this._estado.pageSize; }
    get searchTerm() { return this._estado.searchTerm; }
    get rols() { return this._estado.rols; }

    set page(page: number) { this._set({page}); }
    set pageSize(pageSize: number) { this._set({pageSize}); }
    set searchTerm(searchTerm: string) { this._set({searchTerm}); }
    set rols(rols: string[]) { this._set({rols}); }
    set sortColumn(sortColumn: string) { this._set({sortColumn}); }
    set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }
    
    _set(patch: Partial<Estado>) {
        Object.assign(this._estado, patch);
        this._search$.next();
    }

    _search(): Observable<CercaResult>{
        const {sortColumn, sortDirection, pageSize, page, searchTerm, rols} = this._estado;
        //1. sort
        let actores = sort(this.actores, sortColumn, sortDirection);
        //2. filter
        actores = actores.filter(actor => matchesActor(searchTerm, actor) && rols.includes(actor.Rol));
        const total = actores.length;
        //3. paginate
        actores = actores.slice((page-1)*pageSize, (page-1)*pageSize + pageSize);
        return of({actores, total});
    }
    
    /**
    * Obtiene los actores existentes.
    * @return {Observable} - Conjunt d'actors enregistrats.
    */
    getActores (): Observable<Actor[]>{
        const actorsUrl = 'https://webhooks.mongodb-realm.com/api/client/v2.0/app/' + environment.appId + '/service/' + environment.webhookService + '/incoming_webhook/Actores';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: new HttpParams()
            .set('arg1', environment.mongoDb)
            .set('arg2', this.user.profile.email)
        };
        return this.http.get<Actor[]>(actorsUrl, httpOptions);
    }



    /**
    * Recupera el registre d'un actor.
    * @param {String} id - El seu id.
    * @return {Actor} - Objecte actor.
    */
    async getActorById(id): Promise<any> {
        var _id = new BSON.ObjectId(id);
        return await this.db.collection('Actors').findOne(
            { _id: _id }
        )
        .catch(e => {
            alert(' Fallo en la conexión al servidor función getActorById: ' + e)
        });
    }

    /**
     * Obté la informació relativa a l'usuari.
     * @param {Object} user - L'usuari d'Stitch.
     * @return {Actor} - Objecte actor.
     */
    async getActorByEmail(user): Promise<any> {
        return await this.db.collection('Actors').findOne(
            { email: user }
        )
        .catch(e => {
            alert(' Fallo en la conexión al servidor función getActorByEmail: ' + e)
        });
    }

    /**
    * Obté els actors relacionats amb una determinada entitat.
    * @param {String} id - El seu id.
    * @return {Array} - Conjunt d'actors associats a una entitat.
    */
    async getActorsEntidadById (idEntidad): Promise<any[]>{
        var _idEntidad = new BSON.ObjectId(idEntidad);
        return await this.db.collection('Actors').aggregate([
            { $match: {$and : [{'_idEntidad': _idEntidad}, {'emailsAutorizados': this.stitch.client.auth.user.profile.email}]}},
            { $lookup: {
                'from': 'Entidades', 
                'localField': '_idEntidad', 
                'foreignField': '_id', 
                'as': 'entidad'
            }},
            { $project: {
                _id: 1,
                Nom:1,
                NifNie :1,
                Cognom1:1,
                Cognom2:1,
                Telefono:1,
                Ext:1,
                email:1,
                Rol:1,
                Notas:1,
                topic: 1,
                entidad: '$entidad.Rao'
            }}
        ])
        .toArray()
    }

    /**
    * Obté els valors dels actors per a seleccionar relacions.
    * @param {Array} actores - Relació (Array) d'actors.
    */
    async getActoresToSelect(): Promise<any[]> {
        return await this.db.collection('Actors').aggregate([
            { $project: {
                Nom : 1,
                Cognom1:1,
                Cognom2: 1,
                email: 1
                }
            },
            { $sort: {
                Nom: 1
                }
            }
        ]).toArray()
    }

    /**
    * Modifica el registre o inserta un actor.
    * @param {Actor} actor - L'objecte actor.
    * @param {String} user - L'usuari que modifica l'actor.
    */
    async setActorById(actor): Promise<void> {
        if(!this.administrador){
            alert('No tiene permisos para modificar registros.');
            return;
        } else {
        await this.db.collection('Actors').updateOne(
            { _id: actor[0] },
            { $set: {
                Nom: actor[1],
                Cognom1: actor[2],
                Cognom2: actor[3],
                NifNie: actor[4],
                Telefono: actor[5],
                Ext: actor[6],
                email: actor[7],
                Rol: actor[8],
                Notas: actor[9],
                _idEntidad: actor[10],
                emailsAutorizados: environment.administradores.concat([actor[7], actor[11]])
            }},
            {upsert: true}
        )
        .catch (e => {
            alert('Actor no se ha podido registrar debido a: ' + e);
        });
        }
    }

    /**
     * Elimina un actor.
     * @param {ObjectId} id - El id de l'actor.
     * @param {String} user - L'usuari que elimina l'actor.
    */
    async delActorById(id): Promise<void> {
        var _id = new BSON.ObjectId(id);
        if(!this.administrador){
            alert('No tiene permisos para eliminar registros.');
            return;
        }
        if(confirm('Esta acción NO es recuperable. \nDesea eliminar el actor?')) {
            await this.db.collection('Actors').deleteOne(
                { _id: _id }
            )
            .catch (e => {
                alert('Actor no se ha podido eliminar debido a: ' + e);
            });
        }
    }

    /**
    * Obté les dades d'una relació d'actors que participen en el projecte.
    * @param {Array} actores - Relació (Array) d'actors.
    */
   async getActoresAutorizados(actores): Promise<Actor[]> {
    var result = [];
    actores.forEach(async (email) => {
        result.push({
            "autorizado": await this.db.collection('Actors').findOne(
                { email: email },
                { projection: {
                    Nom: 1,
                    NifNie: 1,
                    Cognom1: 1,
                    Cognom2: 1,
                    Telefono: 1,
                    Ext: 1,
                    email: 1,
                    Rol: 1,
                    Notas: 1
                }}
            ).catch (e => {
                alert('Actor no se ha podido añadir a autorizados debido a: ' + e);
            })
        })
    })
    return result;
}
}

@Injectable()
export class ActoresTableService extends ActorsService {

    constructor(http: HttpClient, stitch: stitch){
        super(http, stitch)
        this.getActores().subscribe(result => {
            this.actores = result;
            this._search$.pipe(
                tap(() => this._loading$.next(true)),
                switchMap(() => this._search()),
                tap(() => this._loading$.next(false))
            ).subscribe(result => {
                this._actores$.next(result.actores);
                this._total$.next(result.total);
            });
            this._search$.next();
        });
    }
}