<div class="container">
    <div class="row">
        <div class="col-sm-8 offset-sm-2">
            <h1 class="display-4">Acceso Área Privada</h1>
            <hr class="my-4">
            <h4 class="lead">Bienvenido al Sistema de Información y Gestión, para acceder introduzca su correo electrónico y su clave de acceso.</h4>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username">Usuario</label>
                    <input type="email" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" placeholder="Su correo electrónico"/>
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback"><div *ngIf="f.username.errors.required">Se requiere un usuario registrado</div></div>
                </div>
                <div class="form-group">
                    <label for="password">Contraseña</label>
                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Su clave de acceso"/>
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback"><div *ngIf="f.password.errors.required">Se requiere una clave de acceso</div></div>
                </div>
                <div class="form-group">
                    <button [disabled]="loading" class="btn btn-primary"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Entrar</button>
                    <a routerLink="/registro" class="btn btn-link">Registrarse</a><br><br>
                    <a routerLink="/sendResetPasswordEmail">No recuerda la clave de acceso?</a>
                </div>
            </form>
        </div>
    </div>
</div>