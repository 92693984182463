import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { stitch, ActorsService, EntidadesService, EncargosService, CommentsService, TicketsService } from '../../../_services';
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'actorDetalle',
  templateUrl: './actorDetalle.component.html',
  styleUrls: ['../../../_helpers/components.component.css'],
  providers: [ActorsService, EntidadesService, EncargosService, CommentsService, TicketsService]
})
export class ActorDetalleComponent {

    actorDetalle: any;
    user: any;
    administrador: Boolean;
    db: any;
    actorExistent: any;
    submitted = false;
    entidadPare: any;
    commentsUser: any;
    ticketsAdmin: any;
    encargosAutorizados: any;
    totalTickets: any;
    env: any;
    
  constructor(
    public stitch: stitch,
    public route: ActivatedRoute,
    public router: Router,
    public actorService: ActorsService,
    public entidadService: EntidadesService,
    public encargoService: EncargosService,
    public commentService: CommentsService,
    public ticketService: TicketsService
  ) {
    const id = this.route.snapshot.paramMap.get('_id');
    this.user = this.stitch.client.auth.user.profile.email;
    this.administrador = environment.administradores.includes(this.user);
    this.db = this.stitch.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(environment.mongoDb);
    this.env = environment;

    this.actorService.getActorById(id)
    .then(result => {
      this.actorExistent = result;
      this.entidadService.getEntidadById(this.actorExistent._idEntidad.toString())
      .then( result => {
        this.entidadPare = result;
      });
      this.commentService.getCommentsUserByEmail(this.actorExistent.email)
      .then( result => {
        this.commentsUser = result;
      });
      this.ticketService.getTicketsAdminByEmail(this.actorExistent.email)
      .then( result => {
        this.ticketsAdmin = result;
        this.totalTickets = 0;
        if(this.ticketsAdmin.length != 0){
          var ticketsUser = this.ticketsAdmin.map(a => a.temps);
          this.totalTickets = ticketsUser.reduce((acumulator, currentvalue) => acumulator + currentvalue);
        }
      });
      this.encargoService.getEncargosAutorizadosByEmail(this.actorExistent.email)
      .then(result => {
        this.encargosAutorizados = result;
      })
    });
    //Inicializa stream para cambios en tickets.
    this.watcher();
  }

  async watcher() {
    var tickets = this.db.collection('Tickets');
    const changeStream = await tickets.watch();
    changeStream.onNext(next => {
        this.ticketService.getTicketsAdminByEmail(this.actorExistent.email)
        .then(result => {
          this.ticketsAdmin = result;
          this.totalTickets = this.ticketsAdmin.map(a => a.temps).reduce((acumulator, currentvalue) => acumulator + currentvalue);
        }); 
    });
  }


  procesaSet(actor){
    if(!actor) {
      return;
    } else {
      this.actorService.getActorById(actor[0].toString())
      .then(result => {
        this.actorExistent = result;
      });
    }
  }

  procesaSetAutorizado(){
    this.encargoService.getEncargosAutorizadosByEmail(this.actorExistent.email)
      .then(result => {
        this.encargosAutorizados = result;
      });
  }

  copyElementToClipBoard(element) {
    navigator.clipboard.writeText(element)
    alert ('Texto copiado al portapapeles');
  }

}