<div class="container">
        <div class="row">
                <div class="col-sm-8 offset-sm-2">
                        <h1 class="display-4">Generar nueva contraseña</h1>
                        <hr class="my-4">
                        <h4 class="lead">Una vez introducido el usuario que realiza la solicitud y enviado el formulario, recibirá un correo electrónico para completar su restablecimiento.</h4>
                        <form *ngIf="sendEmailForm" [formGroup]="sendEmailForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                                <label for="email">Usuario (email)</label>
                                <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="ejemplo@ejemplo.com"/>
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Se requiere un correo electrónico válido</div>
                                </div>
                        </div>
                        <div class="form-group">
                                <button [disabled]="loading" class="btn btn-primary">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Enviar
                                </button>
                                <a routerLink="/" class="btn btn-link">Volver</a>
                        </div>
                        </form>
                </div>
        </div>
</div>