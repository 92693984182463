<div class="container-fluid mh-100">
    <h1 class="font-weight-light">Gestión de Comentarios</h1>    
    <form>
        <div class="form-group form-inline">
            <input class="font-weight-lighter" id="cercaTerm" type="text" placeholder="Búsqueda por término..." [formControl]="filter"/>
        </div>
    </form>
    <table *ngIf="comments" class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'> 
    <thead>
        <tr>
            <th>Fecha</th>
            <th>Usuario</th>
            <th>Asunto</th>
            <th>Texto</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let comment of comments$ | async" (click)="openMatriz(comment)">
            <td>{{ comment.fecha | date:'yyy-MM-dd' }}</td>
            <td>{{ comment.actor.email }}</td>
            <td>{{ comment.asunto }}</td>
            <td>{{ comment.texto }}</td>
        </tr>
    </tbody>
    </table>
    <div *ngIf="showSpinner" class="d-flex justify-content-center" style="height: 100vh;">
        <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div><br>
        <p class="lead"> Cargando datos...</p>
    </div>
</div>  