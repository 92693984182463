import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Interaccion } from '../_models';
import { stitch } from './stitch.service';
import { BSON, RemoteMongoClient } from 'mongodb-stitch-browser-sdk';
import { environment } from '../environments/environment';

interface CercaResult {
    interacciones: Interaccion[];
    total: number;
}
  
interface Estado {
    page: number;
    pageSize: number;
    searchTerm: string;
    estados: string[];
}

function matchesInteraccion(text: String, interaccion: Interaccion){
    const term = text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return interaccion.asunto.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || interaccion.texto.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || interaccion.proyecto.codi.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || interaccion.proyecto.descripcio.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term);
}

@Injectable()

export class InteraccionesService {

    user: any;
    administrador: Boolean;
    db: any;

    constructor(
        public http: HttpClient,
        public stitch: stitch
    ){
        this.user = this.stitch.client.auth.user;
        this.db = this.stitch.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(environment.mongoDb);
        this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
    }

    _search$ = new Subject<void>();
    _interacciones$ = new BehaviorSubject<Interaccion[]>([]);
    _total$ = new BehaviorSubject<number>(0);
    _loading$ = new BehaviorSubject<boolean>(true);
    _estado: Estado = {
        page: 1,
        pageSize: 25,
        searchTerm: '',
        estados: ['A resolver', 'En espera', 'Resuelto']
    };

    interacciones: Interaccion[];

    get totalInteracciones() { return this.interacciones.length; }
    get interacciones$() { return this._interacciones$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._estado.page; }
    get pageSize() { return this._estado.pageSize; }
    get searchTerm() { return this._estado.searchTerm; }
    get estados() { return this._estado.estados; }

    set page(page: number) { this._set({page}); }
    set pageSize(pageSize: number) { this._set({pageSize}); }
    set searchTerm(searchTerm: string) { this._set({searchTerm}); }
    set estados(estados: string[]) { this._set({estados}); }
    
    _set(patch: Partial<Estado>) {
        Object.assign(this._estado, patch);
        this._search$.next();
    }

    _search(): Observable<CercaResult>{
        const {pageSize, page, searchTerm, estados} = this._estado;
        let interacciones = this.interacciones.filter(interaccion => matchesInteraccion(searchTerm, interaccion) && estados.includes(interaccion.estado));
        const total = interacciones.length;
        interacciones = interacciones.slice((page-1)*pageSize, (page-1)*pageSize + pageSize);
        return of({interacciones, total});
    }

   /**
    * Obtiene el total de las Interacciones existentes.
    * @return {Array} - Conjunt d'interaccions enregistrades.
    */
    getInteracciones (): Observable<Interaccion[]>{
        const interaccionesUrl = 'https://webhooks.mongodb-realm.com/api/client/v2.0/app/' + environment.appId + '/service/' + environment.webhookService + '/incoming_webhook/Interacciones';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: new HttpParams()
            .set('arg1', environment.mongoDb)
            .set('arg2', this.user.profile.email)
        };
        return this.http.get<Interaccion[]>(interaccionesUrl, httpOptions);
    }

    /**
     * Obté les interaccions d'un encàrrec.
     * @param {String} id - L'identificador de l'encàrrec.
     * @return {Array} - Conjunt d'interaccions enregistrades.
     */
    async getInteraccionesEncargoById(id): Promise<any[]> {
        const _id = new BSON.ObjectId(id);
        return await this.db.collection('Interacciones').aggregate([
            { $match: { 'proyecto._id': _id }},
            { $project: {
                user: 1,
                fecha: 1,
                asunto: 1,
                texto: 1,
                estado: 1
            }},
            { $sort: { fecha: -1 }}
        ]).toArray()
    }

    /**
     * Registra una nueva interacción.
     * @param {Object} interaccion - L'objecte interacció.
    */
    async setInteraccionById(interaccion): Promise<void> {
        await this.db.collection('Interacciones').updateOne(
            { _id: interaccion[0]},
            { $set: {
                fecha: interaccion[1],
                asunto: interaccion[2],
                texto: interaccion[3],
                estado: interaccion[4],
                proyecto: {
                    _id: interaccion[5],
                    codi: interaccion[6],
                    descripcio: interaccion[7],
                },
                emailsAutorizados: interaccion[8],
                user: this.stitch.client.auth.user.profile.email
            }},
            { upsert: true}
        )
        .catch (e => {
            alert('Interacción no se ha podido registrar debido a: ' + e)
        });
    }

    /**
     * Elimina una interacció del projecte.
     * @param {ObjectId} id - El id de la interacció.
     * @param {String} user - L'usuari que elimina la interacció.
   */
    async delInteraccionById(id): Promise<void> {
        if(!this.administrador){
        alert('No tiene permisos para eliminar registros.');
        return;
        }
        if(id === ''){
            alert('Ninguna interacción registrada para eliminar.');
            return;
        } else {
            if(confirm('Esta acción NO es recuperable. \nDesea eliminar la interacción?')) {
                await this.db.collection('Interacciones').deleteOne(
                    { _id: id }
                )
                .catch (e => {
                alert('Interacción no se ha podido eliminar debido a: ' + e);
                });
            }
        }
    }
}

@Injectable()
export class InteraccionesTableService extends InteraccionesService {
    constructor(http: HttpClient, stitch: stitch){
        super(http, stitch)
        this.getInteracciones().subscribe(result => {
            this.interacciones = result;
            this._search$.pipe(
                tap(() => this._loading$.next(true)),
                switchMap(() => this._search()),
                tap(() => this._loading$.next(false))
            ).subscribe(result => {
                this._interacciones$.next(result.interacciones);
                this._total$.next(result.total);
            });
            this._search$.next();
        });
    }
}