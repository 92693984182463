<span *ngIf="administrador" style="cursor: pointer"><svg (click)="open(content)" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M5 15v-3h3v-2H5V7H3v3H0v2h3v3zm7-1.25c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5zM7.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H7.34zM12 12c1.93 0 3.5-1.57 3.5-3.5S13.93 5 12 5 8.5 6.57 8.5 8.5 10.07 12 12 12zm0-5c.83 0 1.5.67 1.5 1.5S12.83 10 12 10s-1.5-.67-1.5-1.5S11.17 7 12 7zm5 5c1.93 0 3.5-1.57 3.5-3.5S18.93 5 17 5c-.24 0-.48.02-.71.07.76.94 1.21 2.13 1.21 3.43 0 1.3-.47 2.48-1.23 3.42.24.05.48.08.73.08zm2.32 2.02c1 .81 1.68 1.87 1.68 3.23V19h3v-1.75c0-1.69-2.44-2.76-4.68-3.23z"/></svg></span>
<ng-template #content let-modal>
    <form [formGroup]="participacionForm" disable (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h4 class="modal-title font-weight-light" id="modal-basic-title">Participación <span style="cursor: pointer"><svg (click)="delParticipacion(encargo._id, entidad._id)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zm2-8h6v8H5v-8zm5-6H6L5 5H2v2h12V5h-3z"/></svg></span></h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="encargo">Selecciona un Encargo:</label>
                    <select formControlName="encargo" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.encargo.errors }">
                        <option value="" disabled selected>...</option>
                        <option *ngFor="let encargo of encargosToSelect" value="{{encargo._id}}">{{encargo.properties.codi}} - {{encargo.properties.descripcio}}</option>
                    </select>
                    <div *ngIf="submitted && f.encargo.errors" class="invalid-feedback"><div *ngIf="f.encargo.errors.required">Se requiere un Encargo</div></div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="entidad">Selecciona una Entidad:</label>
                    <select formControlName="entidad" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.entidad.errors }">
                        <option value="" disabled selected>...</option>
                        <option *ngFor="let entidad of entidadesToSelect" value="{{entidad._id}}">{{entidad.Rao}}</option>
                    </select>
                    <div *ngIf="submitted && f.entidad.errors" class="invalid-feedback"><div *ngIf="f.entidad.errors.required">Se requiere una Entidad</div></div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="rol">Describe el Rol:</label>
                    <input type="text" formControlName="rol" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.rol.errors }"/>
                    <div *ngIf="submitted && f.rol.errors" class="invalid-feedback"><div *ngIf="f.rol.errors.required">Se requiere un Rol</div></div>
                </div>
            </div>  
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
            <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar</button>
        </div>
    </form>
</ng-template>