<ngb-toast
      *ngFor="let ticket of ticketService.tickets"
      [class]="ticket.classname"
      [autohide]="true"
      [delay]="ticket.delay || 5000"
      [header]="ticket.time"
      (hide)="ticketService.tancaTicket(ticket)"
    >
      <ng-template [ngIf]="isTemplate(ticket)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="ticket.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ ticket.textOrTpl }} {{ticket.time}}</ng-template>
    </ngb-toast>