import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { stitch } from './stitch.service';

import { SortDirection } from '../_helpers/sortable.directive';
import { RemoteMongoClient, BSON } from 'mongodb-stitch-browser-sdk';
import { environment } from '../environments/environment';

interface CercaResult {
    encargos: any[];
    total: number;
    totalPresupuestos: number;
    totalAños: number[];
    restaFacturar: number;
}
  
interface Estado {
    page: number;
    pageSize: number;
    searchTerm: string;
    estados: string[];
    sortColumn: string;
    sortDirection: SortDirection;
    year: number;
}

function compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }
  
function sort(encargos: any[], column: string, direction: string): any[] {
    if (direction === '') {
        return encargos;
    } else {
        return [...encargos].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
        });
    }
}

function matchesEncargo(text: String, encargo: any){
    const term = text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return encargo.descripcio.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || encargo.codi.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || encargo.localitat.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || encargo.municipi.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || encargo.pareNom[0].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || encargo.titularNom[0].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term);
}

@Injectable()
export class EncargosService {

    administrador: Boolean;
    superUser: Boolean;
    user: any;
    db: any;

    constructor(
        public http: HttpClient,
        public stitch: stitch
    ){
        this.user = this.stitch.client.auth.user;
        this.db = this.stitch.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(environment.mongoDb);
        this.administrador = environment.administradores.includes(stitch.client.auth.user.profile.email);
        this.superUser = environment.superUsers.includes(stitch.client.auth.user.profile.email);
    }

    _loading$ = new BehaviorSubject<boolean>(true);
    _search$ = new Subject<void>();
    _encargos$ = new BehaviorSubject<any[]>([]);
    _total$ = new BehaviorSubject<number>(0);
    _totalPresupuestos$ = new BehaviorSubject<number>(0);
    _restaFacturar$ = new BehaviorSubject<number>(0);
    _totalAños$ = new BehaviorSubject<number[]>([]);
    
    _estado: Estado = {
        page: 1,
        pageSize: 25,
        searchTerm: '',
        estados: ['Redaccion', 'Tramite', 'Ejecucion', 'Finalizado', 'Archivado', 'Anulado' ],
        sortColumn: '',
        sortDirection: '',
        year: 0
    };
    
    encargos: any[];
    restaFacturar: number = 0;

    get totalEncargos() { return this.encargos.length; }
    get encargos$() { return this._encargos$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get totalPresupuestos$() { return this._totalPresupuestos$.asObservable(); }
    get restaFacturar$() { return this._restaFacturar$.asObservable(); }
    get totalAños$() { return this._totalAños$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._estado.page; }
    get pageSize() { return this._estado.pageSize; }
    get searchTerm() { return this._estado.searchTerm; }
    get estados() { return this._estado.estados; }
    get year() { return this._estado.year; }


    set page(page: number) { this._set({page}); }
    set pageSize(pageSize: number) { this._set({pageSize}); }
    set searchTerm(searchTerm: string) { this._set({searchTerm}); }
    set estados(estados: string[]) { this._set({estados}); }
    set year(year: number) { this._set({year}); }
    set sortColumn(sortColumn: string) { this._set({sortColumn}); }
    set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }
    
    _set(patch: Partial<Estado>) {
        Object.assign(this._estado, patch);
        this._search$.next();
    }

    _search(): Observable<CercaResult>{
        const {sortColumn, sortDirection, pageSize, page, searchTerm, estados, year} = this._estado;
        //1. sort
        let encargos = sort(this.encargos, sortColumn, sortDirection);
        //2. filter
        if (year == 0){
            encargos = encargos.filter(encargo => matchesEncargo(searchTerm, encargo) && estados.includes(encargo.estat));
            //4. facturacion total periodos anuales
        if (searchTerm !==''){
            var totalAños = [];
            for (var i=2001; i<2100; i++){
                var encargosTmp = encargos.filter(encargo => new Date(encargo.data).getFullYear() == i);
                var totalAño = encargosTmp.map(encargo => Number(encargo.totalPresupuestado)).reduce((acumulador ,base) => acumulador + base, 0);
                totalAños.push(totalAño);
            };
        } else {
            var totalAños = [];
            for (var i=2001; i<2100; i++){
                var encargosTmp = this.encargos.filter(encargo => new Date(encargo.data).getFullYear() == i);
                var totalAño = encargosTmp.map(encargo => Number(encargo.totalPresupuestado)).reduce((acumulador ,base) => acumulador + base, 0);
                totalAños.push(totalAño);
            };
        }
        } else {
        //4. facturacion mensual del año seleccionado
        var totalAños = [];
        encargos = encargos.filter(encargo => matchesEncargo(searchTerm, encargo) && estados.includes(encargo.estat) && new Date(encargo.data).getFullYear() === year);
        for (var i=0; i<12; i++){
            var encargosMes = encargos.filter(encargo => new Date(encargo.data).getMonth() == i);
            var totalMes = encargosMes.map(encargo => Number(encargo.totalPresupuestado)).reduce((acumulador ,base) => acumulador + base, 0);
            totalAños.push(totalMes);
        }
        }
        const total = encargos.length;
        const totalPresupuestos = encargos.map(encargo => Number(encargo.totalPresupuestado || 0)).reduce((acumulador ,base) => acumulador + base, 0);

        //5. Resta facturar
        let restaFacturar = 0;
        let totalFacturado = encargos.map(encargo => encargo.totalFacturado.$numberDouble ? parseFloat(encargo.totalFacturado.$numberDouble) : 0).reduce((acumulador, base) => acumulador + base, 0);
        restaFacturar = totalPresupuestos - totalFacturado;
        
        //6. paginate
        encargos = encargos.slice((page-1)*pageSize, (page-1)*pageSize + pageSize);
        return of({encargos, total, totalPresupuestos, totalAños, restaFacturar});
    }

    /**
    * Obtiene los encargos existentes.
    * @return {Array} - Conjunt d'enàrrecs enregistrades.
    */
    getEncargos (): Observable<any[]>{
        const encargosUrl = 'https://webhooks.mongodb-realm.com/api/client/v2.0/app/' + environment.appId + '/service/' + environment.webhookService + '/incoming_webhook/EncargosConTotalFacturado';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: new HttpParams()
            .set('arg1', environment.mongoDb)
            .set('arg2', this.user.profile.email)
        };
        return this.http.get<any[]>(encargosUrl, httpOptions);
    }

    /**
     * Obté la informació relativa al encargo.
     * @param {String} id - El seu id.
    */
    async getEncargoById(id): Promise<any> {
        var _id = new BSON.ObjectID(id);
        return await this.db.collection('Proyectos').findOne(
            {
            _id: _id
            }
        )
        .catch(e => {
            alert('Fallo en la conexión al servidor función getEncargoById: ' + e)
        })
    }

    /**
     * Obté els projectes o encàrrecs de l'entitat.
     * @param {String} id - L'identificador de la entitat.
    */
    async getEncargosEntidadById(id): Promise<any[]> {
        var _id = new BSON.ObjectId(id);
        return await this.db.collection('Proyectos').aggregate([
            { $match: { $or: [{_idPare: _id}, {_idTitular: _id}] }},
            { $project: {
                "properties.codi": "$properties.codi",
                "properties.data": "$properties.data",
                "properties.descripcio": "$properties.descripcio",
                "properties.estat": "$properties.estat",
                "properties.totalPresupuestado": "$properties.totalPresupuestado"
            }},
            { $sort: {
                'properties.data': -1,
                'properties.codi': -1
            }}
        ])
        .toArray()
    }

    /**
     * Obté els projectes o encàrrecs en els quals l'entitat actua com a pare, com a titular o que ha liquidat alguna factura.
     * @param {String} id - L'identificador de la entitat.
    */
   async getProyectosEntidadEsPareTitularLiquidaById(db, id): Promise<any[]> {
        return await this.stitch.client.callFunction("getProyectosEntidadByIdLiquidador", [db, id])
        /*var _id = new BSON.ObjectId(id);
        var result = [];
        var Proyectos = await this.stitch.db.collection('Proyectos');
        var Facturas = await this.stitch.db.collection('Facturas');
        
        try { Facturas.find(
            { 'liquidador._idLiquidador': _id }
        )
        .toArray()
        .then((facturas) => {
            
            facturas.forEach((factura: Factura) => {
                Proyectos.find(
                    { _id: factura.proyecto._idProyecto}
                ).toArray().then( proyecto => {
                    result.push(proyecto)
                })
            });
            console.log(result);
        });
    } catch {}      
        return result;*/
    }

    /**
     * Obté els projectes o encàrrecs en els quals l'actor està autoritzat.
     * @param {String} email - L'identificador de l'actor autoritzat.
    */
   async getEncargosAutorizadosByEmail(email): Promise<any[]> {
        return await this.db.collection('Proyectos').find(
            { 'emailsAutorizados': email },
            { projection: {
                "properties.codi": 1,
                "properties.descripcio": 1,
                "properties.data": 1,
                "properties.estat": 1
                },
                sort: {'properties.codi': -1 }
            }
        ).toArray()
    }

    /**
     * Obté els projectes o encàrrecs on els quals l'entitat participa.
     * @param {String} id - El seu identificador.
     */
    async getParticipacionesEntidadById(id): Promise<any>{
        const _id = new BSON.ObjectId(id);
        const Proyectos = await this.db.collection('Proyectos');
        return Proyectos.aggregate([
            { $match: { $and: [
                { "participantes": { $elemMatch: { _id: _id }}},
                { 'emailsAutorizados': this.stitch.client.auth.user.profile.email }
            ]}},
            { $lookup: {
                'from': 'Entidades',
                'localField': '_idTitular',
                'foreignField': '_id',
                'as': 'pare2'
            }},
            { $project: {
                participacion: {
                    $filter: {
                        'input': '$participantes',
                        'as': 'participacion',
                        'cond': { $eq: ['$$participacion._id', _id]}
                    }
                },
                _id : 1,
                codi: "$properties.codi",
                descripcio: "$properties.descripcio",
                estat: "$properties.estat",
                data: "$properties.data",
                localitat: "$properties.localitat",
                municipi: "$properties.municipi",
                pareNom: "$pare2.Rao",
                _idPare: "$_idPare",
                titularNom: "$titular2.Rao",
                _idTitular: "$_idTitular"
            }},
            { $sort: { "codi": -1 }}
        ]).toArray()
    }

    /**
     * Afegeix la participació de l'entitat en un encàrrec.
     * @param {String} idProyecto - L'identificador de l'encàrrec on s'autoritza l'autor.
     * @param {String} idPare - L'identificador del pare de l'encàrrec on s'autoritza l'autor.
     * @param {String} idTitular - L'identificador del titular de l'encàrrec on s'autoritza l'autor.
     * @param {String} email - El mail de l'actor autoritzat.
     * @assign Assigna els valors a la variable @autorizadosEncargo
     */
    async addActorAutorizado(idProyecto, idPare, idTitular, email): Promise<void> {
        if(!this.administrador){
            alert('No tiene permisos para agregar y/o modificar registros.');
            return;
        } else {
            await this.db.collection('Proyectos').updateOne(
                { _id: idProyecto },
                { $push: { 'emailsAutorizados': email }}
            ).then(async () => {
                await this.db.collection('Entidades').updateOne(
                    { _id: idPare },
                    { $push: { 'emailsAutorizados': email }}
                )
            }).then(async () => {
                await this.db.collection('Entidades').updateOne(
                    { _id: idTitular },
                    { $push: { 'emailsAutorizados': email }}
                )
            }).then(async () => {
                await this.db.collection('Fotos').updateMany(
                    { _idEncargo: idProyecto},
                    { $push: { 'emailsAutorizados': email }}
                )
            }).then(async () => {
                await this.db.collection('Interacciones').updateMany(
                    { 'proyecto._id': idProyecto },
                    { $push: { 'emailsAutorizados': email }}
                )
            }).then(async () => {
                await this.db.collection('Documentos').updateMany(
                    { _idMatriz: idProyecto },
                    { $push: { 'emailsAutorizados': email }}
                )
            })
            .catch(e => {
                alert('Autorizado no se ha podido registrar debido a: ' + e);
            })
        }
    }

    /**
     * Elimina la participació de l'entitat en un encàrrec.
     * @param {String} idProyecto - L'identificador de l'encàrrec on s'autoritza l'autor.
     * @param {String} idPare - L'identificador del pare de l'encàrrec on s'autoritza l'autor.
     * @param {String} idTitular - L'identificador del titular de l'encàrrec on s'autoritza l'autor.
     * @param {String} email - El mail de l'actor autoritzat.
     */
    async delActorAutorizado(idProyecto, idPare, idTitular, email): Promise<void> {
        if(!this.administrador){
            alert('No tiene permisos para agregar y/o modificar registros.');
            return;
        } else {
            await this.db.collection('Proyectos').updateOne(
                { _id: idProyecto },
                { $pull: { 'emailsAutorizados': email }}
            ).then(async () => {
                await this.db.collection('Entidades').updateOne(
                    { _id: idPare },
                    { $pull: { 'emailsAutorizados': email }}
                )
            }).then(async () => {
                await this.db.collection('Entidades').updateOne(
                    { _id: idTitular },
                    { $pull: { 'emailsAutorizados': email }}
                )
            }).then(async () => {
                await this.db.collection('Fotos').updateMany(
                    { _idEncargo: idProyecto},
                    { $pull: { 'emailsAutorizados': email }}
                )
            }).then(async () => {
                await this.db.collection('Interacciones').updateMany(
                    { 'proyecto._id': idProyecto },
                    { $pull: { 'emailsAutorizados': email }}
                )
            }).then(async () => {
                await this.db.collection('Documentos').updateMany(
                    { _idMatriz: idProyecto },
                    { $pull: { 'emailsAutorizados': email }}
                )
            })
            .catch(e => {
                alert('Autorizado no se ha podido registrar debido a: ' + e);
            })
        }
    }

    /**
     * Afegeix la participació de l'entitat en un encàrrec.
     * @param {String} idProyecto - L'identificador de l'encàrrec on participa.
     * @param {String} idParticipante - L'identificador de l'entitat que hi participa.
     * @param {String} rol - La funció o el rol que desenvolupa l'entitat a l'encàrrec.
     * @param {String} rao - La rao social de l'entitat que hi participa.
     * @assign Assigna els valors a la variable @participacionesEntidad
     */
    async addParticipacionEntidad(idProyecto, idParticipante, rol, rao): Promise<void> {
        if(!this.administrador){
            alert('No tiene permisos para agregar y/o modificar registros.');
            return;
        } else {
            const _idProyecto = new BSON.ObjectId(idProyecto);
            const _idParticipante = new BSON.ObjectId(idParticipante);
                await this.db.collection('Proyectos').updateOne(
                { _id: _idProyecto },
                { $push: {
                    "participantes": {
                        _id: _idParticipante,
                        rol: rol,
                        rao: rao
                    }
                }}
            )
            .catch(e => {
                alert('Participación no se ha podido registrar debido a: ' + e);
            })
        }
    }

    /**
     * Elimina la participació de l'entitat en un encàrrec.
     * @param {String} idProyecto - L'identificador de l'encàrrec on participa.
     * @param {String} idParticipante - L'identificador de l'entitat que hi participa.
     */
    async delParticipacionEntidad(idProyecto, idParticipante): Promise<void> {
        if(!this.administrador){
            alert('No tiene permisos para agregar y/o modificar registros.');
            return;
        } else {
            await this.db.collection('Proyectos').updateOne(
                { _id: idProyecto },
                { $pull: {
                    "participantes": { _id: idParticipante}
                }}
            )
            .catch(e => {
                alert('Participación no se ha podido eliminar debido a: ' + e);
            })
        }
    }

    /**
     * Estableix la titularidad de l'entitat en un projecte.
     * @param {String} idProyecto - L'identificador del projecte del qual és titular.
     * @param {String} idTitular - L'identificador de l'entitat.
     * @assign Assigna els valors a la variable @titularidadesEntidad
     */
    async addTitularidadEntidad(idProyecto, idTitular): Promise<void> {
        if(!this.superUser){
            alert('No tiene permisos para agregar y/o modificar registros.');
            return;
        } else {
            const _idProyecto = new BSON.ObjectId(idProyecto);
            await this.db.collection('Proyectos').updateOne(
                { _id: _idProyecto },
                { $set: {
                    "_idTitular": idTitular
                }}
            )
            .catch(e => {
                alert('Comentario no se ha podido registrar debido a: ' + e);
            })
        }
    }   

    /**
    * Obté els valors dels projectes per a seleccionar.
    */
    async getProyectosToSelect(): Promise<any> {
        return await this.db.collection('Proyectos').find(
        {},
        {
            projection: {
                "properties.codi": 1,
                "properties.descripcio": 1
            },
            sort: { "properties.codi": -1 }
        })
        .toArray()
        .catch(e => {
            alert(' Fallo en la conexión al servidor función getProyectosToSelect: ' + e)
        });
    }

    /**
    * Obté el codi d'un encàrrec.
    * @param {id} id - L'id de l'objecte encàrrec.
    */
   async getCodiEncargo(id): Promise<any> {
    var _id = new BSON.ObjectId(id);
    return await this.db.collection('Proyectos').findOne(
    {_id: _id},
    {
        projection: {
            _id: 0,
            codi: "$properties.codi"
        }
    })
    .catch(e => {
        alert(' Fallo en la conexión al servidor función getProyectosToSelect: ' + e)
    });
}

    /**
    * Obté els valors dels projectes per a seleccionar.
    */
    async getFeatures(): Promise<any> {
        const conjunto = {
            "type": "FeatureCollection",
            "features": []
        };
        await this.db.collection('Proyectos').find(
        {},
        { projection: {
                _id: 0,
                geometry: 1,
                type: 1,
                "properties.id": {"$toString": "$_id"},
                "properties.codi": 1,
                "properties.descripcio": 1,
                "properties.direccio": 1,
                "properties.estat": 1
                },
            sort: { "properties.codi": -1 }
        })
        .toArray()
        .then(result => {
            conjunto.features = result;
        })
        .catch(e => {
            alert(' Fallo en la conexión al servidor función getFeaturesToSelect: ' + e)
        });
        return conjunto;
    }

    /**
     * Registra un nuevo encargo.
     * @param {Encargo} encargo - L'objecte encàrrec.
    */
    async setEncargoById(encargo): Promise<void> {
        if(!this.superUser){
            alert('No tiene permisos para modificar registros.');
            return;
        } else {
            await this.db.collection('Proyectos').updateOne(
                { _id: encargo[0] },
                { $set: {
                    "type": "Feature",
                    "geometry.coordinates": [encargo[1], encargo[2]],
                    "geometry.type": "Point",
                    "properties.direccio": encargo[3],
                    "properties.cp": encargo[4],
                    "properties.data": encargo[5],
                    "properties.municipi": encargo[6],
                    "properties.refcat": encargo[7],
                    "properties.descripcio": encargo[8],
                    "properties.localitat": encargo[9],
                    "properties.supcons": encargo[10],
                    "properties.codi": encargo[11],
                    "properties.estat": encargo[12],
                    "properties.totalPresupuestado": encargo[16],
                    "participantes": encargo[13],
                    "emailsAutorizados": encargo[17],
                    "_idPare": encargo[14],
                    "_idTitular": new BSON.ObjectId(encargo[15])
                    }},
                   { upsert: true }
            )
        .catch (e => {
            alert('El encargo no se ha podido registrar debido a: ' + e)
        });
        }
    }
    
    /**
     * Elimina un encargo.
     * @param {ObjectId} id - El id de l'encàrrec.
     * @param {String} user - L'usuari que elimina el encargo.
    */
    async delEncargoById(id, mongodb): Promise<void> {
        if(!this.superUser){
            alert('No tiene permisos para eliminar registros.');
            return;
        } else {
        if(confirm('Esta acción NO es recuperable. \nDesea eliminar el encargo?')) {
            await this.stitch.client.callFunction("delEncargoById", [id, mongodb])
            .catch (e => {
            alert('Encargo no se ha podido eliminar debido a: ' + e);
            });
        }
        }
    }

    filtraFeatures(text: String, features: any[]): any[]{
        return features.filter(feature => feature.properties.estat === text)
    }
}

@Injectable()
export class EncargosTableService extends EncargosService {

    constructor(http: HttpClient, stitch: stitch){
        super(http, stitch)
        this.getEncargos().subscribe(result => {
            this.encargos = result;
            this._search$.pipe(
                tap(() => this._loading$.next(true)),
                switchMap(() => this._search()),
                tap(() => this._loading$.next(false))
            ).subscribe(result => {
                this._encargos$.next(result.encargos);
                this._total$.next(result.total);
                this._totalPresupuestos$.next(result.totalPresupuestos);
                this._restaFacturar$.next(result.restaFacturar);
                this._totalAños$.next(result.totalAños);
            });
            this._search$.next();
        });
    }
}