<span style="cursor: pointer"><svg (click)="open(content)" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M7 15h7v2H7zm0-4h10v2H7zm0-4h10v2H7zm12-4h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04-.39.08-.74.28-1.01.55-.18.18-.33.4-.43.64-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"/></svg></span>
<ng-template #content let-modal>
    <form [formGroup]="encargoForm" disable (ngSubmit)="onSubmit()">
        <div class="modal-header">
                <h4 class="modal-title font-weight-light" id="modal-basic-title">Datos del Encargo <span style="cursor: pointer"><svg (click)="delEncargo(f._id.value)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zm2-8h6v8H5v-8zm5-6H6L5 5H2v2h12V5h-3z"/></svg></span></h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
                </button>
        </div>
        <div class="modal-body">
            <div class="form-row">
                <div class="form-group col-md-12">
                <label for="titular">Selecciona una Entidad Titular:</label>
                <select formControlName="titular" class="form-control"><option *ngFor="let titular of entidadesToSelect" value="{{titular._id}}">{{titular.Rao}}</option></select>
            </div>
            </div>
            <div *ngIf="env.features.facturacion" class="form-row">
                <div class="input-group col-md-2 mb-3">
                <label for="totalPresupuestado">Total Presupuestado:</label>
                <input euro type="text" formControlName="totalPresupuestado" class="form-control text-right"/>
                <div class="input-group-append"><span class="input-group-text">€</span></div></div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="descripcio">Descripción</label>
                    <textarea formControlName="descripcio" class="form-control" rows="5" [ngClass]="{ 'is-invalid': submitted && f.descripcio.errors }"></textarea>
                    <div *ngIf="submitted && f.descripcio.errors" class="invalid-feedback"><div *ngIf="f.descripcio.errors.required">Debe introducir una descripción del encargo</div></div>
                </div>
            </div>
            <div class="form-row">
            <div class="form-group col-md-2 mb-3">
                <label for="data">Fecha</label>
                <div class="input-group">
                    <input class="form-control" formControlName="data" placeholder="yyyy-mm-dd" value="{{ f.data.value | date:'yyy-MM-dd' }}" [ngClass]="{ 'is-invalid': submitted && f.data.errors }" name="dp" ngbDatepicker #dp="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                    </div>
                    <div *ngIf="submitted && f.data.errors" class="invalid-feedback"><div *ngIf="f.data.errors.required">Debe introducir una fecha</div></div>
                </div>            
            </div>
            <div class="form-group col-md-2 mb-3">
                <label for="codi">Código Ref.</label>
                <input type="text" formControlName="codi" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.codi.errors }"/>
                <div *ngIf="submitted && f.codi.errors" class="invalid-feedback"><div *ngIf="f.codi.errors.required">Introducir un código válido</div></div>
            </div>
            <div class="form-group col-md-4 mb-3">
                <label for="refcat">Referencia Catastral</label>
                <input type="text" formControlName="refcat" class="form-control"/>
            </div>
            <div class="form-group col-md-2 mb-3">
                <label for="supcons">Sup. Construida</label>
                <input type="text" formControlName="supcons" class="form-control"/>
            </div>
            <div class="form-group col-md-2 mb-3">
                <label for="estat">Estado</label>
                <select class="form-control" formControlName="estat" [ngClass]="{ 'is-invalid': submitted && f.estat.errors }">
                    <option value="Redaccion">En redacción</option>
                    <option value="Tramite">En trámite</option>
                    <option value="Ejecucion">En ejecución</option>
                    <option value="Finalizado">Finalizado</option>
                    <option value="Archivado">Archivado</option>
                    <option value="Anulado">Anulado</option>
                </select>
                <div *ngIf="submitted && f.estat.errors" class="invalid-feedback"><div *ngIf="f.estat.errors.required">Debe indicar el estado</div></div>
            </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-8 mb-3">
                    <label for="ireccio">Dirección</label>
                    <input type="text" formControlName="direccio" class="form-control"/>
                </div>
                <div *ngIf="env.features.mapa" class="form-group col-md-2 mb-3">
                    <label for="Lat">Latitud</label>
                    <input type="text" formControlName="Lat" class="form-control"/>
                </div>
                <div *ngIf="env.features.mapa" class="form-group col-md-2 mb-3">
                    <label for="Lng">Longitud</label>
                    <input type="text" formControlName="Lng" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2 mb-3">
                    <label for="cp">Código Postal</label>
                    <input type="text" formControlName="cp" class="form-control"/>
                </div>
                <div class="form-group col-md-5 mb-3">
                    <label for="localitat">Localidad</label>
                    <input type="text" formControlName="localitat" class="form-control"/>
                </div>
                <div class="form-group col-md-5 mb-3">
                    <label for="municipi">Municipio</label>
                    <input type="text" formControlName="municipi" class="form-control"/>
                </div>
            </div>
            <mapa *ngIf="env.features.mapa" [proyecto]="encargo" (Lat)="procesaLat($event)" (Lng)="procesaLng($event)"></mapa> 
        </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
        <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar</button>
    </div>
    </form>
</ng-template>