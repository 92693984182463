export class Documento {
    constructor(
        public _id: String,
        public owner_id: String,
        public owner_name: String,
        public fecha: Date,
        public descripcion: string,
        public clasificacion: {
            ambito: string,
            area: string,
            tipo: string
        },
        public url: String,
        public file: {
            name: String,
            type: String
        },
        public s3: {
            bucket: String,
            key: String
        },
        public _idMatriz: String,
        public ts: Date,
        public emailsAutorizados: String[]
        )
    {}
}