import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Comment, Actor, Encargo } from '../../../_models';
import { stitch, CommentsService } from '../../../_services';
import { BSON } from 'mongodb-stitch-browser-sdk';

@Component({
    selector: 'formComment',
    templateUrl: 'formComment.component.html',
    providers: [CommentsService],
    styleUrls: ['formComment.component.css']
})

export class FormCommentComponent {
    
    @Input() comment: Comment;
    @Input() user: Actor;
    @Input() encargo: Encargo;
    @Output() commentSet = new EventEmitter<any[]>();

    commentForm : FormGroup;
    submitted = false;
    loading = false;
    fechaComment: any;
    _id: any;

    constructor(
        public stitch: stitch,
        public commentService: CommentsService,
        public modalService: NgbModal,
        public router: Router,
        public fb: FormBuilder
        ){}

    open(content) {
        this.submitted = false;
        if (this.comment._id === ''){
            this.commentForm = this.fb.group({
                _id: [{value: this.comment._id, disabled: true}],
                fecha: ['', Validators.required],
                texto: [''],
                asunto: ['', Validators.required],
                user: [this.user.email]
              });
        } else {
            this.drawCommentForm();
        };
        if (this.commentForm.controls.user.value != ''){
            if(this.f.user.value != this.user.email){
            this.commentForm.disable();
            }
        };
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result;
    }

    get f() { return this.commentForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.loading = true;
        //this.commentForm.controls.fecha.setErrors(null);
        // stop here if form is invalid
        if (this.commentForm.invalid) {
            this.loading = false;
            return;
        }
        /*if(this.f.user.value != this.user.email){
            alert('No está permitido modificar comentarios ajenos.');
            return;
        }*/
        if (this.f._id.value === ''){
            this._id = new BSON.ObjectId();
        } else {
            this._id = this.f._id.value;
        };
        if ( this.f.fecha.value.length === 24 ) {
            this.fechaComment = this.f.fecha.value;
        } else {
            const fecha1 = new Date(this.f.fecha.value.year, this.f.fecha.value.month - 1, this.f.fecha.value.day);
            this.fechaComment = fecha1.toISOString();
        };
        const comment = [ this._id,
                        this.user._id,
                        this.user.Nom,
                        this.user.email,
                        this.f.asunto.value,
                        this.f.texto.value,
                        this.fechaComment,
                        this.encargo._id,
                        this.encargo.properties.codi,
                        this.encargo.properties.descripcio
                        ]
        this.commentService.setCommentById(comment, this.stitch.client.auth.user.profile.email, this.f.user.value)
        .then(() => {
            this.loading = false;
            this.commentSet.emit(comment);
            this.submitted = false;
            this.modalService.dismissAll();
        });
    }

    delComment(id){
        this.loading = true;
        this.commentService.delComment(id, this.stitch.client.auth.user.profile.email, this.comment.actor.email)
        .then(() => {
            this.loading = false;
            this.commentSet.emit();
            this.submitted = false;
            this.modalService.dismissAll();
        });
    }

    drawCommentForm(){
        this.commentForm =  this.fb.group({
            _id: [this.comment._id],
            fecha: [{value: this.comment.fecha, disabled: true}, Validators.required],
            asunto: [this.comment.asunto, Validators.required],
            texto: [this.comment.texto],
            user: [this.comment.actor.email]
        });
    }
}