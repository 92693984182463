import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Factura } from '../_models';
import { stitch } from './stitch.service';
import { SortDirection } from '../_helpers/sortable.directive';
import { RemoteMongoClient, BSON } from 'mongodb-stitch-browser-sdk';
import { environment } from '../environments/environment';

interface CercaResult {
    facturas: any[];
    total: number;
    totalBase: number;
    totalAños: number[];
}
  
interface Estado {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: string;
    sortDirection: SortDirection;
    estados: string[];
    year: number;
}

function compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}
  
function sort(facturas: any[], column: string, direction: string): any[] {
    if (direction === '') {
        return facturas;
    } else {
        return [...facturas].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
        });
    }
}

function matchesFactura(text: String, factura: any){
    const term = text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return factura.codifactura.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || factura.concepto.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || factura.codiProyecto.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || factura.descripcioProyecto.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || factura.raoLiquidador.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term);
}

@Injectable()
export class FacturasService {

    private entidadLiquidadora: any;
    user: any;
    superUser: Boolean;
    db: any;
    
    constructor(
        private http: HttpClient,
        private stitch: stitch
    ){
        this.user = this.stitch.client.auth.user;
        this.db = this.stitch.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(environment.mongoDb);
        this.superUser = environment.superUsers.includes(this.stitch.client.auth.user.profile.email);
    }

    _search$ = new Subject<void>();
    _facturas$ = new BehaviorSubject<any[]>([]);
    _total$ = new BehaviorSubject<number>(0);
    _totalBase$ = new BehaviorSubject<number>(0);
    _totalAños$ = new BehaviorSubject<number[]>([]);
    _loading$ = new BehaviorSubject<boolean>(true);
    _estado: Estado = {
        page: 1,
        pageSize: 25,
        searchTerm: '',
        sortColumn: '',
        sortDirection: '',
        estados: ['Al cobro', 'Liquidada'],
        year: 0
    };
    
    facturas: any[];

    get totalFacturas() { return this.facturas.length; }
    get facturas$() { return this._facturas$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get totalBase$() { return this._totalBase$.asObservable(); }
    get totalAños$() { return this._totalAños$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._estado.page; }
    get pageSize() { return this._estado.pageSize; }
    get searchTerm() { return this._estado.searchTerm; }
    get estados() { return this._estado.estados; }
    get year() { return this._estado.year; }

    set page(page: number) { this._set({page}); }
    set pageSize(pageSize: number) { this._set({pageSize}); }
    set searchTerm(searchTerm: string) { this._set({searchTerm}); }
    set year(year: number) { this._set({year}); }
    set sortColumn(sortColumn: string) { this._set({sortColumn}); }
    set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }
    set estados(estados: string[]) { this._set({estados}); }
    
    _set(patch: Partial<Estado>) {
        Object.assign(this._estado, patch);
        this._search$.next();
    }

    _search(): Observable<CercaResult>{
        const {sortColumn, sortDirection, pageSize, page, searchTerm, estados, year} = this._estado;
        //1. sort
        let facturas = sort(this.facturas, sortColumn, sortDirection);
        //2. filter
        if (year == 0){
            facturas = facturas.filter(factura => matchesFactura(searchTerm, factura) && estados.includes(factura.estado));
        //4. facturacion total periodos anuales
        if (searchTerm !==''){
            var totalAños = [];
            for (var i=2004; i<2100; i++){
                var facturasTmp = facturas.filter(factura => new Date(factura.fechafactura).getFullYear() == i);
                var totalAño = facturasTmp.map(factura => Number(factura.baseimponible)).reduce((acumulador ,base) => acumulador + base, 0);
                totalAños.push(totalAño);
            };
        } else {
            var totalAños = [];
            for (var i=2004; i<2100; i++){
                var facturasTmp = this.facturas.filter(factura => new Date(factura.fechafactura).getFullYear() == i);
                var totalAño = facturasTmp.map(factura => Number(factura.baseimponible)).reduce((acumulador ,base) => acumulador + base, 0);
                totalAños.push(totalAño);
            };
        }
    } else {
        //4. facturacion mensual del año seleccionado
        var totalAños = [];
        facturas = facturas.filter(factura => matchesFactura(searchTerm, factura) && estados.includes(factura.estado) && new Date(factura.fechafactura).getFullYear() === year);
        for (var i=0; i<12; i++){
            var facturasMes = facturas.filter(factura => new Date(factura.fechafactura).getMonth() == i);
            var totalMes = facturasMes.map(factura => Number(factura.baseimponible)).reduce((acumulador ,base) => acumulador + base, 0);
            totalAños.push(totalMes);
        }
    }
        const total = facturas.length;
        var totalBase = facturas.map(factura => Number(factura.baseimponible)).reduce((acumulador ,base) => acumulador + base, 0);
        //3. paginate
        facturas = facturas.slice((page-1)*pageSize, (page-1)*pageSize + pageSize);
        return of({facturas, total, totalBase, totalAños});
    }

   /**
    * Obtiene las facturas existentes.
    * @return {Array} - Conjunt de factures enregistrades.
    */
    getFacturas (): Observable<Factura[]>{
        const facturasUrl = 'https://webhooks.mongodb-realm.com/api/client/v2.0/app/' + environment.appId + '/service/'  + environment.webhookService + '/incoming_webhook/Facturas';
        const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        params: new HttpParams()
        .set('arg1', environment.mongoDb)
        .set('arg2', this.user.profile.email)
        };
        return this.http.get<Factura[]>(facturasUrl, httpOptions);
    }

    /**
     * Obté les factures carregades a l'entitat.
     * @param {String} idEntidad - L'identificador de l'entitat.
    */
    async getFacturasEntidadById(idEntidad): Promise<any[]> {
        var _idEntidad = new BSON.ObjectId(idEntidad);
        return await this.db.collection('Facturas').aggregate([
            { $match: {'liquidador._idLiquidador': _idEntidad }},
            { $project: {
                codifactura:1,
                fechafactura :1,
                fechaalcobro:1,
                fechaliquida:1,
                concepto:1,
                baseimponible:1,
                gastossuplidos:1,
                tipoiva:1,
                tipoirpf:1,
                estado: 1,
                notas:1,
                "proyecto._idProyecto": "$proyecto._idProyecto",
                "proyecto.descripcio": "$proyecto.desrcipcio",
                "proyecto.codi": "$proyecto.codi",
                url:1
            }},
            { $sort: {
                fechafactura: -1,
                codifactura: -1
            }}
        ])
        .toArray()
    }

    /**
     * Obté les factures carregades a l'entitat.
     * @param {String} id - L'identificador de l'encàrrec.
    */
    async getFacturasEncargoById(id): Promise<any[]> {
        var _id = new BSON.ObjectId(id);
        return await this.db.collection('Facturas').find(
            { 'proyecto._idProyecto': _id},
            { sort: {
                fechafactura: -1
            }}
        )
        .toArray()
    }

    /**
     * Modifica el registre d'una factura.
     * @param {Factura} factura - L'objecte factura.
     * @param {String} user - L'usuari que modifica l'actor.
    */
    async setFacturaById(factura): Promise<void> {
        var _idLiquidador = new BSON.ObjectId(factura[13]);
        if(!this.superUser){
            alert('No tiene permisos para modificar registros.');
            return;
        } else {
            const _id = new BSON.ObjectId(factura[13]);
            await this.db.collection('Entidades').findOne({_id: _id}, { projection: {_id: 0, Rao:1, email:1}})
            .then(result => {
                this.entidadLiquidadora = result;
            }).then( async () => {
                if(this.entidadLiquidadora.email === ""){
                    alert('La entidad liquidadora no dispone de un email, añádalo antes de registrar una factura.');
                    return;
                } else {
                    factura.push(this.entidadLiquidadora.Rao, this.entidadLiquidadora.email);
                    await this.db.collection('Facturas').updateOne(
                        { _id: factura[0]},
                        { $set: {
                            'codifactura': factura[1],
                            'baseimponible': factura[2],
                            'gastossuplidos': factura[3],
                            'concepto' :factura[4],
                            'estado': factura[5],
                            'notas': factura[6],
                            'fechafactura': factura[7],
                            'fechaalcobro': factura[8],
                            'fechaliquida': factura[9],
                            'proyecto._idProyecto': factura[10],
                            'proyecto.codi': factura[11],
                            'proyecto.descripcio': factura[12],
                            'liquidador.razon': factura[16],
                            'liquidador._idLiquidador': _idLiquidador,
                            'tipoirpf': factura[14],
                            'tipoiva': factura[15],
                            'emailsAutorizados': environment.superUsers.concat([factura[17]])
                        }},
                        {upsert : true}
                    )
                    .catch( e => {alert('Factura no se ha podido modificar debido a: ' + e)})
                }
            });
        }
    }


    /**
     * Elimina una factura.
     * @param {ObjectId} id - El id de la factura.
     * @param {String} user - L'usuari que elimina la factura.
    */
    async delFacturaById(id): Promise<void> {
        var _id = new BSON.ObjectId(id);
        if(!this.superUser){
            alert('No tiene permisos para eliminar registros.');
            return;
        } else {
            if(confirm('Esta acción NO es recuperable. \nDesea eliminar la factura?')) {
                await this.db.collection('Facturas').deleteOne(
                    { _id: _id }
                )
                .catch (e => {
                    alert('Factura no se ha podido eliminar debido a: ' + e);
                });
            }
        }
    }
}

@Injectable()
export class FacturasTableService extends FacturasService {

    constructor(http: HttpClient, stitch: stitch){
        super(http, stitch)
        this.getFacturas().subscribe(result => {
            this.facturas = result;
            this._search$.pipe(
                tap(() => this._loading$.next(true)),
                switchMap(() => this._search()),
                tap(() => this._loading$.next(false))
            ).subscribe(result => {
                this._facturas$.next(result.facturas);
                this._total$.next(result.total);
                this._totalBase$.next(result.totalBase);
                this._totalAños$.next(result.totalAños);
            });
            this._search$.next();
        });
    }
}