<ng-template #content let-modal>
    <form [formGroup]="entidadForm" disable (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h4 class="modal-title font-weight-light" id="modal-basic-title">Datos de la Entidad <span style="cursor: pointer"><svg (click)="delEntidad(f._id.value)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zm2-8h6v8H5v-8zm5-6H6L5 5H2v2h12V5h-3z"/></svg></span></h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-row">
                <div class="form-group col-md-12 mb-3">
                    <label for="Rao">Razón Social</label>
                    <input type="text" formControlName="Rao" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Rao.errors }"/>
                    <div *ngIf="submitted && f.Rao.errors" class="invalid-feedback"><div *ngIf="f.Rao.errors.required">Se requiere una Razón Social</div></div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-4 mb-3">
                    <label for="CifNif">Id. Fiscal</label>
                    <input type="text" formControlName="CifNif" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.CifNif.errors }" />
                    <div *ngIf="submitted && f.CifNif.errors" class="invalid-feedback"><div *ngIf="f.CifNif.errors.required">Se requiere una Id fiscal</div></div>
                </div>
                <div class="form-group col-md-4 mb-3">
                    <label for="DQE">Doc. Empresarial</label>
                    <input type="text" formControlName="DQE" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12 mb-3">
                    <label for="Direccion">Dirección</label>
                    <input type="text" formControlName="Direccion" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Direccion.errors }" />
                    <div *ngIf="submitted && f.Direccion.errors" class="invalid-feedback"><div *ngIf="f.Direccion.errors.required">Se requiere una Dirección</div></div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2 mb-3">
                    <label for="Cp">Código Postal</label>
                    <input type="text" formControlName="Cp" class="form-control"/>
                </div>
                <div class="form-group col-md-5 mb-3">
                    <label for="Localidad">Localidad</label>
                    <input type="text" formControlName="Localidad" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Localidad.errors }" />
                    <div *ngIf="submitted && f.Localidad.errors" class="invalid-feedback"><div *ngIf="f.Localidad.errors.required">Se requiere una Localidad</div></div>
                </div>
                <div class="form-group col-md-5 mb-3">
                    <label for="Municipio">Municipio</label>
                    <input type="text" formControlName="Municipio" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Municipio.errors }"/>
                    <div *ngIf="submitted && f.Municipio.errors" class="invalid-feedback"><div *ngIf="f.Municipio.errors.required">Se requiere un Municipio</div></div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-3 mb-3">
                    <label for="Telefono">Teléfono</label>
                    <input type="text" formControlName="Telefono" class="form-control"/>
                </div>
                <div class="form-group col-md-5 mb-3">
                    <label for="email">email</label>
                    <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback"><div *ngIf="f.email.errors.required">Se requiere un email</div></div>
                </div>
                <div class="form-group col-md-4 mb-3">
                    <label for="web">web</label>
                    <input type="text" formControlName="web" class="form-control"/>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
            <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar</button>
        </div>
    </form>
</ng-template>
<span style="cursor: pointer"><svg (click)="open(content)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M4 11.5v4c0 .83.67 1.5 1.5 1.5S7 16.33 7 15.5v-4c0-.83-.67-1.5-1.5-1.5S4 10.67 4 11.5zm6 0v4c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5zM3.5 22h16c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-16c-.83 0-1.5.67-1.5 1.5S2.67 22 3.5 22zM16 11.5v4c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5zM10.57 1.49l-7.9 4.16c-.41.21-.67.64-.67 1.1C2 7.44 2.56 8 3.25 8h16.51C20.44 8 21 7.44 21 6.75c0-.46-.26-.89-.67-1.1l-7.9-4.16c-.58-.31-1.28-.31-1.86 0z"/></svg></span>