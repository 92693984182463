import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';


import { Interaccion } from '../../../_models';
import { InteraccionesService, InteraccionesTableService } from '../../../_services';

@Component({
    selector: 'interacciones',
    templateUrl: 'interacciones.component.html',
    providers: [{provide: InteraccionesService, useClass: InteraccionesTableService}],
    styleUrls: ['../../../_helpers/components.component.css', 'interacciones.component.css']
})

export class InteraccionesComponent {
    
    interacciones$: Observable<Interaccion[]>;
    total$: Observable<number>;

    constructor(
        public route: Router,
        public interaccionesTableService: InteraccionesService)
        {
            this.interacciones$ = interaccionesTableService.interacciones$;
            this.total$ = interaccionesTableService.total$;
        }

    openProjecte(id) {
        this.route.navigate(['/encargoDetalle/' + id]);
    }

    setEstado(estado){
        var estados = this.interaccionesTableService.estados;
        if(estados.includes(estado)){
            const index = estados.indexOf(estado);
            if( index > -1) {
                estados.splice(index,1);
            }
            this.interaccionesTableService.estados = estados;
        } else {
            estados.push(estado);
            this.interaccionesTableService.estados = estados;
        }
    }
}