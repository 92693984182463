<div class="container-fluid overflow-auto">
    <div class="row">
        <div class="col-8">
        <div *ngIf="encargo && pareEncargo && user" class="container-fluid mh-100">
            <h2 class="font-weight-light"><a routerLink="/entidadDetalle/{{encargo?._idPare}}">{{ pareEncargo?.Rao }}</a></h2>
            <h2 class="font-weight-light">{{ encargo?.properties.descripcio }} <span style="cursor: pointer"><svg (click)="copyElementToClipBoard(encargo?.properties.descripcio)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-copy" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg></span><formEncargo [encargo]="encargo" [pare]="pareEncargo" [titular]="titularEncargo" (encargoSet)="procesaSetEncargo($event)"></formEncargo></h2>
            <h4 class="font-weight-light">{{ encargo?.properties.direccio }} <span style="cursor: pointer"><svg (click)="copyElementToClipBoard(encargo?.properties.direccio)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-copy" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg></span></h4>
            <h4 class="font-weight-light">{{ encargo?.properties.cp }} - {{ encargo?.properties.localitat }} <span style="cursor: pointer"><svg (click)="copyElementToClipBoard(encargo?.properties.cp + ' - ' + encargo?.properties.localitat)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-copy" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg></span></h4>
            <div *ngIf="administrador"><h3 class="font-weight-light">{{ encargo?.properties.codi }} - <i>Titular</i>: <a routerLink="/entidadDetalle/{{titularEncargo?._id}}">{{ titularEncargo?.Rao }}</a></h3></div>
        </div>
        </div>
        <div *ngIf="(facturasProyecto?.length > 0) || (superUser) && env.features.facturacion" class="col-4 d-flex justify-content-end">
        <div class="container">
            <div class="row justify-content-end">
                <h6 class="font-weight-light">Total Presupuestos: {{encargo?.properties.totalPresupuestado | currency:'EUR':'symbol':'1.2-2':'es' }}</h6>
            </div>
            <div class="row justify-content-end">
            <h6 class="font-weight-light">Total Facturado: {{ totalFacturado - totalDescuento | currency:'EUR':'symbol':'1.2-2':'es' }}</h6>        
            </div>
            <div class="row justify-content-end">
                <h6 class="font-weight-light">Total Descuentos: {{ totalDescuento | currency:'EUR':'symbol':'1.2-2':'es' }}</h6>        
            </div>
            <div class="row justify-content-end">
                <h6 class="font-weight-light">Total Tickets (hh:mm:ss): {{ ticketService.timeToString(totalTickets) }}</h6>        
                </div>
            <div class="row justify-content-end">
                <h6 class="font-weight-bolder text-danger">Pendiente Facturación: {{ encargo?.properties.totalPresupuestado - totalFacturado | currency:'EUR':'symbol':'1.2-2':'es' }}</h6>        
            </div>
        </div>
        </div>
    </div>
    <app-tickets aria-live="polite" aria-atomic="true"></app-tickets>
<hr class="my-4">
<ngb-tabset type="pills">
<ngb-tab title="Interacciones">
    <ng-template ngbTabContent><span class="badge badge-warning" style="position: relative; bottom:3rem; left: 7.5rem;">{{interaccionesProyecto?.length}}</span><br>
        <div class="row">
            <div class="col-sm-12">
                <h3 class="font-weight-light">Interacciones del Encargo (TimeLine) <formInteraccion *ngIf="administrador" [interaccion]="{_id: ''}" [encargo]="encargo" [pare]="pareEncargo" [titular]="titularEncargo" (interaccionSet)="procesaSetInteraccion($event)"></formInteraccion></h3>
                <div *ngIf="interaccionesProyecto?.length > 0">
                    <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [(pageSize)]="pageSize" [collectionSize]="interaccionesProyecto.length" [boundaryLinks]="true"></ngb-pagination>
                    <table class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'> 
                        <thead>
                            <tr>
                                <th class="dataCol">Fecha</th>
                                <th style="width: 82%">Asunto</th>
                                <th class="openIcon">Ver</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lineaInteraccion of interaccionesProyecto | slice: (page-1) * pageSize: (page-1) * pageSize + pageSize" [ngClass]="{'table-warning': lineaInteraccion.estado === 'En espera', 'table-danger': lineaInteraccion.estado ==='A resolver'}">
                                <td>{{ lineaInteraccion.fecha | date:'dd-MM-yyy' }}</td>
                                <td>{{ lineaInteraccion.asunto }}</td>
                                <td><formInteraccion [interaccion]="lineaInteraccion" [encargo]="encargo" [pare]="pareEncargo" [titular]="titularEncargo" (interaccionSet)="procesaSetInteraccion($event)"></formInteraccion></td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="showSpinner" class="d-flex justify-content-center">
                        <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>        
                </div> 
            </div>
        </div>
    </ng-template>
</ngb-tab>
<ngb-tab title="Documentos">
    <ng-template ngbTabContent><span class="badge badge-warning" style="position: relative; bottom:3rem; left: 15.5rem;">{{documentosProyecto?.length}}</span><br>
        <div class="row">
            <div class="col-sm-12">
                <h3 class="font-weight-light">Documentos del Encargo <formDocumento *ngIf="administrador" [documento]="{_id: ''}" [user]="actorExistent" [encargo]="encargo" [entidadPare]="pareEncargo" [entidadTitular]="titularEncargo" (documentoSet)="procesaSetDocumento($event)"></formDocumento></h3>
                <div *ngIf="documentosProyecto?.length > 0">
                    <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [(pageSize)]="pageSize" [collectionSize]="documentosProyecto.length" [boundaryLinks]="true"></ngb-pagination>
                    <table class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'> 
                        <thead>
                            <tr>
                                <th class="dataCol">Fecha</th>
                                <th style="width: 2%">File</th>
                                <th style="width: 75%">Descripción</th>
                                <th style="width: 5%">Ámbito</th>
                                <th style="width: 5%">Área</th>
                                <th style="width: 5%">Tipo</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lineaDocumento of documentosProyecto | slice: (page-1) * pageSize: (page-1) * pageSize + pageSize" (click)="openToEditDocumento(editarDocumento, lineaDocumento)">
                                <td>{{ lineaDocumento.fecha | date:'dd-MM-yyy' }}</td>
                                <td><span style="cursor: pointer"><a href="{{lineaDocumento.url}}" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/><path d="M0 0h24v24H0z" fill="none"/></svg></a></span></td>
                                <td>{{ lineaDocumento.descripcion }}</td>
                                <td>{{ lineaDocumento.clasificacion.ambito }}</td>
                                <td>{{ lineaDocumento.clasificacion.area }}</td>
                                <td>{{ lineaDocumento.clasificacion.tipo }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="showSpinner" class="d-flex justify-content-center">
                        <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>     
                </div>
            </div>
        </div>
    </ng-template>
</ngb-tab>
<ngb-tab title="Planos">
    <ng-template ngbTabContent><span class="badge badge-warning" style="position: relative; bottom:3rem; left: 21rem;">{{planosProyecto?.length}}</span><br>
        <div class="row">
            <div class="col-sm-12">
                <h3 class="font-weight-light"><span style="cursor: pointer" (click)="open(carouselPlanos)">Planos del Encargo </span><formDocumento *ngIf="administrador" [documento]="{_id: ''}" [user]="actorExistent" [encargo]="encargo" [entidadPare]="pareEncargo" [entidadTitular]="titularEncargo" (documentoSet)="procesaSetDocumento($event)"></formDocumento></h3>
                <div *ngIf="planosProyecto?.length > 0">
                    <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [(pageSize)]="pageSize" [collectionSize]="planosProyecto.length" [boundaryLinks]="true"></ngb-pagination>
                    <table class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'> 
                        <thead>
                            <tr>
                                <th class="dataCol">Fecha</th>
                                <th style="width: 2%">File</th>
                                <th style="width: 75%">Descripción</th>
                                <th style="width: 5%">Ámbito</th>
                                <th style="width: 5%">Área</th>
                                <th style="width: 5%">Tipo</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lineaPlano of planosProyecto | slice: (page-1) * pageSize: (page-1) * pageSize + pageSize" (click)="openToEditDocumento(editarDocumento, lineaPlano)">
                                <td>{{ lineaPlano.fecha | date:'dd-MM-yyy' }}</td>
                                <td><span style="cursor: pointer"><a href="{{lineaPlano.url}}" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/><path d="M0 0h24v24H0z" fill="none"/></svg></a></span></td>
                                <td>{{ lineaPlano.descripcion }}</td>
                                <td>{{ lineaPlano.clasificacion.ambito }}</td>
                                <td>{{ lineaPlano.clasificacion.area }}</td>
                                <td>{{ lineaPlano.clasificacion.tipo }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="showSpinner" class="d-flex justify-content-center">
                        <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>     
                </div>
            </div>
        </div>
    </ng-template>
</ngb-tab>
<ngb-tab *ngIf="env.features.autorizados" title="Comentarios">
    <ng-template ngbTabContent><br>
        <div class="row">
            <div class="col-sm-12">
                <h3 class="font-weight-light">Comentarios al Encargo <formComment [comment]="{_id: ''}" [user]="actorExistent" [encargo]="encargo" (commentSet)="procesaSetComment($event)"></formComment></h3>
                <div *ngIf="commentsProyecto?.length > 0">
                <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [(pageSize)]="pageSize" [collectionSize]="commentsProyecto.length" [boundaryLinks]="true"></ngb-pagination>
                <table class="table table-striped table-bordered" style="width:100%" data-page-length='25'>
                        <thead>
                            <tr>
                                <th style="width: 20%">Actor</th>
                                <th class="dataCol">Fecha</th>
                                <th style="width: 69%">Asunto</th>
                                <th style="width: 3%">Editar</th>
                            </tr>
                        </thead>
                        <tbody>
                                <tr *ngFor="let lineaComment of commentsProyecto | slice: (page-1) * pageSize: (page-1) * pageSize + pageSize">
                                    <td><a routerLink="/actorDetalle/{{lineaComment.actor._id}}">{{ lineaComment.actor.email }}</a></td>
                                    <td>{{ lineaComment.fecha | date:'dd-MM-yyy' }}</td>
                                    <td>{{ lineaComment.asunto | slice:0:40 }}</td>
                                    <td><formComment [comment]="lineaComment" [user]="actorExistent" [encargo]="encargo" (commentSet)="procesaSetComment($event)"></formComment></td>
                                    </tr>
                        </tbody>
                    </table>
                    </div>       
            </div>
        </div>
    </ng-template>
</ngb-tab>
<ngb-tab title="Fotos">
    <ng-template ngbTabContent><br>
        <div class="row">
                <div class="col-sm-12">
                <h3 class="font-weight-light"><span style="cursor: pointer" (click)="open(carouselFotos)">Fotos del Encargo </span><formFoto *ngIf="administrador" [foto]="{_id: ''}" [user]="actorExistent" [encargo]="encargo" (fotoSet)="procesaSetFoto($event)"></formFoto></h3>
                <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [(pageSize)]="pageSize" [collectionSize]="fotosProyecto.length" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>
                <div *ngIf="fotosProyecto?.length > 0" class="card-deck">
                    <div class="card-columns">
                        <div *ngFor="let foto of fotosProyecto | slice: (page-1) * pageSize: (page-1) * pageSize + pageSize" class="card" (click)="openToEditFoto(editarFoto, foto)">
                            <img src="{{foto.url}}" class="card-img-top img-fluid" alt="{{foto.file.name}}">
                            <div class="card-body">
                                <p class="card-text"><small class="text-muted">{{foto.ts | date}}</small></p>
                                <p class="card-text"><small class="text-muted">{{foto.texto}}</small></p>
                                <!--<span style="cursor: pointer" ><svg (click)="downLoadFoto(foto.s3.key)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z"/></svg></span>-->
                                <a href="{{foto.url}}" download="archivo.JPG"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z"/></svg></a>
                                <span style="cursor: pointer"><svg (click)="delFoto(foto.owner_name, foto.s3.key, foto._id)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zm2-8h6v8H5v-8zm5-6H6L5 5H2v2h12V5h-3z"/></svg></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ngb-tab>
<ng-container *ngIf="administrador">
    <ngb-tab title="Participantes">
    <ng-template ngbTabContent><br>
        <div class="row">
                <div class="col-sm-12">
                    <h3 class="font-weight-light">Entidades Participantes <formParticipacion [rol]="''" [entidad]="{_id: ''}" [encargo]="encargo" (participacionSet)="procesaSetParticipacion($event)"></formParticipacion></h3>
                    <div *ngIf="participantesProyecto?.length > 0">
                        <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [(pageSize)]="pageSize" [collectionSize]="participantesProyecto.length" [boundaryLinks]="true"></ngb-pagination>
                        <table class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'>
                            <thead>
                                <tr>
                                    <th class="entidadCol">Entidad</th>
                                    <th style="width: 42%">Rol</th>
                                    <th style="width: 10%">Teléfono</th>
                                    <th style="width: 15%">email</th>
                                    <th class="openIcon">Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let participante of participantesProyecto | slice: (page-1) * pageSize: (page-1) * pageSize + pageSize">
                                    <td><a routerLink="/entidadDetalle/{{participante.entidad._id}}">{{ participante.entidad.Rao }}</a></td>
                                    <td>{{ participante.rol }}</td>
                                    <td><a href= "tel: {{participante.entidad.Telefono}}">{{ participante.entidad.Telefono }}</a></td>
                                    <td><a href= "mailto: {{ participante.entidad.email }}">{{ participante.entidad.email }}</a></td>
                                    <td><formParticipacion [rol]="participante.rol" [entidad]="participante.entidad" [encargo]="encargo" (participacionSet)="procesaSetParticipacion($event)"></formParticipacion></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>       
                </div>
            </div>
    </ng-template>
    </ngb-tab>
    <ngb-tab *ngIf="env.features.autorizados" title="Autorizados">
        <ng-template ngbTabContent><br>
            <div class="row">
                    <div class="col-sm-12">
                        <h3 class="font-weight-light">Actores Autorizados <formAutorizado [encargo]="encargo" [idPareEncargo]="pareEncargo._id" [idTitularEncargo]="titularEncargo._id" [email]="''" (autorizadoSet)="procesaSetAutorizado($event)"></formAutorizado></h3>
                        <div *ngIf="actoresAutorizados?.length > 0">
                            <table class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'>
                                <thead>
                                    <tr>
                                        <th style="width: 25%">Nombre</th>
                                        <!--<th>Apellido 1</th>
                                        <th>Apellido 2</th>-->
                                        <th style="width: 10%">Teléfono</th>
                                        <th style="width: 15%">email</th>
                                        <th style="width: 10%">Rol</th>
                                        <th style="width: 37%">Notas</th>
                                        <th class="openIcon">Ver</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let actor of actoresAutorizados">
                                        <td><a routerLink="/actorDetalle/{{actor.autorizado._id}}">{{ actor.autorizado.Nom }} {{actor.autorizado.Cognom1}} {{actor.autorizado.Cognom2}}</a></td>
                                        <!--<td>{{ actor.Cognom1 }}</td>
                                        <td>{{ actor.Cognom2 }}</td>-->
                                        <td><a href= "tel: {{actor.autorizado.Telefono}}">{{ actor.autorizado.Telefono }}</a></td>
                                        <td><a href= "mailto: {{ actor.autorizado.email }}">{{ actor.autorizado.email }}</a></td>
                                        <td>{{ actor.autorizado.Rol }}</td>
                                        <td>{{ actor.autorizado.notas | slice:0:80 }}...</td>
                                        <!--<td><span style="cursor: pointer"><svg (click)="encargoService.delActorAutorizado(encargo._id, pareEncargo._id, titularEncargo._id, actor.autorizado.email, actorExistent.email)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zm2-8h6v8H5v-8zm5-6H6L5 5H2v2h12V5h-3z"/></svg></span></td>-->
                                        <td><formAutorizado [encargo]="encargo" [idPareEncargo]="pareEncargo._id" [idTitularEncargo]="titularEncargo._id" [email]="actor.autorizado.email" (autorizadoSet)="procesaSetAutorizado($event)"></formAutorizado></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>       
                    </div>
                </div>
        </ng-template>
    </ngb-tab>
    <ngb-tab *ngIf="env.features.mapa" title="Establecimiento">
    <ng-template ngbTabContent><br>
    <mapa [proyecto]=encargo></mapa>
    </ng-template>
    </ngb-tab>
    <ngb-tab *ngIf="env.features.tickets" title="Tickets">
        <ng-template ngbTabContent><br>
            <div class="row">
                <div class="col-sm-12">
                    <h3 class="font-weight-light">Tickets Encargo <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="36px" height="36px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z"/><path d="M3 12c0-2.61 1.67-4.83 4-5.65V4.26C3.55 5.15 1 8.27 1 12s2.55 6.85 6 7.74v-2.09c-2.33-.82-4-3.04-4-5.65z"/></svg></h3>
                    <div *ngIf="ticketsEncargo?.length > 0">
                    <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [(pageSize)]="pageSize" [collectionSize]="ticketsEncargo.length" [boundaryLinks]="true"></ngb-pagination>
                    <table class="table table-striped table-bordered" style="width:100%" data-page-length='25'>
                            <thead>
                                <tr>
                                    <th style="width: 15%">Administrador</th>
                                    <th>Tarea</th>
                                    <th style="width: 20%">Fecha</th>
                                    <th style="width: 10%">Temps</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ticket of ticketsEncargo | slice: (page-1) * pageSize: (page-1) * pageSize + pageSize" [ngClass]="{'table-success': ticket.estat === 'Obert', 'table-danger': ticket.estat === 'Pausa'}">
                                    <td><a routerLink="/actorDetalle/{{ticket.admin._id}}">{{ ticket.admin.email }}</a></td>
                                    <td>{{ ticket.tarea }}</td>
                                    <td>{{ ticket.fecha | date:'EEEE, MMM d, y, h:mm:ss a'}}</td>
                                    <td>{{ ticket.estat ==='Tancat' ? ticketService.timeToString(ticket.temps): '' }}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>       
                </div>
            </div>
        </ng-template>
    </ngb-tab>
</ng-container>
<ngb-tab *ngIf="superUser && env.features.facturacion" title="Facturación">
    <ng-template ngbTabContent><br>
        <h3 class="font-weight-light">Facturación del Encargo <formFactura [factura]="{_id: ''}" [user]="actorExistent" [encargo]="encargo" (facturaSet)="procesaSetFactura($event)"></formFactura></h3>
        <div *ngIf="facturasProyecto?.length > 0">
        <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [(pageSize)]="pageSize" [collectionSize]="facturasProyecto.length" [boundaryLinks]="true"></ngb-pagination>
        <table class="table table-striped table-bordered" style="width:100%" data-page-length='25'>
            <thead>
                <tr>
                    <th class="codiCol">Codi</th>
                    <th style="width: 2%">File</th>
                    <th class="dataCol">Data Factura</th>
                    <th>Concepto</th>
                    <th class="valCol">Base Imp.</th>
                    <th class="valCol">Suplidos</th>
                    <th class="dataCol">Liquidada</th>
                    <th class="entidadCol">Liquidador</th>
                    <th class="openIcon">Ver</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let lineafactura of facturasProyecto | slice: (page-1) * pageSize: (page-1) * pageSize + pageSize" [ngClass]="{'table-danger': lineafactura.estado === 'Al cobro'}">
                    <td>{{ lineafactura.codifactura }}</td>
                    <td><span style="cursor: pointer"><a href="{{lineafactura.url}}" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/><path d="M0 0h24v24H0z" fill="none"/></svg></a></span></td>    
                    <td>{{ lineafactura.fechafactura | date:'dd-MM-yyy' }}</td>
                    <td>{{ lineafactura.concepto | slice:0:60 }}...</td>
                    <td>{{ lineafactura.baseimponible | currency:'EUR':'symbol':'1.2-2':'es' }}</td>
                    <td>{{ lineafactura.gastossuplidos | currency:'EUR':'symbol':'1.2-2':'es' }}</td>
                    <td>{{ lineafactura.fechaliquida | date:'dd-MM-yyy'}}</td>
                    <td><a routerLink="/entidadDetalle/{{lineafactura.liquidador._idLiquidador}}">{{ lineafactura.liquidador.razon }}</a></td>
                    <td><formFactura [factura]="lineafactura" [user]="actorExistent" [encargo]="encargo" (facturaSet)="procesaSetFactura($event)"></formFactura></td>
                </tr>
            </tbody>
        </table>
        </div>
    </ng-template>
</ngb-tab>
</ngb-tabset>

<div *ngIf="showSpinner" class="d-flex justify-content-center" style="height: 100vh;">
    <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
    <span class="sr-only">Loading...</span>
    </div><br>
    <p class="lead"> Cargando datos...</p>
</div>

<ng-template #editarFoto let-modal>
<form [formGroup]="editFotoForm" (ngSubmit)="onEditFoto()">
<div class="modal-header">
    <h4 class="modal-title font-weight-light" id="modal-basic-title">Editar Fotografía</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
        <img src="{{editFotoForm.controls.url.value}}" class="img-fluid">
    <div class="form-group">
        <h6 class="modal-title font-weight-light mt-2 mb-2">Selecciona los ámbitos afectados</h6>
        <button type="button" (click)="setAmbitoFoto('Establecimiento', ambitosFoto$)" [ngClass]="setButtonStyle('Establecimiento')">Establecimiento</button>
        <button type="button" (click)="setAmbitoFoto('Accesibilidad', ambitosFoto$)" [ngClass]="setButtonStyle('Accesibilidad')">Accesibilidad</button>
        <button type="button" (click)="setAmbitoFoto('Señalizacion', ambitosFoto$)" [ngClass]="setButtonStyle('Señalizacion')">Señalización</button>
        <button type="button" (click)="setAmbitoFoto('BajaTension', ambitosFoto$)" [ngClass]="setButtonStyle('BajaTension')">Baja Tensión</button>
        <button type="button" (click)="setAmbitoFoto('AltaTension', ambitosFoto$)" [ngClass]="setButtonStyle('AltaTension')">Alta Tensión</button>
        <button type="button" (click)="setAmbitoFoto('Climatizacion', ambitosFoto$)" [ngClass]="setButtonStyle('Climatizacion')">Climatización</button>
        <button type="button" (click)="setAmbitoFoto('Ventilacion', ambitosFoto$)" [ngClass]="setButtonStyle('Ventilacion')">Ventilación</button>
        <button type="button" (click)="setAmbitoFoto('ContraIncendios', ambitosFoto$)" [ngClass]="setButtonStyle('ContraIncendios')">Contra Incendios</button>
        <button type="button" (click)="setAmbitoFoto('AguaFria', ambitosFoto$)" [ngClass]="setButtonStyle('AguaFria')">Agua Fría</button>
        <button type="button" (click)="setAmbitoFoto('Evacuacion', ambitosFoto$)" [ngClass]="setButtonStyle('Evacuacion')">Evacuación</button>
        <button type="button" (click)="setAmbitoFoto('Gas', ambitosFoto$)" [ngClass]="setButtonStyle('Gas')">Gas</button>
        <button type="button" (click)="setAmbitoFoto('Generacion', ambitosFoto$)" [ngClass]="setButtonStyle('Generacion')">Generación</button>
        <button type="button" (click)="setAmbitoFoto('Solar', ambitosFoto$)" [ngClass]="setButtonStyle('Solar')">Energía Solar</button>
        <button type="button" (click)="setAmbitoFoto('ProductosPetroliferos', ambitosFoto$)" [ngClass]="setButtonStyle('ProductosPetroliferos')">Prod. Petrolíferos</button>
    </div>
    <div class="form-group">
        <label class="font-weight-light" for="texto">Texto</label>
        <input type="text" formControlName="texto" class="form-control"/>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
    <button [disabled]="loading" class="btn btn-primary">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar</button>
</div>
</form>
</ng-template>

<ng-template #editarDocumento let-modal>
<form [formGroup]="editDocumentoForm" (ngSubmit)="onEditDocumento()">
<div class="modal-header">
    <h4 class="modal-title font-weight-light" id="modal-basic-title">Editar Documento <span style="cursor: pointer"><svg (click)="delDocumento(editDocumentoForm.controls.owner_name.value, editDocumentoForm.controls.key.value, editDocumentoForm.controls._id.value)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zm2-8h6v8H5v-8zm5-6H6L5 5H2v2h12V5h-3z"/></svg></span></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-row">
        <div class="form-group col-md-12">
            <label>Archivo</label>
            <input disabled type="text" formControlName="url" class="form-control"/>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-3 mb-3">
            <label for="fecha">Fecha</label>
            <div class="input-group">
                <input class="form-control" formControlName="fecha" value="{{ editDocumentoForm.controls.fecha.value | date:'dd-MM-yyy' }}" name="dp" ngbDatepicker #dp="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                </div>
            </div>
        </div>
        <div class="form-group col-md-3 mb-3">
            <label for="ambito">Ámbito</label>
            <select class="form-control" formControlName="ambito" value="{{ editDocumentoForm.controls.ambito.value }}">
                <option>Proyecto</option>
                <option>Legales</option>
                <option>Administracion</option>
            </select>
        </div>
        <div class="form-group col-md-3 mb-3">
            <label for="area">Área</label>
            <select class="form-control" formControlName="area" value="{{ editDocumentoForm.controls.area.value }}">
                <option>Licencias</option>
                <option>Documentación</option>
                <option>Autorizaciones</option>
            </select>
        </div>
        <div class="form-group col-md-3 mb-3">
            <label for="tipo">Tipo</label>
            <select class="form-control" formControlName="tipo" value="{{ editDocumentoForm.controls.tipo.value }}">
                <option>Planos</option>
                <option>Memorias</option>
                <option>Mediciones</option>
                <option>Presupuestos</option>
                <option>Pliegos</option>
                <option>Certificados</option>
                <option>Informes</option>
                <option>Acreditaciones</option>
            </select>
        </div>
    </div>
    <div class="form-group">
        <label for="asunto">Descripcion</label>
        <input type="text" formControlName="descripcion" value="{{ editDocumentoForm.controls.descripcion.value }}" class="form-control"/>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
    <button [disabled]="loading" class="btn btn-primary">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar</button>
</div>
</form>
</ng-template>

<ng-template #carouselPlanos let-modal>
    <ngb-carousel *ngIf="planosProyecto">
        <ng-template *ngFor="let plano of planosProyecto; index as i; first as isFirst" ngbSlide>
            <div class="picsum-img-wrapper" style="max-width: 100%; height: auto;">
                <img [src]="plano.url" class="img-fluid" alt="">
            </div>
        </ng-template>
</ngb-carousel>
</ng-template>

<ng-template #carouselFotos let-modal>
<ngb-carousel *ngIf="fotosProyecto">
    <ng-template *ngFor="let foto of fotosProyecto; index as i; first as isFirst" ngbSlide>
        <div class="picsum-img-wrapper" style="max-width: 100%; height: auto;">
            <img [src]="foto.url" class="img-fluid" alt="">
        </div>
        <div class="carousel-caption">
            <h3>{{foto.file.name}}</h3>
            <p>{{foto.texto}}</p>
        </div>
    </ng-template>
</ngb-carousel>
</ng-template>

</div>