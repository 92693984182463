import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Encargo, Actor, Foto } from '../../../_models';
import { EntidadesService, EncargosService, FotosService } from '../../../_services';
import { stitch } from '../../../_services/stitch.service';

@Component({
    selector: 'formFoto',
    templateUrl: 'formFoto.component.html',
    providers: [EntidadesService, EncargosService, FotosService],
    styleUrls: ['formFoto.component.css']
})

export class FormFotoComponent {
    
    administrador: Boolean;

    @Input() foto: Foto;
    @Input() user: Actor;
    @Input() encargo: Encargo;
    @Output() fotoSet = new EventEmitter<any>();

    fotoForm : FormGroup;
    submitted = false;
    encargosToSelect: any;
    filesToUpload: File[] = [];
    upLoading = false;
    label: SafeHtml;

    constructor(
        public stitch: stitch,
        public encargoService: EncargosService,
        public fotoService: FotosService,
        public modalService: NgbModal,
        public fb: FormBuilder,
        private sanitizer: DomSanitizer
        ){
            this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
            this.encargoService.getProyectosToSelect()
            .then(result => {
                this.encargosToSelect = result;
            });
            // Inicializar el contenido del label
            this.label = this.sanitizer.bypassSecurityTrustHtml('<i class="fas fa-search"></i> Selecciona Archivos');
        }

    open(content) {
        this.submitted = false;
        this.drawFotoForm();
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'}).result;
    }

    get f() { return this.fotoForm.controls; }

    onSubmit() {
        this.upLoading = true;
        this.submitted = true;
        if (this.fotoForm.invalid) {
            this.upLoading = false;
            return;
        }
        this.fotoForm.disable();
        this.fotoService.awsFotoUpload(this.filesToUpload, this.encargo, this.fotoForm.controls)
        .then(() => {
            this.upLoading = false;
            this.label = '<i class="fas fa-search"></i> Selecciona Archivos';
            this.fotoSet.emit();
            this.submitted = false;
            this.modalService.dismissAll();
        });
    }

    /**
     * Inicialitzar formulari de fotografia.
     * @assign Assigna valors buits a la variable @fotoForm
     */
    drawFotoForm(){
        this.fotoForm = this.fb.group({
            _id: [{value: '', disabled: true}],
            owner_name: [{value: '', disabled: true}],
            importFile: ['', Validators.required],
            texto: ['', Validators.required],
            ambitos: ['']
        });
    }

    onFileChange(event: any) {
        if (event.target && event.target.files){
            const files: File[] = Array.from(event.target.files);
            this.filesToUpload = files;
            const fileNames = this.filesToUpload.map(file => file.name).join(', ');
            this.label = this.sanitizer.bypassSecurityTrustHtml(fileNames || '<i class="fas fa-search"></i> Selecciona Archivos');
        } else {
            alert('El evento no contiene archivos.');
        }
    }
}