export class Comment {
    constructor(
        public _id: Object,
        public actor: {
            _id: Object,
            email: String,
            nom: String
        },
        public asunto: String,
        public fecha: Date,
        public proyecto: {
            _id: Object,
            descripcio: String,
            codi: String
        },
        public texto: String
    )
    {}
}