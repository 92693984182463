import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AwsServiceClient, AwsRequest } from 'mongodb-stitch-browser-services-aws';
import { BSON, RemoteMongoClient } from 'mongodb-stitch-browser-sdk';
import { stitch } from './stitch.service';
import { environment } from '../environments/environment';

interface CercaResult {
    fotos: any[];
    total: number;
}
  
interface Estado {
    page: number;
    pageSize: number;
    searchTerm: string;
    ambitos: string[];
}

function matchesFoto(text: String, foto: any){
    const term = text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return foto.texto.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || foto.codiEncargo[0].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || foto.descripcioEncargo[0].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term);
}

function ambitosIncludesAmbitosFoto(AmbitosFoto: string[], ambitos: string[]){
    var trobat: Boolean = false;
    for(let i=0; i<AmbitosFoto.length; i++){
        if(ambitos.some(arrVal => AmbitosFoto[i] === arrVal)){
            trobat = true;
        };
    }
    return trobat;
}

@Injectable()
export class FotosService {

    user: any;
    db: any;
    uploadProgress: number = 0; // Variable para rastrear el progreso de la subida

    constructor(
        public http: HttpClient,
        public stitch: stitch
    ){
        this.user = this.stitch.client.auth.user;
        this.db = this.stitch.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(environment.mongoDb);
    }

    _search$ = new Subject<void>();
    _fotos$ = new BehaviorSubject<any[]>([]);
    _total$ = new BehaviorSubject<number>(0);
    _loading$ = new BehaviorSubject<boolean>(true);
    _estado: Estado = {
        page: 1,
        pageSize: 25,
        searchTerm: '',
        ambitos: ['Establecimiento', 'Accesibilidad', 'Señalizacion', 'BajaTension', 'AltaTension', 'Climatizacion', 'Ventilacion', 'ContraIncendios', 'AguaFria', 'Evacuacion', 'Gas', 'Generacion', 'Solar', 'ProductosPetroliferos']
    };

    fotos: any[];

    get totalFotos() { return this.fotos.length; }
    get fotos$() { return this._fotos$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._estado.page; }
    get pageSize() { return this._estado.pageSize; }
    get searchTerm() { return this._estado.searchTerm; }
    get ambitos() { return this._estado.ambitos; }
    
    set page(page: number) { this._set({page}); }
    set pageSize(pageSize: number) { this._set({pageSize}); }
    set searchTerm(searchTerm: string) { this._set({searchTerm}); }
    set ambitos(ambitos: string[]) { this._set({ambitos}); }
    
    _set(patch: Partial<Estado>) {
        Object.assign(this._estado, patch);
        this._search$.next();
    }

    _search(): Observable<CercaResult>{
        const {pageSize, page, searchTerm, ambitos} = this._estado;
        //1. filter
        let fotos = this.fotos.filter(foto => matchesFoto(searchTerm, foto) && ambitosIncludesAmbitosFoto(foto.ambitos, ambitos));
         const total = fotos.length;
        //2. paginate
        fotos = fotos.slice((page-1)*pageSize, (page-1)*pageSize + pageSize);
        return of({fotos, total});
    }

    /**
    * Obtiene las entidades existentes.
    * @return {Array} - Conjunt d'entitats enregistrades.
    */
    getFotos (): Observable<any[]>{
        const fotosUrl = 'https://webhooks.mongodb-realm.com/api/client/v2.0/app/' + environment.appId + '/service/' + environment.webhookService + '/incoming_webhook/Fotos';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: new HttpParams()
            .set('arg1', environment.mongoDb)
            .set('arg2', this.user.profile.email)
        };
        return this.http.get<any[]>(fotosUrl, httpOptions);
    }

    /**
    * Obté les fotografies relacionats amb una determinat encàrrec.
    * @param {String} id - L'identificador de l'encàrrec.
    * @return {Array} - Conjunt de fotografies associades a una encàrrec.
    */
    async getFotosEncargoById(id): Promise<any[]> {
        const _id = new BSON.ObjectId(id);
        return await this.db.collection('Fotos').find(
            { _idEncargo: _id },
            { sort: { ts: -1 }}
        ).toArray()
    }

    async awsFotoUpload(files: File[], encargo, foto) {
        // Comprueba que haya archivos para procesar
        if (!files || files.length === 0) {
            alert('No hay archivos para procesar!');
            return;
        }

        // Process the image file
        // Itera sobre cada archivo
        for (const file of files) {
            try {
                const fileBinary = await this.convertImageToBSONBinaryObject(file);
                // Upload the image binary to S3
                await this.uploadToS3(fileBinary, file, encargo, foto)
            } catch (error){
                alert('Error procesando archivo: ' + file.name + error);
            }
        }
    }

    private async uploadToS3(fileBinary: any, file: File, encargo: any, foto: any){
        const aws = this.stitch.client.getServiceClient(AwsServiceClient.factory, "AWS");
        const key = 'img/' + encargo._id.toString() + '/' + file.name;
        const bucket = environment.awsBucket;
        const url = `https://${bucket}.s3.amazonaws.com/${encodeURIComponent(key)}`;
        const request = new AwsRequest.Builder()
        .withService("s3")
        .withAction("PutObject")
        .withRegion("us-east-2")
        .withArgs({
            ACL: "public-read",
            Bucket: bucket,
            ContentType: file.type,
            Key: key,
            Body: fileBinary
        });

        try {
            await aws.execute(request.build())
            await this.afegirDocumentMongoDB(file, url, encargo, foto);
            //console.log(`File uploaded successfully: ${file.name}`);
        } catch (error) {
            //console.error('Error uploading file to S3:', file.name, error);
        }
        /*const xhr = new XMLHttpRequest();
        xhr.open('PUT', url, true);
        xhr.setRequestHeader('Content-Type', file.type);
        xhr.setRequestHeader('x-amz-acl', 'public-read');

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                this.uploadProgress = Math.round((event.loaded / event.total) * 100);
                console.log(`Upload progress: ${this.uploadProgress}%`);
            }
        };

        xhr.onload = async () => {
        if (xhr.status === 200) {
            await this.afegirDocumentMongoDB(file, url, encargo, foto);
            console.log(`File uploaded successfully: ${file.name}`);
            } else {
                console.error('Error uploading file to S3:', file.name, xhr.statusText);
            }
        };
        xhr.onerror = () => {   
        console.error('Error uploading file to S3:', file.name, xhr.statusText);
        };
        xhr.send(fileBinary.$binary.base64);*/
    }

    private async afegirDocumentMongoDB(file: File, url: string, encargo: any, foto: any){

        const fotos = this.db.collection('Fotos');
        try {
            await fotos.insertOne({
                owner_id: this.stitch.client.auth.user.id,
                owner_name: this.stitch.client.auth.user.profile.email,
                texto: foto.texto.value,
                url,
                file: {
                    name: file.name,
                    type: file.type
                },
                s3: {
                    bucket: environment.awsBucket,
                    key: 'img/' + encargo._id.toString() + '/' + file.name
                },
                ambitos: [foto.ambitos.value],
                _idEncargo: encargo._id,
                emailsAutorizados: encargo.emailsAutorizados,
                ts: new Date()
            });
            //console.log(`Metadata saved successfully for file: ${file.name}`);
            } catch (error) {
            alert('Error guardando archivo en base de datos: ' + file.name + error);
            }
        }

    private convertImageToBSONBinaryObject(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = (event: any) => {
                try {
                    resolve({
                        $binary: {
                            base64: event.target.result.split(",")[1],
                            subType: "00"
                        }
                    });
                } catch (error){
                    reject('Error convirtiendo imagen')
                }
            };
            fileReader.onerror = (error) => {
                reject('fileReader error: ' + error);
            };      
            fileReader.readAsDataURL(file);
        });
    }

    /**
   * Actualiza o afegeix una foto a l'encàrrec.
   * @param {String} id - L'identificador de la foto.
   * @param {String} owner_name - L'identificador del propietari de la foto.
   * @param {String} texto - El text descriptiu de la foto.
   * @param {Object} interaccion - L'objecte interacció.
   */
    async setFoto(id, owner_name, texto, ambitos){
        if(owner_name != this.stitch.client.auth.user.profile.email){
            alert('Las fotografías del Encargo no son modificables.');
            return;
        };
        return await this.db.collection('Fotos').updateOne(
            { _id: id },
            { $set: {
                texto: texto,
                ambitos: ambitos
            }},
            { upsert: true }
        )
        .catch( e => {
            alert('Fotografía no se ha podido actualizar debido a: ' + e);
        });
    }

    /**
   * Elimina una foto a l'encàrrec.
   * @param {String} id - L'identificador de la foto.
   * @param {String} owner_name - L'identificador del propietari de la foto.
   * @param {String} key - La clau de l'arxiu al bucket d'AWS.
   */
    async delFoto(owner_name, key, id) {
        if(owner_name != this.stitch.client.auth.user.profile.email){
          alert('Las fotografías del Encargo no son modificables.');
          return;
        };
        const aws = this.stitch.client.getServiceClient(AwsServiceClient.factory, "AWS");
        const bucket = environment.awsBucket;
        const request = new AwsRequest.Builder()
          .withService("s3")
          .withAction("DeleteObject")
          .withRegion("us-east-2")
          .withArgs({
            Bucket: bucket,
            Key: key,
          });
          if(confirm('Esta acción NO es recuperable. \nDesea eliminar la fotografía?')) {
            try {
                await aws.execute(request.build())
                .then(async () => {
                    await this.db.collection('Fotos').deleteOne({_id: id})
                });
            } catch (e) {
              console.log(e);
            }
        }
    }

}

@Injectable()
export class FotosTableService extends FotosService {

    constructor(http: HttpClient, stitch: stitch){
        super(http, stitch)
        this.getFotos().subscribe(result => {
            this.fotos = result;
            this._search$.pipe(
                tap(() => this._loading$.next(true)),
                switchMap(() => this._search()),
                tap(() => this._loading$.next(false))
            ).subscribe(result => {
                this._fotos$.next(result.fotos);
                this._total$.next(result.total);
            });
            this._search$.next();
        });
    }

}