<span style="cursor: pointer" ><svg (click)="open(content)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M14 10H2v2h12v-2zm0-4H2v2h12V6zm4 8v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zM2 16h8v-2H2v2z"/></svg></span>

<ng-template #content let-modal>
    <form [formGroup]="interaccionForm" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <h4 class="modal-title font-weight-light" id="modal-basic-title">Interacción <span style="cursor: pointer"><svg (click)="delInteraccion(f._id.value)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zm2-8h6v8H5v-8zm5-6H6L5 5H2v2h12V5h-3z"/></svg></span></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-row">
            <div class="form-group col-md-3">
                <label for="fecha">Fecha</label>
                <div class="input-group">
                    <input class="form-control" formControlName="fecha" placeholder="yyyy-mm-dd" value="{{ f.fecha.value | date:'yyy-MM-dd' }}" [ngClass]="{ 'is-invalid': submitted && f.fecha.errors }" name="dp" ngbDatepicker #dp="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                    </div>
                    <div *ngIf="submitted && f.fecha.errors" class="invalid-feedback"><div *ngIf="f.fecha.errors.required">Debe introducir una fecha</div></div>
                </div>
            </div>
            <div class="form-group col-md-3">
                <label for="estado">Estado</label>
                <select class="form-control" formControlName="estado" [ngClass]="{ 'is-invalid': submitted && f.estado.errors }">
                    <option>En espera</option>
                    <option>A resolver</option>
                    <option>Resuelto</option>
                </select>
                <div *ngIf="submitted && f.estado.errors" class="invalid-feedback"><div *ngIf="f.estado.errors.required">Debe indicar el estado</div></div>
            </div>
        </div>
            <div class="form-group">
                <label for="asunto">Asunto</label>
                <input type="text" formControlName="asunto" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.asunto.errors }"/>
                <div *ngIf="submitted && f.asunto.errors" class="invalid-feedback"><div *ngIf="f.asunto.errors.required">Debe introducir al menos un asunto</div></div>
            </div>
            <div class="form-group">
                <label for="texto">Texto</label>
                <textarea formControlName="texto" class="form-control" rows="6"></textarea>
            </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
        <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar</button>
    </div>
    </form>
    </ng-template>