import { Injectable, TemplateRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Ticket } from '../_models';
import { stitch } from './stitch.service';
import { BSON, RemoteMongoClient } from 'mongodb-stitch-browser-sdk';
import { environment } from '../environments/environment';
import { Time } from '@angular/common';

@Injectable()
export class TicketsService {

    user: any;
    db: any;

    constructor(
        public http: HttpClient,
        public stitch: stitch
    ){
        this.user = this.stitch.client.auth.user;
        this.db = this.stitch.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(environment.mongoDb);
    }

    /**
    * Obtiene els comments existents.
    * @return {Array} - Conjunt de comments enregistrats.
    */
    getTickets (): Observable<Ticket[]>{
        const ticketsUrl = 'https://webhooks.mongodb-realm.com/api/client/v2.0/app/' + environment.appId + '/service/' + environment.webhookService + '/incoming_webhook/Tickets';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: new HttpParams()
            .set('arg1', environment.mongoDb)
            .set('arg2', this.user.profile.email)
        };
        return this.http.get<Ticket[]>(ticketsUrl, httpOptions)
    }

    /**
    * Obté els comentaris de l'encàrrec.
    * @param {Object} id - L'objecte id de l'encàrrec.
    * @return {Array} - Conjunt de comentaris d'un encàrrec.
    */
    async getTicketsEncargoById(id): Promise<any[]> {
        return await this.db.collection('Tickets').aggregate([
            { $match: {
                'encargo._idEncargo': id
            }},
            { $project: {
                "admin" : {
                "_id": {"$toString": "$admin._id"},
                "email": "$admin.email"},
                encargo: 1,
                fecha: 1,
                estat: 1,
                temps: 1,
                tarea: 1,
                texto: 1
            }},
            { $sort: { fecha: -1 }}
        ]).toArray()
    }

    /**
    * Obté els tickets d'un determinat usuari.
    * @param {String} email - El email de l'usuari.
    * @return {Array} - Conjunt de comentaris d'un usuari.
    */
    async getTicketsAdminByEmail(email): Promise<Ticket[]>{
        return await this.db.collection('Tickets').aggregate([
            { $match: {
                "admin.email": email
            }},
            { $project: {
                "admin" : {
                    "_id": {"$toString": "$admin._id"},
                    "email": "$admin.email"},
                    encargo: 1,
                    fecha: 1,
                    estat: 1,
                    temps: 1,
                    tarea: 1
            }},
            { $sort: { fecha: -1 }}
        ]).toArray()
    }

    /**
    * Obté els tickets OBERTS d'un determinat usuari.
    * @param {String} email - El email de l'usuari.
    * @return {Array} - Conjunt de comentaris d'un usuari.
    */
   async getTicketsObertsByEmail(email): Promise<Ticket>{
    return await this.db.collection('Tickets').findOne(
        { 
            "admin.email": email,
            "estat": 'Obert'
        },
        { projection: {
            "admin" : {
                "_id": {"$toString": "$admin._id"},
                "email": "$admin.email"
            },
            encargo: 1,
            fecha: 1,
            estat: 1,
            temps: 1,
            tarea: 1
        }},
        { $sort: { fecha: -1 }}
        )
    }

    /**
    * Modifica el registre d'un comentari o afegeix un de nou.
    * @param {Object} ticket - L'objecte Ticket.
    * @param {String} user - L'usuari actual.
    * @param {String} owner - L'usuari que intenta eliminar el comentari.
    */
   async setTicketById(ticket, user, owner): Promise<void> {
    if(user != owner){
        alert('No está permitido modificar tickets ajenos.');
        return;
    };
    const _idEncargo = new BSON.ObjectId(ticket.encargo._idEncargo);
    await this.db.collection('Tickets').updateOne(
        { _id: ticket._id },
        { $set: {
            admin: {
                _id: ticket.admin._id,
                email: ticket.admin.email
            },
            fecha: ticket.fecha,
            encargo: {
                _idEncargo: _idEncargo,
                codi: ticket.encargo.codi
            },
            estat: ticket.estat,
            temps: ticket.temps,
            tarea: ticket.tarea,
            texto: ticket.texto
        }},
        { upsert: true }
    )
    .catch (e => {
        alert('Ticket no se ha podido modificar debido a: ' + e);
    });
    }

    /**
    * Afegeix un nou ticket.
    * @param {Object} ticket - L'objecte ticket.
    */
    async addTicketById(ticket): Promise<void> {
        return await this.db.collection('Tickets').insertOne(
            {
                admin: {
                    _id: ticket[1],
                    email: ticket[2]
                },
                fecha: ticket[3],
                encargo: {
                    _idEncargo: ticket[4],
                    codi: ticket[5] 
                },
                estat: 'Obert',
                temps: null,
                tarea: ticket[6]
            } 
        )
        .catch (e => {
            alert('Ticket no se ha podido registrar debido a: ' + e);
        });
    }

    /**
     * Elimina un ticket de l'usuari.
     * @param {ObjectId} id - El id del ticket i l'usuari actual.
     * @param {String} user - L'usuari actual.
     * @param {String} owner - L'usuari que intenta eliminar el comentari.
     */
    async borraTicket(id, user, owner): Promise<void> {
        if(user != owner){
            alert('No está permitido eliminar tickets ajenos.');
            return;
        };
        if(confirm('Esta acción NO es recuperable. \nDesea eliminar el ticket?')) {
            await this.db.collection('Tickets').deleteOne(
                { _id: id }
            )
            .catch (e => {
                alert('Ticket no se ha podido eliminar debido a: ' + e);
            });
        }
    }

    elapsedTime: number = 0;

    pausaTicket(ticket){
        clearInterval(ticket.interval);
    }

    /**
     * Elimina un ticket de l'usuari.
     * @param {ObjectId} id - El id del ticket i l'usuari actual.
     * @param {String} user - L'usuari actual.
     * @param {String} owner - L'usuari que intenta eliminar el comentari.
     */
    async cierraTicket(ticket, user, owner): Promise<void> {
        if(user != owner){
            alert('No está permitido modificar tickets ajenos.');
            return;
        };
        if(confirm('Si cierra el ticket no podrá editar parámetros ni eliminarlo.\nEsta acción NO es recuperable.\nDesea cerrar el ticket?')) {
            let moment = Date.now();
            let total = moment - new Date(ticket.fecha).getTime();
            return await this.db.collection('Tickets').updateOne(
                { _id: ticket._id },
                { $set : {
                    temps: total,
                    estat: 'Tancat'
                    }
                });
        };
    }
    
    tickets: any[] = [];
    time: number = 0;
    interval;

    obriTicket(textOrTpl: string, time: string | TemplateRef<any>, options: any = {}) {
        this.tickets.push({ textOrTpl, time, ...options });
    }

    tancaTicketToast(ticket) {
        this.tickets = this.tickets.filter(t => t !== ticket);
    }

    timeToString(time) {
        let diffInHrs = time / 3600000;
        let hh = Math.floor(diffInHrs);
      
        let diffInMin = (diffInHrs - hh) * 60;
        let mm = Math.floor(diffInMin);
      
        let diffInSec = (diffInMin - mm) * 60;
        let ss = Math.floor(diffInSec);
      
        let diffInMs = (diffInSec - ss) * 100;
        let ms = Math.floor(diffInMs);

        let formattedHH = hh.toString().padStart(2, "0");      
        let formattedMM = mm.toString().padStart(2, "0");
        let formattedSS = ss.toString().padStart(2, "0");
        let formattedMS = ms.toString().padStart(2, "0");
      
        return `${formattedHH}:${formattedMM}:${formattedSS}`;
      }
}