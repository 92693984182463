import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';

import { Encargo, Entidad } from '../../../_models';
import { stitch, EntidadesService, EncargosService } from '../../../_services';


@Component({
    selector: 'formParticipacion',
    templateUrl: 'formParticipacion.component.html',
    providers: [EntidadesService, EncargosService,],
    styleUrls: ['formParticipacion.component.css']
})

export class FormParticipacionComponent {
    
    @Input() rol: string;
    @Input() encargo: Encargo;
    @Input() entidad: Entidad;
    @Output() participacionSet = new EventEmitter<any>();

    participacionForm : FormGroup;
    submitted = false;
    loading = false;
    encargosToSelect: any[];
    entidadesToSelect: any[];
    idParticipante: Entidad;
    administrador: Boolean;          


    constructor(
        public stitch: stitch,
        public entidadService: EntidadesService,
        public encargoService: EncargosService,
        public modalService: NgbModal,
        public fb: FormBuilder
        ){
            this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
            encargoService.getProyectosToSelect()
            .then(result => {
                this.encargosToSelect = result;
            });
            this.entidadService.getEntidadesToSelect()
            .then(result => {
                this.entidadesToSelect = result;
            });
        }

    open(content) {
        this.submitted = false;
        if (this.encargo._id === ''){ //Caso abro desde Entidad
            if (this.rol !== ''){ //Caso participación nueva
            this.drawParticipacioFormFromEntidad();
            this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result;
            } else { //Caso participación existente
                this.drawParticipacioFormExistent();
                this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result;
            }
        } else { //Caso abro desde Encargo
            if (this.rol !== ''){ //Caso participación existente
                this.drawParticipacioFormExistent();
                this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result;
            } else { //Caso participación nueva
                this.drawParticipacioFormFromEncargo(); 
                this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result;
            }
        }        
    }

    get f() { return this.participacionForm.controls; }

    onSubmit() {
        this.loading = true;
        this.submitted = true;
        if (this.participacionForm.invalid) {
            return;
        }
        if(this.f.encargo.disabled){
            //desde proyecto
            this.entidadService.getEntidadById(this.f.entidad.value)
            .then(result => {
                this.idParticipante = result;
                this.encargoService.addParticipacionEntidad(this.encargo._id.toString(), this.f.entidad.value, this.f.rol.value, this.idParticipante.Rao)
                .then(() => {
                    this.loading = false;
                    this.participacionSet.emit();
                    this.submitted = false;
                    this.modalService.dismissAll();
                });
            });        
        } else {
            //desde entidad
            this.encargoService.addParticipacionEntidad(this.f.encargo.value, this.entidad._id.toString(), this.f.rol.value, this.entidad.Rao)
            .then(() => {
                this.loading = false;
                this.participacionSet.emit();
                this.submitted = false;
                this.modalService.dismissAll();
            });
        }
    }

    /**
     * Elimina la participacio de l'entitat d'un projecte.
     * @param {ObjectId} id - El id de la interacció.
     * 
    */
    delParticipacion(idEncargo, idParticipante){
        this.loading = true;
        this.participacionForm.disable();
        this.encargoService.delParticipacionEntidad(idEncargo, idParticipante)
        .then(() => {
            this.loading = false;
            this.participacionSet.emit();
            this.submitted = false;
            this.modalService.dismissAll();
        });
    }

    /**
   * Inicialitzar formulari participació.
   * @assign Assigna valors buits a la variable @participacionForm
   */
    drawParticipacioFormFromEntidad(){
        this.participacionForm = this.fb.group({
            entidad: [{value: this.entidad._id.toString(), disabled: true}],
            encargo: ['', Validators.required],
            rol: ['', Validators.required]
        });
    }

   /**
   * Inicialitzar formulari participació.
   * @assign Assigna valors buits a la variable @participacionForm
   */
    drawParticipacioFormFromEncargo(){
        this.participacionForm = this.fb.group({
            entidad: ['', Validators.required],
            encargo: [{value: this.encargo._id, disabled: true}],
            rol: ['', Validators.required]
        });
    }

    /**
   * Inicialitzar formulari participació.
   * @assign Assigna valors buits a la variable @participacionForm
   */
    drawParticipacioFormExistent(){
        this.participacionForm = this.fb.group({
            entidad: [{value: this.entidad._id.toString(), disabled: true}],
            encargo: [{value: this.encargo._id, disabled: true}],
            rol: [this.rol, Validators.required]
        });
    }
}