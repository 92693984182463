import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Encargo, Entidad } from '../../../_models';
import { EncargosService, stitch, EntidadesService } from '../../../_services';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'formEncargo',
    templateUrl: 'formEncargo.component.html',
    providers: [EncargosService],
    styleUrls: ['formEncargo.component.css']
})

export class FormEncargoComponent {
    
    @Input() encargo: Encargo;
    @Input() pare: Entidad;
    @Input() titular: Entidad;
    @Output() encargoSet = new EventEmitter<any[]>();

    encargosExistentes: Encargo[];
    entidadesToSelect: any[];
    encargoForm : FormGroup;
    totalPresupuestadoValor: any;
    submitted = false;
    loading = false;
    fechaEncargo: any;
    _id: any;
    administrador: Boolean;
    superUser: Boolean;
    numberChars = new RegExp(/\D/g, '');
    env: any;
    totalPresupuestado: String;

    constructor(
        public stitch: stitch,
        public encargoService: EncargosService,
        public entidadService: EntidadesService,
        public modalService: NgbModal,
        public router: Router,
        public fb: FormBuilder
        ){
            this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
            this.superUser = environment.superUsers.includes(this.stitch.client.auth.user.profile.email);
            this.entidadService.getEntidadesToSelect()
            .then(result => {
                this.entidadesToSelect = result;
            });
            this.env = environment;
        }

    open(content) {
        this.loading = false;
        this.submitted = false;
        if (this.encargo._id === ''){
            this.encargoForm = this.fb.group({
                _id: [{value: this.encargo._id, disabled: true}],
                descripcio: ['', Validators.required],
                data: ['', Validators.required],
                direccio: [''],
                cp: [''],
                localitat: [''],
                municipi: [''],
                supcons: [''],
                refcat: [''],
                codi: ['', Validators.required ],
                estat: ['', Validators.required ],
                Lng: [{value: this.encargo.geometry.coordinates[0], disabled: true}],
                Lat: [{value: this.encargo.geometry.coordinates[1], disabled: true}],
                titular: [this.titular._id],
                totalPresupuestado: ['']
            });
            if(!this.superUser) {
                this.encargoForm.disable();
            }
        } else {
            this.drawEncargoForm();
        }
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'}).result;
    }

    get f() { return this.encargoForm.controls; }

    onSubmit() {
        this.loading = true;
        this.submitted = true;
        if (this.encargoForm.invalid) {
            this.loading = false;
            return;
        }
        this.encargoForm.disable();
        if (this.f._id.value === ''){
            this._id = new BSON.ObjectId();
        } else {
            this._id = this.f._id.value;
        };
        if ( this.f.data.value.length === 24 ) {
            this.fechaEncargo = this.f.data.value;
        } else {
            const fecha1 = new Date(this.f.data.value.year, this.f.data.value.month - 1, this.f.data.value.day);
            this.fechaEncargo = fecha1.toISOString();
        };
        if (this.env.features.facturacion){
        //Comprobar si el valor se ha modificado
        //Si es así, volver a darle formato.
        const regex = /^\d+\.\d+$/;
        if (regex.test(this.f.totalPresupuestado.value)) {
            this.totalPresupuestadoValor = this.f.totalPresupuestado.value.toString();
            } else {this.totalPresupuestadoValor = this.convertirEuroParaAlmacenar(this.f.totalPresupuestado.value.toString())};
        }
        



        this.entidadService.getEntidadById(this.f.titular.value.toString())
        .then(result => {
            this.titular = result;
            if( this.titular.email === "" ){
                alert('El titular no dispone de dirección email para autorizar.');
                return;
            } else {
                if (this.encargo.emailsAutorizados.length == 0){
                    this.encargo.emailsAutorizados = environment.administradores.concat([this.pare.email, this.titular.email]);
                }
                const encargo = [
                    this._id,
                    this.f.Lng.value,
                    this.f.Lat.value,
                    this.f.direccio.value,
                    this.f.cp.value,
                    this.fechaEncargo,
                    this.f.municipi.value,
                    this.f.refcat.value,
                    this.f.descripcio.value,
                    this.f.localitat.value,
                    this.f.supcons.value,
                    this.f.codi.value,
                    this.f.estat.value,
                    this.encargo.participantes,
                    this.pare._id,
                    this.f.titular.value,
                    this.totalPresupuestadoValor,
                    this.encargo.emailsAutorizados
                ];
                this.encargoService.setEncargoById(encargo)
                .then(() => { 
                    this.loading = false;
                    this.encargoSet.emit(encargo);
                    this.submitted = false;
                    this.modalService.dismissAll();
                });
            };
        });
    }

    procesaLat(Lat){
        this.f.Lat.setValue(Lat);
    }
    
    procesaLng(Lng){
        this.f.Lng.setValue(Lng);
    }
    
    delEncargo(id){
        this.loading = true;
        this.encargoForm.disable();
        this.encargoService.delEncargoById(id, environment.mongoDb)
        .then(() => {
            this.loading = false;
            this.encargoSet.emit();
            this.submitted = false;
            this.modalService.dismissAll();
            this.router.navigate(['/encargos']);
        });
    }

    drawEncargoForm(){
        this.encargoForm =  this.fb.group({
            _id: [{value: this.encargo._id, disabled: true}],
            descripcio: [this.encargo.properties.descripcio, Validators.required],
            data: [{value: this.encargo.properties.data, disabled: true}, Validators.required],
            direccio: [this.encargo.properties.direccio],
            Lng: [{value: this.encargo.geometry.coordinates[0], disabled: true}],
            Lat: [{value: this.encargo.geometry.coordinates[1], disabled: true}],
            cp: [this.encargo.properties.cp],
            localitat: [this.encargo.properties.localitat],
            municipi: [this.encargo.properties.municipi],
            supcons: [this.encargo.properties.supcons],
            refcat: [this.encargo.properties.refcat],
            codi: [this.encargo.properties.codi, Validators.required ],
            estat: [this.encargo.properties.estat, Validators.required ],
            titular: [this.titular._id],
            totalPresupuestado: [this.encargo.properties.totalPresupuestado]
        });
        if(!this.superUser){
            this.encargoForm.disable();
        };
    }


    convertirEuroParaAlmacenar(value: string): string {
        // Implementa la lógica necesaria para convertir el valor formateado a una cadena
        // adecuada para el almacenamiento. Por ejemplo, podrías eliminar símbolos de moneda,
        // separadores de miles, etc., dependiendo de cómo se formateó el valor.
        // Este es un ejemplo simple que asume que deseas almacenar el número como un string plano.
        return value.replace(/[^\d,]/g, '').replace(',', '.');
      }
}