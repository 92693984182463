
export class Ticket {
    constructor(
        public _id: Object,
        public fecha: Date,
        public estat: string,
        public admin: {
            _id: Object,
            email: String
        },
        public encargo: {
            _idEncargo: Object,
            codi: String
        },
        public temps: number,
        public tarea: string,
        public texto: string
    )
    {}
}