<span *ngIf="administrador" style="cursor: pointer"><svg (click)="open(content)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="32px" height="32px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM5 18c.2-.63 2.57-1.68 4.96-1.94l2.04-2c-.39-.04-.68-.06-1-.06-2.67 0-8 1.34-8 4v2h9l-2-2H5zm15.6-5.5l-5.13 5.17-2.07-2.08L12 17l3.47 3.5L22 13.91z"/></svg></span>
<ng-template #content let-modal>
    <form [formGroup]="autorizadoForm" disable (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h4 class="modal-title font-weight-light" id="modal-basic-title">Autorizado <span style="cursor: pointer"><svg (click)="delAutorizado(encargo._id, idPareEncargo, idTitularEncargo)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zm2-8h6v8H5v-8zm5-6H6L5 5H2v2h12V5h-3z"/></svg></span></h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="encargo">Selecciona un Encargo:</label>
                    <select formControlName="encargo" class="form-control">
                        <option value="" disabled selected>...</option>
                        <option *ngFor="let encargo of encargosToSelect" value="{{encargo._id}}">{{encargo.properties.codi}} - {{encargo.properties.descripcio}}</option>
                    </select>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="actor">Selecciona un Actor:</label>
                    <select formControlName="actor" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.actor.errors }">
                        <option value="" disabled selected>...</option>
                        <option *ngFor="let actor of actoresToSelect" value="{{actor.email}}">{{actor.Nom}} {{actor.Cognom1}} {{actor.Cognom2}} - {{actor.email}}</option>
                    </select>
                    <div *ngIf="submitted && f.actor.errors" class="invalid-feedback"><div *ngIf="f.actor.errors.required">Se requiere un Actor</div></div>
                </div>
            </div>  
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
            <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar</button>
        </div>
    </form>
</ng-template>