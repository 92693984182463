<div class="container-fluid overflow-auto">
<h1 class="font-weight-light">Gestión de Documentos</h1>
<form>
<div class="form-group form-inline">
    <input class="font-weight-lighter" id="cercaTerm" type="text" name="searchTerm" placeholder="Búsqueda por término..." [(ngModel)]="documentosTableService.searchTerm"/>
</div>
</form>
<button type="button" (click)="setAmbito('Proyecto')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Proyecto</button>
<button type="button" (click)="setAmbito('Legales')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Legales</button>
<button type="button" (click)="setAmbito('Administracion')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Administración</button>
<!--<button type="button" (click)="setTipo('Planos')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Planos</button>-->
<div class="d-flex justify-content-between">
    <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="documentosTableService.pageSize">
        <option [ngValue]="10">Mostrar 10 registros</option>
        <option [ngValue]="25">Mostrar 25 registros</option>
        <option [ngValue]="50">Mostrar 50 registros</option>
    </select>
    <ngb-pagination class="d-flex justify-content-end" [(page)]="documentosTableService.page" [(pageSize)]="documentosTableService.pageSize" [collectionSize]="total$ | async" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>  
</div>
<div *ngIf="documentosTableService.loading$ | async" class="d-flex justify-content-center" style="height: 100vh;">
    <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
    </div><br>
    <p class="lead"> Cargando datos...</p>
</div>
<table *ngIf="documentos$" class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'> 
<thead>
    <tr>
        <th class="sortable dataCol" sortable="fecha" (sort)="onSort($event)">Fecha</th>
        <th class="openIcon">File</th>
        <th class="sortable" sortable="descripcion" (sort)="onSort($event)">Descripción</th>
        <th class="sortable codiCol" sortable="codiMatriz" (sort)="onSort($event)">Matriz</th>
        <th class="sortable entidadCol" sortable="descripcioMatriz" (sort)="onSort($event)">Referencia</th>
    </tr>
</thead>
<tbody>
    <tr *ngFor="let documento of documentos$ | async" (click)="openMatriz(documento)">
        <td><ngb-highlight [result]="documento.fecha | date:'dd-MM-yyy'" [term]="documentosTableService.searchTerm"></ngb-highlight></td>
        <td><span style="cursor: pointer"><a href="{{documento.url}}" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/><path d="M0 0h24v24H0z" fill="none"/></svg></a></span></td>
        <td><ngb-highlight [result]="documento.descripcion" [term]="documentosTableService.searchTerm"></ngb-highlight></td>
        <td><ngb-highlight [result]="documento.codiMatriz" [term]="documentosTableService.searchTerm"></ngb-highlight></td>
        <td><ngb-highlight [result]="documento.descripcioMatriz" [term]="documentosTableService.searchTerm"></ngb-highlight></td>
    </tr>
</tbody>
</table>
<!--Caso 1: No hay búsqueda-->
<h6 *ngIf="!documentosTableService.searchTerm" class="font-weight-light">Mostrando registros del {{(documentosTableService.page - 1 ) * documentosTableService.pageSize + 1}} al {{((documentosTableService.page - 1 ) * documentosTableService.pageSize) + documentosTableService.pageSize}} de un total de {{ documentosTableService.total$ | async }}</h6>
<!--Caso 2: Hay búsqueda y más de una página-->
<h6 *ngIf="documentosTableService.searchTerm && ((documentosTableService.total$ | async) >= documentosTableService.pageSize) && (documentosTableService.page !== (((documentosTableService.total$ | async) % documentosTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(documentosTableService.page - 1 ) * documentosTableService.pageSize + 1}} al {{((documentosTableService.page - 1 ) * documentosTableService.pageSize) + ((documentosTableService.total$ | async) % documentosTableService.pageSize) + (documentosTableService.pageSize - ((documentosTableService.total$ | async) % documentosTableService.pageSize))}} de un total de {{ documentosTableService.total$ | async }} (filtrado de un total de {{documentosTableService.totalDocumentos}})</h6>
<!--Caso 3: Hay búsqueda, más de una página y es la última page-->
<h6 *ngIf="documentosTableService.searchTerm && ((documentosTableService.total$ | async) >= documentosTableService.pageSize) && (documentosTableService.page == (((documentosTableService.total$ | async) % documentosTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(documentosTableService.page - 1 ) * documentosTableService.pageSize + 1}} al {{((documentosTableService.page - 1 ) * documentosTableService.pageSize) + ((documentosTableService.total$ | async) % documentosTableService.pageSize)}} de un total de {{ documentosTableService.total$ | async }} (filtrado de un total de {{documentosTableService.totalDocumentos}})</h6>
<!--Caso 3: Hay búsqueda y solo una página-->
<h6 *ngIf="documentosTableService.searchTerm && documentosTableService.pageSize >= (documentosTableService.total$ | async)" class="font-weight-light">Mostrando registros del {{(documentosTableService.page - 1 ) * documentosTableService.pageSize + 1}} al {{((documentosTableService.page - 1 ) * documentosTableService.pageSize) + ((documentosTableService.total$ | async) % documentosTableService.pageSize)}} de un total de {{ documentosTableService.total$ | async }} (filtrado de un total de {{documentosTableService.totalDocumentos}})</h6>
</div>  