<span style="cursor: pointer"><svg (click)="open(content)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/></svg></span>

<ng-template #content let-modal>
    <form [formGroup]="facturaForm" (ngSubmit)="onSubmit()">
        <div class="modal-header">
                <h4 class="modal-title font-weight-light" id="modal-basic-title">Factura <span style="cursor: pointer"><svg (click)="delFactura(f._id.value)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zm2-8h6v8H5v-8zm5-6H6L5 5H2v2h12V5h-3z"/></svg></span></h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
                </button>
        </div>
        <div class="modal-body">
            <div class="form-row">
                <div class="form-group col-md-4 mb-3">
                    <label for="codifactura">Codi Factura</label>
                    <input type="text" formControlName="codifactura" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.codifactura.errors }"/>
                    <div *ngIf="submitted && f.codifactura.errors" class="invalid-feedback"><div *ngIf="f.codifactura.errors.required">Se requiere un código</div></div>
                </div>
                <div class="form-group col-md-4 mb-3">
                    <label for="baseimponible">Base Imponible</label>
                    <div class="input-group mb-2">
                        <input euro type="text" formControlName="baseimponible" class="form-control text-right" [ngClass]="{ 'is-invalid': submitted && f.baseimponible.errors }"/>
                        <div class="input-group-append"><span class="input-group-text">€</span></div>
                        <div *ngIf="submitted && f.baseimponible.errors" class="invalid-feedback"><div *ngIf="f.baseimponible.errors.required">Debe introducir una base imponible</div></div>
                    </div>
                </div>
                <div class="input-group col-md-4 mb-3">
                    <label for="gastossuplidos">Gastos Suplidos</label>
                    <div class="input-group mb-2">
                        <input euro type="text" formControlName="gastossuplidos" class="form-control text-right"/>
                        <div class="input-group-append"><span class="input-group-text">€</span></div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="concepto">Concepto</label>
                    <textarea formControlName="concepto" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.concepto.errors }" rows="6"></textarea>
                    <div *ngIf="submitted && f.concepto.errors" class="invalid-feedback"><div *ngIf="f.concepto.errors.required">Se requiere un concepto</div></div>

                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-3">
                    <label for="estado">Estado</label>
                    <select class="form-control" formControlName="estado">
                        <option>Al cobro</option>
                        <option>Liquidada</option>
                    </select>
                </div>
                <div class="form-group col-md-3">
                    <label for="fechafactura">Fecha factura</label>
                    <div class="input-group">
                        <input class="form-control" formControlName="fechafactura" value="{{ f.fechafactura.value | date:'yyyy-MM-dd' }}" placeholder="yyyy-mm-dd" [ngClass]="{ 'is-invalid': submitted && f.fechafactura.errors }" name="dp" ngbDatepicker #dp="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                        </div>
                        <div *ngIf="submitted && f.fechafactura.errors" class="invalid-feedback"><div *ngIf="f.fechafactura.errors.required">Debe introducir una fecha</div></div>
                    </div>
                </div>
                <div class="form-group col-md-3">
                    <label for="fechafactura">Fecha al cobro</label>
                    <div class="input-group">
                        <input class="form-control" formControlName="fechaalcobro" value="{{ f.fechaalcobro.value | date:'yyyy-MM-dd' }}" placeholder="yyyy-mm-dd" name="dp1" ngbDatepicker #dp1="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dp1.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-3">
                    <label for="fechaliquida">Fecha liquidada</label>
                    <div class="input-group">
                        <input class="form-control" formControlName="fechaliquida" value="{{ f.fechaliquida.value | date:'yyyy-MM-dd' }}" placeholder="yyyy-mm-dd" name="dp2" ngbDatepicker #dp2="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dp2.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-3 mb-3">
                    <label for="tipoiva">Tipo Iva</label>
                    <select class="form-control" formControlName="tipoiva">
                        <option>21</option>
                        <option>16</option>
                        <option>7</option>
                    </select>
                </div>
                <div class="form-group col-md-3 mb-3">
                    <label for="tipoirpf">Tipo Irpf</label>
                    <select class="form-control" formControlName="tipoirpf">
                        <option>21</option>
                        <option>19</option>
                        <option>18</option>
                        <option>15</option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label for="liquidador">Selecciona el Liquidador:</label>
                <select formControlName="liquidador" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.liquidador.errors }">
                    <option value="" disabled selected>...</option>
                    <option *ngFor="let entidad of entidadesToSelect" value="{{entidad._id}}">{{entidad.Rao}}</option>
                </select>
                <div *ngIf="submitted && f.liquidador.errors" class="invalid-feedback"><div *ngIf="f.liquidador.errors.required">Se requiere una Entidad Liquidadora</div></div>
            </div>
            <div class="form-group">
                <label for="notas">Notas</label>
                <textarea formControlName="notas" class="form-control" rows="6"></textarea>
            </div>
        </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
        <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar</button>
    </div>
    </form>
</ng-template>