export class Actor {
    constructor(
        public _id: String,
        public Nom: String,
        public Cognom1: String,
        public Cognom2: String,
        public NifNie: String,
        public Telefono: String,
        public email: string,
        public Notas: String,
        public _idEntidad: String,
        public Rol: string,
        public Ext: string,
        public emailsAutorizados: String[]
        )
    {}
}