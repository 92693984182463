import { Component, QueryList, ViewChildren } from '@angular/core';
import { stitch } from '../../../_services';
import { Entidad } from '../../../_models/entidad.model';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { EntidadesService, EntidadesTableService } from '../../../_services';
import { NgbdSortableHeader, SortEvent } from '../../../_helpers/sortable.directive';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'entidades',
    templateUrl: 'entidadesTable.component.html',
    providers: [{provide: EntidadesService, useClass: EntidadesTableService}],
    styleUrls: ['../../../_helpers/components.component.css']
})

export class EntidadesComponent {
    
    entidades: Entidad[];
    entidades$: BehaviorSubject<Entidad[]>;
    total$: BehaviorSubject<number>;
    administrador: Boolean;
    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    constructor(
        public route: Router,
        public stitch: stitch,
        public entidadesTableService: EntidadesService
        ){
            this.entidades$ = entidadesTableService._entidades$;
            this.total$ = entidadesTableService._total$;
            this.administrador = environment.administradores.includes(stitch.client.auth.user.profile.email);
        }

        onSort({column, direction}: SortEvent) {
            // resetting other headers
            this.headers.forEach(header => {
                if (header.sortable !== column) {
                    header.direction = '';
                }
            });
            this.entidadesTableService.sortColumn = column;
            this.entidadesTableService.sortDirection = direction;
        }
    
        procesaSet(){
            //Cuando quiero actualizar instantáneamente
            /*procesaSet(entidades){
                this.entidades$.next(entidades);
                this.total$.next(entidades.length);*/
            this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => this.route.navigate(['/entidades']));
        }

        openMatriz(entidad) {
            this.route.navigate(['/entidadDetalle/' + entidad._id.$oid])
        }
}