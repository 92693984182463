<div class="container-fluid overflow-auto">
    <div class="row">
        <div class="col-3">
            <h1 class="font-weight-light">Gestión de Facturas</h1>
            <form>
                <div class="form-group form-inline">
                    <input class="font-weight-lighter" id="cercaTermF" type="text" name="searchTerm" placeholder="Búsqueda por término..." (input)="updateChart()" [(ngModel)]="facturasTableService.searchTerm" (ngModelChange)="onSearchTermChange($event)"/>
                </div>
            </form>
            <button type="button" (click)="setEstado('Al cobro')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Al cobro</button>
            <button type="button" (click)="setEstado('Liquidada')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Liquidada</button>
            <select class="custom-select mr-1 mb-2" style="width: auto" name="year" (change)="updateChart()" [(ngModel)]="facturasTableService.year" (ngModelChange)="onYearChange($event)">
                <option [ngValue]="0">Todos</option>
                <option [ngValue]="2024">2024</option>
                <option [ngValue]="2023">2023</option>
                <option [ngValue]="2022">2022</option>
                <option [ngValue]="2021">2021</option>
                <option [ngValue]="2020">2020</option>
                <option [ngValue]="2019">2019</option>
                <option [ngValue]="2018">2018</option>
                <option [ngValue]="2017">2017</option>
                <option [ngValue]="2016">2016</option>
                <option [ngValue]="2015">2015</option>
                <option [ngValue]="2014">2014</option>
                <option [ngValue]="2013">2013</option>
                <option [ngValue]="2012">2012</option>
                <option [ngValue]="2011">2011</option>
                <option [ngValue]="2010">2010</option>
                <option [ngValue]="2009">2009</option>
                <option [ngValue]="2008">2008</option>
                <option [ngValue]="2007">2007</option>
                <option [ngValue]="2006">2006</option>
                <option [ngValue]="2005">2005</option>
                <option [ngValue]="2004">2004</option>        
            </select>
        </div>
        <div class="col-6">
            <canvas #chart width="auto" height="auto"></canvas>
        </div>
        <div *ngIf="(superUser) && env.features.facturacion" class="col-3 d-flex justify-content-end">
            <div class="container">
                <div class="row justify-content-end">
                    <h6 class="font-weight-light">Total Base Imponible: {{ facturasTableService.totalBase$ | async | currency:'EUR':'symbol':'1.2-2':'es' }}</h6>
                </div>
                <div class="row justify-content-end">
                    <h6 class="font-weight-light">Total Encargos: {{ encargosTableService.totalPresupuestos$ | async | currency:'EUR':'symbol':'1.2-2':'es' }}</h6>
                </div>
                <div class="row justify-content-end">
                    <h6 class="font-weight-bolder text-danger" style="display: flex; align-items: center;"><span placement="left" ngbTooltip="De los encargos correspondientes a las facturas que se muestran." style="cursor:pointer;"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"/></svg></span> Resta Facturar: {{ encargosTableService.restaFacturar$ | async | currency:'EUR':'symbol':'1.2-2':'es' }}</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-between">
        <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="facturasTableService.pageSize">
            <option [ngValue]="10">Mostrar 10 registros</option>
            <option [ngValue]="25">Mostrar 25 registros</option>
            <option [ngValue]="50">Mostrar 50 registros</option>
        </select>
    <ngb-pagination class="d-flex justify-content-end" [(page)]="facturasTableService.page" [(pageSize)]="facturasTableService.pageSize" [collectionSize]="total$ | async" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>  
    </div>
    
    <div *ngIf="facturasTableService.loading$ | async" class="d-flex justify-content-center" style="height: 100vh;">
        <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div><br>
        <p class="lead"> Cargando datos...</p>
    </div>
    <table *ngIf="facturas$" class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'>
    <thead>
        <tr>
            <th class="sortable" sortable="codifactura" (sort)="onSort($event)" style="width: 5%">Codi</th>
            <th class="openIcon">File</th>
            <th class="sortable dataCol" sortable="fechafactura" (sort)="onSort($event)">Data Fact.</th>
            <th>Concepto</th>
            <th class="valCol">Base Imp.</th>
            <th class="valCol">Suplidos</th>
            <th class="sortable dataCol" sortable="fechaliquida" (sort)="onSort($event)">Liquidada</th>
            <th class="sortable" sortable="raoLiquidador" (sort)="onSort($event)" style="width: 24%">Liquidador</th>
        </tr>
    </thead>
    <tbody>
            <tr *ngFor="let factura of facturas$ | async" (click)= "openProjecte(factura._idProyecto.$oid)" [ngClass]="{'p-3 mb-2 bg-warning text-dark': factura.estado === 'Al cobro'}">
                <td>{{ factura.codifactura }}</td>
                <td><span style="cursor: pointer"><a href="{{factura.url}}" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/><path d="M0 0h24v24H0z" fill="none"/></svg></a></span></td>
                <td>{{ factura.fechafactura | date:'dd-MM-yyy' }}</td>
                <td><ngb-highlight [result]="factura.concepto | slice:0:80" [term]="facturasTableService.searchTerm"></ngb-highlight></td>
                <td><ngb-highlight [result]="factura.baseimponible | currency:'EUR':'symbol':'1.2-2':'es'" [term]="facturasTableService.searchTerm"></ngb-highlight></td>
                <td><ngb-highlight [result]="factura.gastossuplidos | currency:'EUR':'symbol':'1.2-2':'es'" [term]="facturasTableService.searchTerm"></ngb-highlight></td>
                <td><ngb-highlight [result]="factura.fechaliquida | date:'dd-MM-yyy'" [term]="facturasTableService.searchTerm"></ngb-highlight></td>
                <td><a routerLink="/entidadDetalle/{{factura._idLiquidador.$oid}}"><ngb-highlight [result]="factura.raoLiquidador" [term]="facturasTableService.searchTerm"></ngb-highlight></a></td>
        </tr>
    </tbody>
</table>
<!--Caso 1: No hay búsqueda-->
<h6 *ngIf="!facturasTableService.searchTerm" class="font-weight-light">Mostrando registros del {{(facturasTableService.page - 1 ) * facturasTableService.pageSize + 1}} al {{((facturasTableService.page - 1 ) * facturasTableService.pageSize) + facturasTableService.pageSize}} de un total de {{ facturasTableService.total$ | async }}</h6>
<!--Caso 2: Hay búsqueda y más de una página-->
<h6 *ngIf="facturasTableService.searchTerm && ((facturasTableService.total$ | async) >= facturasTableService.pageSize) && (facturasTableService.page !== (((facturasTableService.total$ | async) % facturasTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(facturasTableService.page - 1 ) * facturasTableService.pageSize + 1}} al {{((facturasTableService.page - 1 ) * facturasTableService.pageSize) + ((facturasTableService.total$ | async) % facturasTableService.pageSize) + (facturasTableService.pageSize - ((facturasTableService.total$ | async) % facturasTableService.pageSize))}} de un total de {{ facturasTableService.total$ | async }} (filtrado de un total de {{facturasTableService.totalFacturas}})</h6>
<!--Caso 3: Hay búsqueda, más de una página y es la última page-->
<h6 *ngIf="facturasTableService.searchTerm && ((facturasTableService.total$ | async) >= facturasTableService.pageSize) && (facturasTableService.page == (((facturasTableService.total$ | async) % facturasTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(facturasTableService.page - 1 ) * facturasTableService.pageSize + 1}} al {{((facturasTableService.page - 1 ) * facturasTableService.pageSize) + ((facturasTableService.total$ | async) % facturasTableService.pageSize)}} de un total de {{ facturasTableService.total$ | async }} (filtrado de un total de {{facturasTableService.totalFacturas}})</h6>
<!--Caso 3: Hay búsqueda y solo una página-->
<h6 *ngIf="facturasTableService.searchTerm && facturasTableService.pageSize >= (facturasTableService.total$ | async)" class="font-weight-light">Mostrando registros del {{(facturasTableService.page - 1 ) * facturasTableService.pageSize + 1}} al {{((facturasTableService.page - 1 ) * facturasTableService.pageSize) + ((facturasTableService.total$ | async) % facturasTableService.pageSize)}} de un total de {{ facturasTableService.total$ | async }} (filtrado de un total de {{facturasTableService.totalFacturas}})</h6>
</div>
