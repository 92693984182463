import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Actor, Entidad } from '../../../_models';
import { stitch, ActorsService } from '../../../_services';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'formActor',
    templateUrl: 'formActor.component.html',
    providers: [ActorsService],
    styleUrls: ['formActor.component.css']
})

export class FormActorComponent {

    administrador: Boolean;
    
    @Input() actor: Actor;
    @Input() entidad: Entidad;
    @Input() user: String;
    @Output() actorSet = new EventEmitter<any[]>();

    actoresExistentes: Actor[];
    actorForm : FormGroup;
    submitted: Boolean = false;
    loading: Boolean = false;
    _id: any;

    constructor(
        public stitch: stitch,
        public actorService: ActorsService,
        public modalService: NgbModal,
        public router: Router,
        public fb: FormBuilder
        ){
            this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
        }

    open(content) {
        this.submitted = false;
        if (this.actor._id === ''){
            this.actorForm = this.fb.group({
                _id: [{value: this.actor._id, disabled: true}],
                Nom: ['', Validators.required],
                Cognom1: [''],
                Cognom2: [''],
                NifNie: [''],
                Telefono: ['', Validators.required],
                email: ['', Validators.required],
                Notas: [''],
                Rol: ['', Validators.required],
                Ext: ['']
              });
        } else {
            this.drawActorForm();
        }
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result;
    }

    get f() { return this.actorForm.controls; }

    onSubmit() {
        this.loading = true;
        this.submitted = true;
        if (this.actorForm.invalid) {
            this.loading = false;
            return;
        }
        this.actorForm.disable();
        if (this.f._id.value === ''){
            this._id = new BSON.ObjectId();
        } else {
            this._id = this.f._id.value;
        };
        const actor = [
            this._id,
            this.f.Nom.value,
            this.f.Cognom1.value,
            this.f.Cognom2.value,
            this.f.NifNie.value,
            this.f.Telefono.value,
            this.f.Ext.value,
            this.f.email.value,
            this.f.Rol.value,
            this.f.Notas.value,
            this.entidad._id,
            this.entidad.email
        ];
        this.actorService.setActorById(actor)
        .then(result => { 
            this.actorSet.emit(actor);
            this.loading = false;
            this.submitted = false;
            this.modalService.dismissAll();
        });
    }

    delActor(id){
        this.loading = true;
        this.actorForm.disable();
        this.actorService.delActorById(id)
        .then(() => {
            this.actorSet.emit();
            this.submitted = false;
            this.loading = false;
            this.modalService.dismissAll();
            this.router.navigate(['/entidadDetalle/' + this.entidad._id.toString()]);
        });
    }

    drawActorForm(){
        this.actorForm =  this.fb.group({
            _id: [{value: this.actor._id, disabled: true}],
            Nom: [this.actor.Nom, Validators.required],
            Cognom1: [this.actor.Cognom1],
            Cognom2: [this.actor.Cognom2],
            NifNie: [this.actor.NifNie],
            Telefono: [this.actor.Telefono, Validators.required ],
            Ext: [this.actor.Ext],
            email: [this.actor.email, Validators.required ],
            Rol: [this.actor.Rol, Validators.required ],
            Notas: [this.actor.Notas]
        });
        if(!this.administrador){
            this.actorForm.disable();
        }; 
    }
}