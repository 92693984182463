import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Encargo, Entidad, Actor } from '../../../_models';
import { stitch, EncargosService, ActorsService } from '../../../_services';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'formAutorizado',
    templateUrl: 'formAutorizado.component.html',
    providers: [EncargosService, ActorsService],
    styleUrls: ['formAutorizado.component.css']
})

export class FormAutorizadoComponent {

    administrador: Boolean;
    
    @Input() encargo: Encargo;
    @Input() idPareEncargo: any;
    @Input() idTitularEncargo: any;
    @Input() email: string;
    @Output() autorizadoSet = new EventEmitter<any>();

    autorizadoForm : FormGroup;
    submitted = false;
    loading = false;
    encargosToSelect: any[];
    actoresToSelect: any[];
    idParticipante: Entidad;

    constructor(
        public stitch: stitch,
        public actorService: ActorsService,
        public encargoService: EncargosService,
        public modalService: NgbModal,
        public fb: FormBuilder
        ){
            this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);          
            encargoService.getProyectosToSelect()
            .then(result => {
                this.encargosToSelect = result;
            });
            this.actorService.getActoresToSelect()
            .then(result => {
                this.actoresToSelect = result;
            });
        }

    open(content) {
        this.submitted = false;
        if (this.email === ''){
            //Caso nueva autorización
            this.drawAutorizadoForm(); 
            this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result;      
        } else {
            this.drawAutorizadoFormExistent(); 
            this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result
        }
    }

    get f() { return this.autorizadoForm.controls; }

    onSubmit() {
        this.loading = true;
        this.submitted = true;
        if (this.autorizadoForm.invalid) {
            return;
        }
        this.autorizadoForm.disable();
        if(this.f.encargo.disabled){
            //desde proyecto
            this.encargoService.addActorAutorizado(this.encargo._id, this.idPareEncargo, this.idTitularEncargo, this.f.actor.value)
            .then(() => {
                this.loading = false;
                this.autorizadoSet.emit();
                this.submitted = false;
                this.modalService.dismissAll();
            });        
        } else {
            //desde otro sitio
            this.encargoService.addActorAutorizado(this.f.encargo.value, this.idPareEncargo, this.idTitularEncargo, this.f.actor.value)
            .then(() => {
                this.loading = false;
                this.autorizadoSet.emit();
                this.submitted = false;
                this.modalService.dismissAll();
            });
        }
    }

    /**
     * Elimina la participacio de l'entitat d'un projecte.
     * @param {ObjectId} id - El id de la interacció.
     * 
    */
    delAutorizado(idEncargo, idPare, idTitular){
        this.loading = true;
        this.autorizadoForm.disable();
        this.encargoService.delActorAutorizado(idEncargo, idPare, idTitular, this.f.actor.value)
        .then(() => {
            this.loading = false;
            this.autorizadoSet.emit();
            this.submitted = false;
            this.modalService.dismissAll();
        });
    }

   /**
   * Inicialitzar formulari participació.
   * @assign Assigna valors buits a la variable @participacionForm
   */
    drawAutorizadoForm(){
        this.autorizadoForm = this.fb.group({
            actor: ['', Validators.required],
            encargo: [{value: this.encargo._id, disabled: true}]
        });
    }

    /**
   * Inicialitzar formulari participació.
   * @assign Assigna valors buits a la variable @participacionForm
   */
    drawAutorizadoFormExistent(){
        this.autorizadoForm = this.fb.group({
            actor: [{value: this.email, disabled: true}],
            encargo: [{value: this.encargo._id, disabled: true}]
        });
    }
}