<div class="container-fluid overflow-auto">
<h1 class="font-weight-light">Álbum de Fotos</h1>
<form>
<div class="form-group form-inline">
    <input class="font-weight-lighter" id="cercaTerm" type="text" name="searchTerm" placeholder="Búsqueda por término..." [(ngModel)]="fotosTableService.searchTerm"/>
</div>
</form>
<button type="button" (click)="setAmbito('Establecimiento')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Establecimiento</button>
<button type="button" (click)="setAmbito('Accesibilidad')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Accesibilidad</button>
<button type="button" (click)="setAmbito('Señalizacion')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Señalización</button>
<button type="button" (click)="setAmbito('BajaTension')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Baja Tensión</button>
<button type="button" (click)="setAmbito('AltaTension')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Alta Tensión</button>
<button type="button" (click)="setAmbito('Climatizacion')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Climatización</button>
<button type="button" (click)="setAmbito('Ventilacion')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Ventilación</button>
<button type="button" (click)="setAmbito('ContraIncendios')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Contra Incendios</button>
<button type="button" (click)="setAmbito('AguaFria')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Agua Fría</button>
<button type="button" (click)="setAmbito('Evacuacion')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Evacuación</button>
<button type="button" (click)="setAmbito('Gas')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Gas</button>
<button type="button" (click)="setAmbito('Generacion')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Generación</button>
<button type="button" (click)="setAmbito('Solar')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Energía Solar</button>
<button type="button" (click)="setAmbito('ProductosPetroliferos')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Prod. Petrolíferos</button>

<!--<button type="button" (click)="setTipo('Planos')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Planos</button>-->
<div class="d-flex justify-content-between">
    <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="fotosTableService.pageSize">
        <option [ngValue]="10">Mostrar 10 registros</option>
        <option [ngValue]="25">Mostrar 25 registros</option>
        <option [ngValue]="50">Mostrar 50 registros</option>
    </select>
    <ngb-pagination class="d-flex justify-content-end" [(page)]="fotosTableService.page" [(pageSize)]="fotosTableService.pageSize" [collectionSize]="total$ | async" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>  
</div>
<div *ngIf="fotosTableService.loading$ | async" class="d-flex justify-content-center" style="height: 100vh;">
    <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
    </div><br>
    <p class="lead"> Cargando datos...</p>
</div>
<div *ngIf="fotos$" class="card-deck"> 
        <div class="card-columns">
            <div *ngFor="let foto of fotos$ | async" class="card">
                <img src="{{foto.url}}" class="card-img-top img-fluid" alt="{{foto.name}}">
                <div class="card-body">
                    <span style="cursor: pointer" ><p (click)="openEncargo(foto)" class="card-title">{{foto.codiEncargo}} <small class="text-muted">{{foto.descripcioEncargo}}</small></p></span>
                    <p class="card-text"><small class="text-muted">{{foto.texto}}</small></p>
                    <!--<span style="cursor: pointer" ><svg (click)="downLoadFoto(foto.s3.key)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z"/></svg></span>-->
                    <a href="{{foto.url}}" download="archivo.JPG"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z"/></svg></a>
                </div>
            </div>
        </div>
</div>
<!--Caso 1: No hay búsqueda-->
<h6 *ngIf="!fotosTableService.searchTerm" class="font-weight-light">Mostrando registros del {{(fotosTableService.page - 1 ) * fotosTableService.pageSize + 1}} al {{((fotosTableService.page - 1 ) * fotosTableService.pageSize) + fotosTableService.pageSize}} de un total de {{ fotosTableService.total$ | async }}</h6>
<!--Caso 2: Hay búsqueda y más de una página-->
<h6 *ngIf="fotosTableService.searchTerm && ((fotosTableService.total$ | async) >= fotosTableService.pageSize) && (fotosTableService.page !== (((fotosTableService.total$ | async) % fotosTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(fotosTableService.page - 1 ) * fotosTableService.pageSize + 1}} al {{((fotosTableService.page - 1 ) * fotosTableService.pageSize) + ((fotosTableService.total$ | async) % fotosTableService.pageSize) + (fotosTableService.pageSize - ((fotosTableService.total$ | async) % fotosTableService.pageSize))}} de un total de {{ fotosTableService.total$ | async }} (filtrado de un total de {{fotosTableService.totalFotos}})</h6>
<!--Caso 3: Hay búsqueda, más de una página y es la última page-->
<h6 *ngIf="fotosTableService.searchTerm && ((fotosTableService.total$ | async) >= fotosTableService.pageSize) && (fotosTableService.page == (((fotosTableService.total$ | async) % fotosTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(fotosTableService.page - 1 ) * fotosTableService.pageSize + 1}} al {{((fotosTableService.page - 1 ) * fotosTableService.pageSize) + ((fotosTableService.total$ | async) % fotosTableService.pageSize)}} de un total de {{ fotosTableService.total$ | async }} (filtrado de un total de {{fotosTableService.totalFotos}})</h6>
<!--Caso 3: Hay búsqueda y solo una página-->
<h6 *ngIf="fotosTableService.searchTerm && fotosTableService.pageSize >= (fotosTableService.total$ | async)" class="font-weight-light">Mostrando registros del {{(fotosTableService.page - 1 ) * fotosTableService.pageSize + 1}} al {{((fotosTableService.page - 1 ) * fotosTableService.pageSize) + ((fotosTableService.total$ | async) % fotosTableService.pageSize)}} de un total de {{ fotosTableService.total$ | async }} (filtrado de un total de {{fotosTableService.totalFotos}})</h6>
</div>  