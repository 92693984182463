import { Component, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Documento } from '../../../_models';
import { DocumentosService, DocumentosTableService } from '../../../_services';
import { stitch } from '../../../_services';
import { NgbdSortableHeader, SortEvent } from '../../../_helpers/sortable.directive';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'documentos',
    templateUrl: 'documentosTable.component.html',
    providers: [{provide: DocumentosService, useClass: DocumentosTableService}],
    styleUrls: ['../../../_helpers/components.component.css']
})

export class DocumentosComponent {

    administrador: Boolean;
    documentos: Documento[];
    documentos$: Observable<Documento[]>;
    total$: Observable<number>;
    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
    
    constructor(
        public route: Router,
        public documentosTableService: DocumentosService,
        public stitch: stitch
        )
        {
            this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
            this.documentos$ = documentosTableService.documentos$;
            this.total$ = documentosTableService.total$;
            //this.watcher();
        }

    /*async watcher() {
        const documentos = this.stitch.db.collection('Documentos');
        const changeStream = await documentos.watch();
        changeStream.onNext(next => {
            console.log(next.fullDocument);
        });
    }*/

    openMatriz(documento) {
        if(documento.codiMatriz.length > 0){
            this.route.navigate(['/encargoDetalle/' + documento._idMatriz.$oid])
        } else {
            this.route.navigate(['/entidadDetalle/' + documento._idMatriz.$oid])
        }
    }

    setAmbito(ambito){
        var ambitos = this.documentosTableService.ambitos;
        if(ambitos.includes(ambito)){
            const index = ambitos.indexOf(ambito);
            if( index > -1) {
                ambitos.splice(index,1);
            }
            this.documentosTableService.ambitos = ambitos;
        } else {
            ambitos.push(ambito);
            this.documentosTableService.ambitos = ambitos;
        }
    }

    setTipo(tipo){
        var tipos = this.documentosTableService.tipos;
        if(tipos.includes(tipo)){
            const index = tipos.indexOf(tipo);
            if( index > -1) {
                tipos.splice(index,1);
            }
            this.documentosTableService.tipos = tipos;
        } else {
            tipos.push(tipo);
            this.documentosTableService.tipos = tipos;
        }
    }

    onSort({column, direction}: SortEvent) {
        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });
        this.documentosTableService.sortColumn = column;
        this.documentosTableService.sortDirection = direction;
    }
}