<span *ngIf="administrador" style="cursor: pointer"><svg (click)="open(content)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="32px" height="32px"><path d="M0 0h24v24H0z" fill="none"/><path d="M21 6h-3.17L16 4h-6v2h5.12l1.83 2H21v12H5v-9H3v9c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zM8 14c0 2.76 2.24 5 5 5s5-2.24 5-5-2.24-5-5-5-5 2.24-5 5zm5-3c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3zM5 6h3V4H5V1H3v3H0v2h3v3h2z"/></svg></span>
<ng-template #content let-modal>
<form [formGroup]="fotoForm" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <h4 class="modal-title font-weight-light" id="modal-basic-title">Añadir Foto</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
    <div *ngIf="encargo._id === ''" class="form-row">
    <div class="form-group col-md-12">
        <label for="encargo">Selecciona un Proyecto:</label>
        <select formControlName="encargo" class="form-control"><option *ngFor="let encargo of encargosToSelect" value="{{encargo._id}}">{{encargo.properties.codi}} - {{encargo.properties.descripcio}}</option></select>
    </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-12">
            <div class="custom-file">
                <input type="file" class="custom-file-input" multiple formControlName="importFile" id="importFile" (change)="onFileChange($event)" multiple [ngClass]="{ 'is-invalid': submitted && f.importFile.errors }">
                <label [innerHTML]="label" class="custom-file-label" #labelImport for="importFile"></label>
            </div>
            <div class="file-list mt-2" *ngIf="filesToUpload.length > 0">
                <p *ngFor="let file of selectedFiles" class="mb-0">{{ file.name }}</p>
            </div>
            <div *ngIf="submitted && f.importFile.errors" class="invalid-feedback"><div *ngIf="f.importFile.errors.required">Se requiere un Archivo</div>
            </div>
        </div>
    </div>
    <div class="form-row">
            <div class="form-group col-md-3 mb-3">
                <select class="form-control" formControlName="ambitos" [ngClass]="{ 'is-invalid': submitted && f.ambitos.errors }">
                    <option value="" disabled selected>Selecciona un ámbito</option>
                    <option value="Establecimiento">General Establecimiento</option>
                    <option value="Accesibilidad">Accesibilidad</option>
                    <option value="Señalizacion">Señalización</option>
                    <option value="BajaTension">Baja Tensión</option>
                    <option value="AltaTension">Alta Tensión</option>
                    <option value="Climatizacion">Climatización</option>
                    <option value="Ventilacion">Ventilación</option>
                    <option value="ContraIncendios">Contra Incendios</option>
                    <option value="AguaFria">Suministro Agua</option>
                    <option value="Evacuacion">Evacuación Agua</option>
                    <option value="Gas">Gases</option>
                    <option value="Generacion">Generación</option>
                    <option value="Solar">Energía Solar</option>
                    <option value="ProductosPetroliferos">Prod. Petrolíferos</option> 
                </select>
                <div *ngIf="submitted && f.ambitos.errors" class="invalid-feedback"><div *ngIf="f.ambitos.errors.required">Se requiere un Ámbito</div></div>
            </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-12">
            <input type="text" formControlName="texto" class="form-control" placeholder="Introduce un texto descriptivo de la foto" [ngClass]="{ 'is-invalid': submitted && f.texto.errors }"/>
            <div *ngIf="submitted && f.texto.errors" class="invalid-feedback"><div *ngIf="f.texto.errors.required">Se requiere una descripción</div></div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
        <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="upLoading" class="spinner-border spinner-border-sm mr-1"></span>Añadir</button>
    </div>
</div>
</form>
</ng-template>