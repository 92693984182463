export class Interaccion {
    constructor(
        public _id: String,
        public estado: string,
        public asunto: String,
        public texto: String,
        public fecha: Date,
        public user: String,
        public proyecto: {
            _id: String,
            codi: String,
            descripcio: String
        },
        public emailsAutorizados: String[]
        )
    {}
}