import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';


import {
    Stitch,
    UserPasswordAuthProviderClient
} from 'mongodb-stitch-browser-sdk';

@Component({
    templateUrl: 'registro.component.html'
})

export class RegistroComponent implements OnInit {

    client = Stitch.defaultAppClient;
    emailPasswordClient = this.client.auth.getProviderClient(UserPasswordAuthProviderClient.factory);
    registerForm : FormGroup;
    submitted = false;

    constructor(
        public router: Router,
        public fb: FormBuilder,
      ) {}

    async ngOnInit() {
        this.drawRegisterForm();
    }

    get f() { return this.registerForm.controls; }

    async onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        try {
            await this.emailPasswordClient.registerWithEmail(this.f.email.value, this.f.password.value).then(() => {
                alert('Registro realizado correctamente. Recibirá en su buzón de entrada un correo de confirmación para completarlo.');
                this.router.navigate(['/']);
            }); 
        } catch (e) {
            alert(e);
        }
    }

    drawRegisterForm(){
        this.registerForm =  this.fb.group({
          email: ['', Validators.required],
          password: ['', Validators.required]
        });
    }
}