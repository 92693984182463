<span style="cursor: pointer"><svg (click)="open(content)" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.22 0 .41.1.55.25.12.13.2.31.2.5 0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-.19.08-.37.2-.5.14-.15.33-.25.55-.25zM19 19H5V5h14v14zM12 6c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zm0 4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-6 6.47V18h12v-1.53c0-2.5-3.97-3.58-6-3.58s-6 1.07-6 3.58zM8.31 16c.69-.56 2.38-1.12 3.69-1.12s3.01.56 3.69 1.12H8.31z"/></svg></span>
<ng-template #content let-modal>
    <form [formGroup]="titularidadForm" disable (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h4 class="modal-title font-weight-light" id="modal-basic-title">Añadir Titularidad</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label for="titular">Selecciona un Proyecto:</label>
                <select formControlName="titular" class="form-control"><option>Selecciona el encargo del que es titular...</option><option *ngFor="let proyecto of encargosToSelect" value="{{proyecto._id}}">{{proyecto.properties.codi}} - {{proyecto.properties.descripcio}}</option></select>
            </div>  
        </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
        <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar</button>
    </div>
    </form>
</ng-template>