export const environment = {
    production: true,
    _id: '5fe7b0ed80e00a5a0bd7f513', //Id stitch de la entidad
    appId: 'gesnormfrontspa-enhvq', //Realm/Stitch AppId
    webhookService: 'GesNorm_Service', //Nombre del servicio WebHook Mongodb
    org: 'JLC Ingenieros', //Nombre de la organización
    cifNif: '20151498W', //Id Fiscal
    telOrg: '(+34) 616 992 440', //Teléfono
    emailOrg: 'jlcampos@iies.es', //Correo
    direccionOrg: 'C/. Carlos III, 28 Bajo', //Dirección
    cpOrg: '07800', //Código Postal
    locOrg: 'Eivissa', //Localidad
    munOrg: 'Eivissa', //Municipio
    countryOrg: '', //Pais
    logoOrgUrl: '../assets/brand/GesNormLogo40px.png', //Logo entidad
    webOrg: 'jlc-ingenieros.com', //Web principal de la entidad
    superUsers: ['jlcampos@iies.es'], //Super usuarios que administran a administradores
    administradores: ['jlcampos@iies.es', 'jlcampos@me.com'], //Administradores de la entidad
    mongoDb: 'GesAdmin', //Nombre de la base de datos Mongodb
    awsBucket: 'gesadminjlc', //Nombre del Bucket de AWS. Solo admite minusculas
    features: {
      mapa: true,
      tickets: true,
      facturacion: true,
      autorizados: true,
      cloud: true,
      graphs: true
    } //Opciones funcionalidades aplicación
  };  