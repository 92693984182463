<div class="container-fluid overflow-auto">
  <h1 class="font-weight-light">Gestión de Interacciones</h1>
  <form>
    <div class="form-group form-inline">
      <input class="font-weight-lighter" id="cercaTerm" type="text" name="searchTerm" placeholder="Búsqueda por término..." [(ngModel)]="interaccionesTableService.searchTerm"/>
    </div>
  </form>
  <button type="button" (click)="setEstado('A resolver')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">A resolver</button>
  <button type="button" (click)="setEstado('En espera')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">En espera</button>
  <button type="button" (click)="setEstado('Resuelto')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Resuelto</button>
  <div class="d-flex justify-content-between">
    <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="interaccionesTableService.pageSize">
        <option [ngValue]="10">Mostrar 10 registros</option>
        <option [ngValue]="25">Mostrar 25 registros</option>
        <option [ngValue]="50">Mostrar 50 registros</option>
    </select>
    <ngb-pagination class="d-flex justify-content-end" [(page)]="interaccionesTableService.page" [(pageSize)]="interaccionesTableService.pageSize" [collectionSize]="total$ | async" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>  
  </div>
  <div *ngIf="interaccionesTableService.loading$ | async" class="d-flex justify-content-center" style="height: 100vh;">
    <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div><br>
    <p class="lead"> Cargando datos...</p>
  </div>
  <table *ngIf="interacciones$" class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'>
    <thead>
      <tr>
        <th class="dataCol">Fecha</th>
        <th style="width: 62%">Asunto</th>
        <th style="width: 5%">Proyecto</th>
        <th style="width: 25%">Referencia</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let lineaInteraccion of interacciones$ | async" (click)= "openProjecte(lineaInteraccion.proyecto._id.$oid)" [ngClass]="{'table-warning': lineaInteraccion.estado === 'En espera', 'table-danger': lineaInteraccion.estado ==='A resolver'}">
        <td>{{ lineaInteraccion.fecha | date:'dd-MM-yyy' }}</td>
        <td><ngb-highlight [result]="lineaInteraccion.asunto" [term]="interaccionesTableService.searchTerm"></ngb-highlight></td>
        <td><ngb-highlight [result]="lineaInteraccion.proyecto.codi" [term]="interaccionesTableService.searchTerm"></ngb-highlight></td>
        <td><ngb-highlight [result]="lineaInteraccion.proyecto.descripcio | slice:0:50" [term]="interaccionesTableService.searchTerm"></ngb-highlight></td>
      </tr>
    </tbody>
  </table>
<!--Caso 1: No hay búsqueda-->
<h6 *ngIf="!interaccionesTableService.searchTerm" class="font-weight-light">Mostrando registros del {{(interaccionesTableService.page - 1 ) * interaccionesTableService.pageSize + 1}} al {{((interaccionesTableService.page - 1 ) * interaccionesTableService.pageSize) + interaccionesTableService.pageSize}} de un total de {{ interaccionesTableService.total$ | async }}</h6>
<!--Caso 2: Hay búsqueda y más de una página-->
<h6 *ngIf="interaccionesTableService.searchTerm && ((interaccionesTableService.total$ | async) >= interaccionesTableService.pageSize) && (interaccionesTableService.page !== (((interaccionesTableService.total$ | async) % interaccionesTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(interaccionesTableService.page - 1 ) * interaccionesTableService.pageSize + 1}} al {{((interaccionesTableService.page - 1 ) * interaccionesTableService.pageSize) + ((interaccionesTableService.total$ | async) % interaccionesTableService.pageSize) + (interaccionesTableService.pageSize - ((interaccionesTableService.total$ | async) % interaccionesTableService.pageSize))}} de un total de {{ interaccionesTableService.total$ | async }} (filtrado de un total de {{interaccionesTableService.totalInteracciones}})</h6>
<!--Caso 3: Hay búsqueda, más de una página y es la última page-->
<h6 *ngIf="interaccionesTableService.searchTerm && ((interaccionesTableService.total$ | async) >= interaccionesTableService.pageSize) && (interaccionesTableService.page == (((interaccionesTableService.total$ | async) % interaccionesTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(interaccionesTableService.page - 1 ) * interaccionesTableService.pageSize + 1}} al {{((interaccionesTableService.page - 1 ) * interaccionesTableService.pageSize) + ((interaccionesTableService.total$ | async) % interaccionesTableService.pageSize)}} de un total de {{ interaccionesTableService.total$ | async }} (filtrado de un total de {{interaccionesTableService.totalInteracciones}})</h6>
<!--Caso 3: Hay búsqueda y solo una página-->
<h6 *ngIf="interaccionesTableService.searchTerm && interaccionesTableService.pageSize >= (interaccionesTableService.total$ | async)" class="font-weight-light">Mostrando registros del {{(interaccionesTableService.page - 1 ) * interaccionesTableService.pageSize + 1}} al {{((interaccionesTableService.page - 1 ) * interaccionesTableService.pageSize) + ((interaccionesTableService.total$ | async) % interaccionesTableService.pageSize)}} de un total de {{ interaccionesTableService.total$ | async }} (filtrado de un total de {{interaccionesTableService.totalInteracciones}})</h6>
</div>
  