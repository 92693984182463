import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { appRoutingModule } from './app.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { LoginComponent, RegistroComponent, ConfirmRegistroComponent, InteraccionesComponent, ResetPasswordComponent, SendResetPasswordEmailComponent } from '../_components';
import { HomeComponent, MapaComponent, EntidadesComponent, ActoresComponent, EncargosComponent, DocumentosComponent, CommentsComponent, FacturasComponent, FotosComponent } from '../_components';
import { EntidadDetalleComponent, ActorDetalleComponent, EncargoDetalleComponent } from '../_components';
import { FormEntidadComponent, FormEncargoComponent, FormActorComponent, FormCommentComponent, FormInteraccionComponent, FormFacturaComponent, FormTitularidadComponent, FormParticipacionComponent, FormAutorizadoComponent, FormDocumentoComponent, FormFotoComponent, FormCurrentUserComponent, FormTicketComponent } from '../_components/';
import { NgbdSortableHeader } from '../_helpers/sortable.directive';
import { EuroDirective } from '../_helpers/euro.directive';
import { TicketsContainerComponent } from 'src/_components/tickets/tickets-container/tickets-container.component';

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        appRoutingModule,
        NgbModule
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        RegistroComponent,
        ConfirmRegistroComponent,
        ResetPasswordComponent,
        SendResetPasswordEmailComponent,
        HomeComponent,
        MapaComponent,
        ActoresComponent,
        EntidadesComponent,
        EncargosComponent,
        DocumentosComponent,
        InteraccionesComponent,
        FacturasComponent,
        FotosComponent,
        CommentsComponent,
        EntidadDetalleComponent,
        ActorDetalleComponent,
        EncargoDetalleComponent,
        FormEntidadComponent,
        FormEncargoComponent,
        FormActorComponent,
        FormCommentComponent,
        FormInteraccionComponent,
        FormParticipacionComponent,
        FormAutorizadoComponent,
        FormDocumentoComponent,
        FormFotoComponent,
        FormFacturaComponent,
        FormTitularidadComponent,
        FormCurrentUserComponent,
        TicketsContainerComponent,
        FormTicketComponent,
        NgbdSortableHeader,
        EuroDirective
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }