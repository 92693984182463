import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Foto } from '../../../_models';
import { FotosService, FotosTableService } from '../../../_services';

@Component({
    selector: 'fotos',
    templateUrl: 'fotosTable.component.html',
    providers: [ {provide: FotosService, useClass: FotosTableService} ],
    styleUrls: ['../../../_helpers/components.component.css']
})

export class FotosComponent {

    fotos: Foto[];
    fotos$: Observable<Foto[]>;
    total$: Observable<number>;
    
    constructor(
        public route: Router,
        public fotosTableService: FotosService)
        {
            this.fotos$ = fotosTableService.fotos$;
            this.total$ = fotosTableService.total$;
        }

    openEncargo(foto) {
        this.route.navigate(['/encargoDetalle/' + foto._idEncargo.$oid])
    }

    setAmbito(ambito){
        var ambitos = this.fotosTableService.ambitos;
        if(ambitos.includes(ambito)){
            const index = ambitos.indexOf(ambito);
            if( index > -1) {
                ambitos.splice(index,1);
            }
            this.fotosTableService.ambitos = ambitos;
        } else {
            ambitos.push(ambito);
            this.fotosTableService.ambitos = ambitos;
        }
    }
}