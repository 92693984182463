<nav class="navbar navbar-expand navbar-dark bg-dark">
    <a class="navbar-brand lead" routerLink="/"><img src="../assets/brand/GesNormLogo40px.png" width="120%" height="120%" class="d-inline-block align-top" alt="Home"></a>
    <div *ngIf="currentUser" class="navbar-nav mr-auto">
        <a *ngIf="env.features.mapa" class="nav-item nav-link lead" routerLink="mapa">Mapa</a>
        <a class="nav-item nav-link lead" routerLink="entidades">Entidades</a>
        <a class="nav-item nav-link lead" routerLink="actores">Actores</a>
        <a class="nav-item nav-link lead" routerLink="encargos">Encargos</a>
        <a class="nav-item nav-link lead" routerLink="interacciones">Interacciones</a>
        <a class="nav-item nav-link lead" routerLink="documentos">Documentos</a>
        <a *ngIf="env.features.autorizados" class="nav-item nav-link lead" routerLink="comments">Comments</a>
        <a *ngIf="superUser && env.features.facturacion" class="nav-item nav-link lead" routerLink="facturas">Facturas</a>
        <a class="nav-item nav-link lead" routerLink="fotos">Fotos</a>
    </div>
    <div *ngIf="currentUser" class="navbar-nav ml-auto">
        <a *ngIf="env.features.tickets" class="nav-item nav-link lead" style="cursor: pointer;"><formTicket [ticket]="ticketActivo" [encargo]="ticketActivo?._idEncargo" [user]="currentUser" (ticketSet)="procesaSetTicket($event)"></formTicket></a>
        <a class="nav-item nav-link lead" style="cursor: pointer;"><formCurrentUser [currentUser]="currentUser" (currentUserSet)="procesaSetUser($event)"></formCurrentUser></a>
        <a class="nav-item nav-link lead" style="cursor: pointer;" (click)="logout()"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="36px" height="36px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg></a>
    </div>
  </nav>
  <div class="jumbotron jumbotron.fluid overflow-auto" style="height:100vmax;">
  <router-outlet></router-outlet>
  </div>
  <div *ngIf="currentUser">
  <footer class="fixed-bottom footer navbar navbar-expand navbar-dark bg-dark">
    <p class="text-muted text-start">&copy; 2021 JLC - Ingenieros. Todos los derechos reservados. &middot; <a><span style="cursor: pointer" (click)="open(privacy)">Política de Privacidad</span></a> &middot; <span style="cursor: pointer" (click)="open(terms)">Términos de Uso</span></p>
    <!--<div class="container-fluid">
        <div class="col-md-10">
            <p class="text-muted text-start">&copy; 2021 JLC - Ingenieros. Todos los derechos reservados. &middot; <a><span style="cursor: pointer" (click)="open(privacy)">Política de Privacidad</span></a> &middot; <span style="cursor: pointer" (click)="open(terms)">Términos de Uso</span></p>
        </div>
        <div class="col-md-1">
            <p class="text-muted text-center">
                {{ env.org }}
                {{ env.telOrg }}
                {{ env.emailOrg }}
                {{ env.direccionOrg }}
                {{ env.cpOrg }}
                {{ env.locOrg }}
            </p>
        </div>
        <div class="col-md-1">
            
        </div>
    </div>-->
  </footer>
  </div>
  
  <ng-template #privacy let-modal>
        <div class="modal-header">
            <h4 class="modal-title font-weight-light" id="modal-basic-title"><strong>POLÍTICA DE PRIVACIDAD</strong></h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <h4 class="modal-title font-weight-light" id="modal-basic-title"><strong>Tratamiento de datos personales</strong></h4>
            <hr class="my-4">
            <p>Para que la aplicación resulte totalmente funcional, JLC - Ingenieros trata sus datos personales de acuerdo con lo establecido por la normativa en vigor, respetando sus derechos y con total transparencia.
            </p>
            <p>Para ello, en esta Política de Privacidad, a la que puede acceder en cualquier momento desde el enlace correspondiente de la propia aplicación, puede consultar el detalle completo de cómo utilizaremos sus datos bien en las relaciones que establezcamos con usted o para la operativa de la aplicación.
            </p>
            <p>Las principales normas que regulan los tratamientos que realizamos con sus datos personales son:
            </p>
                <ul>
                    <li><a href="https://www.boe.es/buscar/doc.php?id=DOUE-L-2016-80807" target="_blank">Reglamento (UE) 2016/679</a>, del Parlamento Europeo y del Consejo, de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (en adelante el RGPD).</li>
                    <li><a href="https://www.boe.es/buscar/doc.php?id=BOE-A-2018-16673" target="_blank">Ley Orgánica 3/2018</a>, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (en adelante LOPD).</li>
                </ul>
            
            <p>Para que la información que contienen nuestros ficheros esté siempre actualizada y no contenga errores pedimos a nuestros clientes y usuarios que nos comuniquen, a la mayor brevedad posible, las modificaciones y rectificaciones de sus datos de carácter personal que no puedan ser modificados por ellos mismos.
            </p>
            <hr class="my-4">
            <h4 class="modal-title font-weight-light" id="modal-basic-title"><strong>Responsable (delegado) del tratamiento (protección) de los datos</strong></h4>
            <hr class="my-4">
            <p>El responsable del tratamiento de sus datos personales es D. José Luis Campos Giménez con DNI 20141498W y domicilio en C/. Carlos III, 28 de Ibiza y puede dirigirse a él en la siguiente dirección de correo electrónico <a href="mailto: jlcampos@iies.es">jlcampos@iies.es</a>.
            </p>
            
            <hr class="my-4">
            <h4 class="modal-title font-weight-light" id="modal-basic-title"><strong>Propiedad Intelectual e Industrial</strong></h4>
            <hr class="my-4">
            <p>El portal y las páginas web de JLC-Ingenieros, las páginas que comprende y la información o elementos contenidos en las mismas, incluyen textos, documentos, fotografías, dibujos, representaciones gráficas, bases de datos, programas informáticos, así como logotipos, marcas, nombres comerciales, u otros signos distintivos, protegidos por derechos de propiedad intelectual o industrial, de los que JLC - Ingenieros son titulares o legítimas licenciatarias.
            </p>
            <p>Siempre que el cliente utilice la aplicación como sistema de gestión de un servicio en el que los prodiuctos puedan ser considerados en al ámbito de la normativa referente a la propiedad intelectual e industrial, le será de aplicación la normativa correspondiente.
            </p>
            <p>JLC-Ingenieros no otorga garantía alguna sobre la licitud y legalidad de la información o elementos que los usuarios de la aplicación depositen en los servidores dedicados a JLC-Ingenieros.
            </p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
        </div>
</ng-template>

<ng-template #terms let-modal>
    <div class="modal-header">
        <h4 class="modal-title font-weight-light" id="modal-basic-title"><strong>TÉRMINOS DE USO</strong></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h4 class="modal-title font-weight-light" id="modal-basic-title"><strong>Descripción del Sistema</strong></h4>
        <hr class="my-4">
        <p>JLC - Ingenieros cumple íntegramente con la legislación vigente en materia de protección de datos de carácter personal y/o profesionales y con los compromisos de confidencialidad propios de dichas actividades.
        </p>
        <p>JLC - Ingenieros ha adoptado las medidas técnicas necesarias para mantener el nivel de seguridad requerido, según la naturaleza de los datos personales y/o profesionales tratados y las circunstancias del tratamiento, con el objeto de evitar, en la medida de lo posible y siempre según el estado de la técnica, su alteración, pérdida, tratamiento o acceso no autorizado.
        </p>
        <p>Cuando sea solicitada la cumplimentación de un formulario en el que se recojan datos de carácter personal, se informará al cliente o usuario del destinatario de la información, de la finalidad para la cual se recogen los datos, de la identidad y dirección del responsable del fichero y de la facultad del usuario de ejercitar los derechos de acceso, rectificación, cancelación y oposición al tratamiento de sus datos. Los datos personales recogidos solo serán tratados y/o cedidos con la expresada finalidad, y siempre con el consentimiento del usuario o cliente.
        </p>
        <p>Para que la información que contienen nuestros ficheros esté siempre actualizada y no contenga errores pedimos a nuestros clientes y usuarios que nos comuniquen, a la mayor brevedad posible, las modificaciones y rectificaciones de sus datos de carácter personal que no puedan ser modificados por ellos mismos.
        </p>
        <hr class="my-4">
        <h4 class="modal-title font-weight-light" id="modal-basic-title"><strong>Usos prohibidos y permitidos</strong></h4>
        <hr class="my-4">
        <p>JLC - Ingenieros cumple íntegramente con la legislación vigente en materia de protección de datos de carácter personal y/o profesionales y con los compromisos de confidencialidad propios de dichas actividades.
        </p>
        <p>JLC - Ingenieros ha adoptado las medidas técnicas necesarias para mantener el nivel de seguridad requerido, según la naturaleza de los datos personales y/o profesionales tratados y las circunstancias del tratamiento, con el objeto de evitar, en la medida de lo posible y siempre según el estado de la técnica, su alteración, pérdida, tratamiento o acceso no autorizado.
        </p>
        <p>Cuando sea solicitada la cumplimentación de un formulario en el que se recojan datos de carácter personal, se informará al cliente o usuario del destinatario de la información, de la finalidad para la cual se recogen los datos, de la identidad y dirección del responsable del fichero y de la facultad del usuario de ejercitar los derechos de acceso, rectificación, cancelación y oposición al tratamiento de sus datos. Los datos personales recogidos solo serán tratados y/o cedidos con la expresada finalidad, y siempre con el consentimiento del usuario o cliente.
        </p>
        <p>Para que la información que contienen nuestros ficheros esté siempre actualizada y no contenga errores pedimos a nuestros clientes y usuarios que nos comuniquen, a la mayor brevedad posible, las modificaciones y rectificaciones de sus datos de carácter personal que no puedan ser modificados por ellos mismos.
        </p>
        <hr class="my-4">
        <h4 class="modal-title font-weight-light" id="modal-basic-title"><strong>Responsabilidades</strong></h4>
        <hr class="my-4">
        <p>El portal y las páginas web de JLC-Ingenieros, las páginas que comprende y la información o elementos contenidos en las mismas, incluyen textos, documentos, fotografías, dibujos, representaciones gráficas, bases de datos, programas informáticos, así como logotipos, marcas, nombres comerciales, u otros signos distintivos, protegidos por derechos de propiedad intelectual o industrial, de los que JLC - Ingenieros son titulares o legítimas licenciatarias.
        </p>
        <p>JLC-Ingenieros no otorga garantía alguna sobre la licitud y legalidad de la información o elementos contenidos en las páginas web de JLC-Ingenieros en caso de que la titularidad de los mismos no corresponda a JLC-Ingenieros.
        </p>
        <hr class="my-4">
        <h4 class="modal-title font-weight-light" id="modal-basic-title"><strong>Legislación aplicable</strong></h4>
        <hr class="my-4">
        <p>JLC - Ingenieros cumple íntegramente con la legislación vigente en materia de protección de datos de carácter personal y/o profesionales y con los compromisos de confidencialidad propios de dichas actividades.
        </p>
        <p>JLC - Ingenieros ha adoptado las medidas técnicas necesarias para mantener el nivel de seguridad requerido, según la naturaleza de los datos personales y/o profesionales tratados y las circunstancias del tratamiento, con el objeto de evitar, en la medida de lo posible y siempre según el estado de la técnica, su alteración, pérdida, tratamiento o acceso no autorizado.
        </p>
        <p>Cuando sea solicitada la cumplimentación de un formulario en el que se recojan datos de carácter personal, se informará al cliente o usuario del destinatario de la información, de la finalidad para la cual se recogen los datos, de la identidad y dirección del responsable del fichero y de la facultad del usuario de ejercitar los derechos de acceso, rectificación, cancelación y oposición al tratamiento de sus datos. Los datos personales recogidos solo serán tratados y/o cedidos con la expresada finalidad, y siempre con el consentimiento del usuario o cliente.
        </p>
        <p>Para que la información que contienen nuestros ficheros esté siempre actualizada y no contenga errores pedimos a nuestros clientes y usuarios que nos comuniquen, a la mayor brevedad posible, las modificaciones y rectificaciones de sus datos de carácter personal que no puedan ser modificados por ellos mismos.
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
    </div>
</ng-template>