<div class="container-fluid overflow-auto">
<h1 class="font-weight-light">Gestión de Actores</h1>
<form>
    <div class="form-group form-inline">
        <!--<input class="font-weight-lighter" id="cercaTerm" type="text" placeholder="Búsqueda por término..." [formControl]="filter"/>-->
        <input class="font-weight-lighter" id="cercaTerm" type="text" name="searchTerm" placeholder="Búsqueda por término..." [(ngModel)]="actoresTableService.searchTerm"/>
    </div>
</form>
<button type="button" (click)="setRol('Propiedad')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Propiedad</button>
<button type="button" (click)="setRol('Administrador')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Administrador</button>
<button type="button" (click)="setRol('Comercial')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Comercial</button>
<button type="button" (click)="setRol('Técnico')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Técnico</button>
<button type="button" (click)="setRol('Administrativo')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Administrativo</button>
<button type="button" (click)="setRol('Jefe de Obra')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Jefe Obra</button>
<div class="d-flex justify-content-between">
    <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="actoresTableService.pageSize">
        <option [ngValue]="10">Mostrar 10 registros</option>
        <option [ngValue]="25">Mostrar 25 registros</option>
        <option [ngValue]="50">Mostrar 50 registros</option>
    </select>
    <ngb-pagination class="d-flex justify-content-end" [(page)]="actoresTableService.page" [(pageSize)]="actoresTableService.pageSize" [collectionSize]="total$ | async" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>  
</div>
<div *ngIf="actoresTableService.loading$ | async" class="d-flex justify-content-center" style="height: 100vh;">
    <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div><br>
    <p class="lead"> Cargando datos...</p>
</div>
<table *ngIf="actores$" class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'>
    <thead>
        <tr>
            <th class="sortable" sortable="Nom" (sort)="onSort($event)" style="width: 10%;">Nombre</th>
            <th class="sortable" sortable="Cognom1" (sort)="onSort($event)" style="width: 10%;">Apellido 1</th>
            <th class="sortable" sortable="Cognom2" (sort)="onSort($event)" style="width: 10%;">Apellido 2</th>
            <th style="width: 10%;">Teléfono</th>
            <th style="width: 20%;">email</th>
            <th class="sortable" sortable="raoEntidad" (sort)="onSort($event)" style="width: 40%;">Entidad</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let actor of actores$ | async" (click)="openEntidad(actor._idEntidad.$oid)">
            <td><a routerLink="/actorDetalle/{{actor._id.$oid}}/"><ngb-highlight [result]="actor.Nom" [term]="actoresTableService.searchTerm"></ngb-highlight></a></td>
            <td><ngb-highlight [result]="actor.Cognom1" [term]="actoresTableService.searchTerm"></ngb-highlight></td>
            <td><ngb-highlight [result]="actor.Cognom2" [term]="actoresTableService.searchTerm"></ngb-highlight></td>
            <td><a href= "tel: {{actor.Telefono}}"><ngb-highlight [result]="actor.Telefono" [term]="actoresTableService.searchTerm"></ngb-highlight></a></td>
            <td><a href= "mailto: {{ actor.email }}"><ngb-highlight [result]="actor.email" [term]="actoresTableService.searchTerm"></ngb-highlight></a></td>
            <td><a routerLink="/entidadDetalle/{{actor._idEntidad.$oid}}"><ngb-highlight [result]="actor.raoEntidad" [term]="actoresTableService.searchTerm"></ngb-highlight></a></td>
        </tr>
    </tbody>
</table>
<!--Caso 1: No hay búsqueda-->
<h6 *ngIf="!actoresTableService.searchTerm" class="font-weight-light">Mostrando registros del {{(actoresTableService.page - 1 ) * actoresTableService.pageSize + 1}} al {{((actoresTableService.page - 1 ) * actoresTableService.pageSize) + actoresTableService.pageSize}} de un total de {{ actoresTableService.total$ | async }}</h6>
<!--Caso 2: Hay búsqueda y más de una página-->
<h6 *ngIf="actoresTableService.searchTerm && ((actoresTableService.total$ | async) >= actoresTableService.pageSize) && (actoresTableService.page !== (((actoresTableService.total$ | async) % actoresTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(actoresTableService.page - 1 ) * actoresTableService.pageSize + 1}} al {{((actoresTableService.page - 1 ) * actoresTableService.pageSize) + ((actoresTableService.total$ | async) % actoresTableService.pageSize) + (actoresTableService.pageSize - ((actoresTableService.total$ | async) % actoresTableService.pageSize))}} de un total de {{ actoresTableService.total$ | async }} (filtrado de un total de {{actoresTableService.totalActores}})</h6>
<!--Caso 3: Hay búsqueda, más de una página y es la última page-->
<h6 *ngIf="actoresTableService.searchTerm && ((actoresTableService.total$ | async) >= actoresTableService.pageSize) && (actoresTableService.page == (((actoresTableService.total$ | async) % actoresTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(actoresTableService.page - 1 ) * actoresTableService.pageSize + 1}} al {{((actoresTableService.page - 1 ) * actoresTableService.pageSize) + ((actoresTableService.total$ | async) % actoresTableService.pageSize)}} de un total de {{ actoresTableService.total$ | async }} (filtrado de un total de {{actoresTableService.totalActores}})</h6>
<!--Caso 3: Hay búsqueda y solo una página-->
<h6 *ngIf="actoresTableService.searchTerm && actoresTableService.pageSize >= (actoresTableService.total$ | async)" class="font-weight-light">Mostrando registros del {{(actoresTableService.page - 1 ) * actoresTableService.pageSize + 1}} al {{((actoresTableService.page - 1 ) * actoresTableService.pageSize) + ((actoresTableService.total$ | async) % actoresTableService.pageSize)}} de un total de {{ actoresTableService.total$ | async }} (filtrado de un total de {{actoresTableService.totalActores}})</h6>
</div>