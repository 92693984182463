import { Component } from '@angular/core';
import { stitch } from '../../_services';

@Component({
    templateUrl: 'home.component.html'
})

export class HomeComponent {
    user: any;
    
    constructor(public stitch: stitch){
        this.user = this.stitch.client.auth.user;
    }
}