import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
    Stitch,
    UserPasswordAuthProviderClient
} from 'mongodb-stitch-browser-sdk';

@Component({
    selector: 'resetPassword',
    templateUrl: 'resetPassword.component.html'
})

export class ResetPasswordComponent implements OnInit {

    client = Stitch.defaultAppClient;
    emailPasswordClient = this.client.auth.getProviderClient(UserPasswordAuthProviderClient.factory);
    resetForm : FormGroup;
    submitted = false;
    
    params = new URLSearchParams(window.location.search);
    token = this.params.get('token');
    tokenId = this.params.get('tokenId');


    constructor(
        public router: Router,
        public fb: FormBuilder,
      ) {}

    async ngOnInit() {
        this.drawResetForm();
    }

    get f() { return this.resetForm.controls; }

    async onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.resetForm.invalid) {
            return;
        }
        try {
            await this.emailPasswordClient.resetPassword(this.token, this.tokenId, this.f.password.value).then(() => {
                alert('Restauración realizada correctamente.');
                this.router.navigate(['/']);
            }); 
        } catch (e) {
            alert(e);
        }
    }
    
    drawResetForm(){
      this.resetForm =  this.fb.group({
        password: ['', Validators.required]
      });
  }

}