<div class="container-fluid overflow-auto">
    <div class="row">
        <div class="col-10">
            <h1 class="font-weight-light">Gestión de Encargos</h1>
            <form>
                <div class="form-group form-inline">
                    <input class="font-weight-lighter" id="cercaTerm" type="text" name="searchTerm" placeholder="Búsqueda por término..." [(ngModel)]="encargosTableService.searchTerm"/>
                </div>
            </form>
            <button type="button" (click)="setEstado('Redaccion')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Redacción</button>
            <button type="button" (click)="setEstado('Tramite')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Trámite</button>
            <button type="button" (click)="setEstado('Ejecucion')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Ejecución</button>
            <button type="button" (click)="setEstado('Finalizado')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Finalizado</button>
            <button type="button" (click)="setEstado('Archivado')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Archivado</button>
            <button type="button" (click)="setEstado('Anulado')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Anulado</button>
            <select class="custom-select mr-1 mb-2" style="width: auto" name="year" [(ngModel)]="encargosTableService.year">
                <option [ngValue]="0">Todos</option>
                <option [ngValue]="2024">2024</option>
                <option [ngValue]="2023">2023</option>
                <option [ngValue]="2022">2022</option>
                <option [ngValue]="2021">2021</option>
                <option [ngValue]="2020">2020</option>
                <option [ngValue]="2019">2019</option>
                <option [ngValue]="2018">2018</option>
                <option [ngValue]="2017">2017</option>
                <option [ngValue]="2016">2016</option>
                <option [ngValue]="2015">2015</option>
                <option [ngValue]="2014">2014</option>
                <option [ngValue]="2013">2013</option>
                <option [ngValue]="2012">2012</option>
                <option [ngValue]="2011">2011</option>
                <option [ngValue]="2010">2010</option>
                <option [ngValue]="2009">2009</option>
                <option [ngValue]="2008">2008</option>
                <option [ngValue]="2007">2007</option>
                <option [ngValue]="2006">2006</option>
                <option [ngValue]="2005">2005</option>
                <option [ngValue]="2004">2004</option>
                <option [ngValue]="2003">2003</option>
                <option [ngValue]="2002">2002</option>
                <option [ngValue]="2001">2001</option>
            </select>
        </div>
        <div *ngIf="(superUser) && env.features.facturacion" class="col-md-2 d-flex justify-content-end">
            <div class="container">
                <div class="row justify-content-end">
                    <h6 class="font-weight-light">Total Encargos: {{ encargosTableService.totalPresupuestos$ | async | currency:'EUR':'symbol':'1.2-2':'es' }}</h6>
                </div>
                <div class="row justify-content-end">
                    <h6 class="font-weight-bolder text-danger" style="display: flex; align-items: center;"><span placement="left" ngbTooltip="De los encargos que se muestran." style="cursor:pointer;"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"/></svg></span> Resta Facturar: {{ encargosTableService.restaFacturar$ | async | currency:'EUR':'symbol':'1.2-2':'es' }}</h6>
                </div>
            </div>
        </div>
    </div>
  <div class="d-flex justify-content-between">
      <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="encargosTableService.pageSize">
          <option [ngValue]="10">Mostrar 10 registros</option>
          <option [ngValue]="25">Mostrar 25 registros</option>
          <option [ngValue]="50">Mostrar 50 registros</option>
      </select>
      <ngb-pagination class="d-flex justify-content-end" [(page)]="encargosTableService.page" [(pageSize)]="encargosTableService.pageSize" [collectionSize]="total$ | async" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>  
  </div>
  <div *ngIf="encargosTableService.loading$ | async" class="d-flex justify-content-center" style="height: 100vh;">
      <div class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Loading...</span>
      </div><br>
      <p class="lead"> Cargando datos...</p>
  </div>
  <table *ngIf="encargos$" class="table table-striped table-bordered table-hover" style="width:100%" data-page-length='25'>
  <thead>
      <tr>
          <th class="sortable" sortable="codi" (sort)="onSort($event)" style="width: 5%">Código</th>
          <th class="sortable" sortable="descripcio" (sort)="onSort($event)" style="width: 39%">Objeto</th>
          <th class="sortable dataCol" sortable="data" (sort)="onSort($event)">Fecha</th>
          <th *ngIf="(superUser || loading) && env.features.facturacion">Presupuestado</th>
          <th class="sortable" sortable="pareNom" (sort)="onSort($event)" style="width: 24%">Cliente</th>
          <th class="sortable" sortable="titularNom" (sort)="onSort($event)" style="width: 24%">Titular</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let proyecto of encargos$ | async" (click)="openProjecte(proyecto._id.$oid)" [ngClass]="{'table-success': proyecto.estat === 'Redaccion', 'table-danger': proyecto.estat === 'Ejecucion', 'table-warning': proyecto.estat === 'Tramite'}">
          <td><a routerLink="/encargoDetalle/{{proyecto._id.$oid}}"><ngb-highlight [result]="proyecto.codi" [term]="encargosTableService.searchTerm"></ngb-highlight></a></td>
          <td><ngb-highlight [result]="proyecto.descripcio | slice:0:160" [term]="encargosTableService.searchTerm"></ngb-highlight></td>
          <td><ngb-highlight [result]="proyecto.data | date:'dd-MM-yyy'" [term]="encargosTableService.searchTerm"></ngb-highlight></td>
          <td *ngIf="(superUser || loading) && env.features.facturacion">{{ proyecto.totalPresupuestado | currency:'EUR':'symbol':'1.2-2':'es' }}</td>
          <td><a routerLink="/entidadDetalle/{{ proyecto._idPare.$oid }}"><ngb-highlight [result]="proyecto.pareNom" [term]="encargosTableService.searchTerm"></ngb-highlight></a></td>
          <td><a routerLink="/entidadDetalle/{{ proyecto._idTitular.$oid }}"><ngb-highlight [result]="proyecto.titularNom" [term]="encargosTableService.searchTerm"></ngb-highlight></a></td>
      </tr>
  </tbody>
  </table>
  <!--Caso 1: No hay búsqueda-->
<h6 *ngIf="!encargosTableService.searchTerm" class="font-weight-light">Mostrando registros del {{(encargosTableService.page - 1 ) * encargosTableService.pageSize + 1}} al {{((encargosTableService.page - 1 ) * encargosTableService.pageSize) + encargosTableService.pageSize}} de un total de {{ encargosTableService.total$ | async }}</h6>
<!--Caso 2: Hay búsqueda y más de una página-->
<h6 *ngIf="encargosTableService.searchTerm && ((encargosTableService.total$ | async) >= encargosTableService.pageSize) && (encargosTableService.page !== (((encargosTableService.total$ | async) % encargosTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(encargosTableService.page - 1 ) * encargosTableService.pageSize + 1}} al {{((encargosTableService.page - 1 ) * encargosTableService.pageSize) + ((encargosTableService.total$ | async) % encargosTableService.pageSize) + (encargosTableService.pageSize - ((encargosTableService.total$ | async) % encargosTableService.pageSize))}} de un total de {{ encargosTableService.total$ | async }} (filtrado de un total de {{encargosTableService.totalEncargos}})</h6>
<!--Caso 3: Hay búsqueda, más de una página y es la última page-->
<h6 *ngIf="encargosTableService.searchTerm && ((encargosTableService.total$ | async) >= encargosTableService.pageSize) && (encargosTableService.page == (((encargosTableService.total$ | async) % encargosTableService.pageSize)) - 1)" class="font-weight-light">Mostrando registros del {{(encargosTableService.page - 1 ) * encargosTableService.pageSize + 1}} al {{((encargosTableService.page - 1 ) * encargosTableService.pageSize) + ((encargosTableService.total$ | async) % encargosTableService.pageSize)}} de un total de {{ encargosTableService.total$ | async }} (filtrado de un total de {{encargosTableService.totalEncargos}})</h6>
<!--Caso 3: Hay búsqueda y solo una página-->
<h6 *ngIf="encargosTableService.searchTerm && encargosTableService.pageSize >= (encargosTableService.total$ | async)" class="font-weight-light">Mostrando registros del {{(encargosTableService.page - 1 ) * encargosTableService.pageSize + 1}} al {{((encargosTableService.page - 1 ) * encargosTableService.pageSize) + ((encargosTableService.total$ | async) % encargosTableService.pageSize)}} de un total de {{ encargosTableService.total$ | async }} (filtrado de un total de {{encargosTableService.totalEncargos}})</h6>
</div>