<div *ngIf="loading" class="loadingwrap">
    <div *ngIf="loading"class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div><br>
    <p class="lead"> Cargando datos...</p>
</div>
<ng-template #content let-modal>
    <form [formGroup]="currentUserForm" disable (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h4 class="modal-title font-weight-light" id="modal-basic-title">Datos de Cliente</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-row">
                <div class="form-group col-md-7 mb-3">
                    <label for="Rao">Razón Social</label>
                    <input disabled type="text" formControlName="Rao" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Rao.errors }"/>
                </div>
                <div class="form-group col-md-2 mb-3">
                    <label for="CifNif">Id. Fiscal</label>
                    <input disabled type="text" formControlName="CifNif" class="form-control"/>
                </div>
                <div class="form-group col-md-3 mb-3">
                    <label for="stitchId">stitchId</label>
                    <input disabled type="text" formControlName="stitchId" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12 mb-3">
                    <label for="Direccion">Dirección</label>
                    <input disabled type="text" formControlName="Direccion" class="form-control" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2 mb-3">
                    <label for="Cp">Código Postal</label>
                    <input disabled type="text" formControlName="Cp" class="form-control"/>
                </div>
                <div class="form-group col-md-4 mb-3">
                    <label for="Localidad">Localidad</label>
                    <input disabled type="text" formControlName="Localidad" class="form-control"/>
                </div>
                <div class="form-group col-md-3 mb-3">
                    <label for="Municipio">Municipio</label>
                    <input disabled type="text" formControlName="Municipio" class="form-control"/>
                </div>
                <div class="form-group col-md-3 mb-3">
                    <label for="Pais">Pais</label>
                    <input disabled type="text" formControlName="Pais" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-3 mb-3">
                    <label for="Telefono">Teléfono</label>
                    <input disabled type="text" formControlName="Telefono" class="form-control"/>
                </div>
                <div class="form-group col-md-5 mb-3">
                    <label for="email">email</label>
                    <input disabled type="email" formControlName="email" class="form-control"/>
                </div>
                <div class="form-group col-md-4 mb-3">
                    <label for="web">web</label>
                    <input disabled type="text" formControlName="web" class="form-control"/>
                </div>
            </div>
            <h4 class="modal-title font-weight-light" id="modal-basic-title">Datos de Usuario</h4>
            <hr class="my-4">
            <div class="form-row">
                <div class="form-group col-md-3 mb-3">
                    <label for="nomAdmin">Nombre</label>
                    <input type="text" formControlName="nomAdmin" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nomAdmin.errors }"/>
                    <div *ngIf="submitted && f.nomAdmin.errors" class="invalid-feedback"><div *ngIf="f.nomAdmin.errors.required">Se requiere un nombre</div></div>
                </div>
                <div class="form-group col-md-3 mb-3">
                    <label for="cognom1Admin">Apellido 1</label>
                    <input type="text" formControlName="cognom1Admin" class="form-control"/>
                </div>
                <div class="form-group col-md-3 mb-3">
                    <label for="cognom2Admin">Apellido 2</label>
                    <input type="text" formControlName="cognom2Admin" class="form-control"/>
                </div>
                <div class="form-group col-md-3 mb-3">
                    <label for="idAdmin">Id</label>
                    <input disabled type="text" formControlName="idAdmin" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-3 mb-3">
                    <label for="telAdmin">Teléfono</label>
                    <input type="text" formControlName="telAdmin" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.telAdmin.errors }" />
                    <div *ngIf="submitted && f.telAdmin.errors" class="invalid-feedback">
                    <div *ngIf="f.telAdmin.errors.required">Teléfono del Admin requerido</div>
                    </div>
                </div>
                <div class="form-group col-md-1">
                    <label for="extAdmin">Extensión</label>
                    <input type="text" formControlName="extAdmin" class="form-control"/>
                </div>
                <div class="form-group col-md-6">
                    <label for="emailAdmin">email</label>
                    <input type="email" formControlName="emailAdmin" class="form-control"/>
                </div>          
                <div class="form-group col-md-2 mb-3">
                    <label for="nifAdmin">NIF/NIE</label>
                    <input type="text" formControlName="nifAdmin" class="form-control"/>
                </div>
            </div>
            <div class="form-group">
                <label for="notasAdmin">Notas</label>
                <textarea formControlName="notasAdmin" class="form-control" rows="6"></textarea></div>
            <div *ngIf="superUser">
            <h4 class="modal-title font-weight-light" id="modal-basic-title">Datos de Administración</h4>
            <hr class="my-4">
            <div *ngIf="superUser" class="form-row">
                <div class="form-group col-md-3 mb-3">
                    <label for="mongoDb">Mongo Db</label>
                    <input disabled type="text" formControlName="mongoDb" class="form-control"/>
                </div>
                <div class="form-group col-md-9 mb-3">
                    <p>La base de datos anterior, asociada a su cuenta, se encuentra disponible en un Cluster MONGODB que actualmente ocupa
                         <strong>{{ mongodbStats.Actors[0].storageStats.size + mongodbStats.Comments[0].storageStats.size + mongodbStats.Documentos[0].storageStats.size
                             + mongodbStats.Entidades[0].storageStats.size + mongodbStats.Facturas[0].storageStats.size + 
                             mongodbStats.Fotos[0].storageStats.size + mongodbStats.Interacciones[0].storageStats.size + 
                             mongodbStats.Proyectos[0].storageStats.size }}</strong> Bytes, correspondientes a <strong>{{ mongodbStats.Actors[0].storageStats.count + mongodbStats.Comments[0].storageStats.count + mongodbStats.Documentos[0].storageStats.count
                                + mongodbStats.Entidades[0].storageStats.count + mongodbStats.Facturas[0].storageStats.count + 
                                mongodbStats.Fotos[0].storageStats.count + mongodbStats.Interacciones[0].storageStats.count + 
                                mongodbStats.Proyectos[0].storageStats.count }}</strong> documentos registrados.</p>                    
                </div>
            </div>
            <hr class="my-4">
            <div class="form-row">
                <div class="form-group col-md-3 mb-3">
                    <label for="awsBucket">Aws Bucket</label>
                    <input disabled type="text" formControlName="awsBucket" class="form-control"/>
                </div>
                <div class="form-group col-md-9 mb-3">
                    <p>La documentación relativa a su cuenta se encuentra disponible en el AWS Bucket anterior y actualmente ocupa
                            <strong>{{ totalBucketSize.Datapoints[0].Sum }}</strong> Bytes, correspondiente a <strong>{{ bucketNumberOfObjects.Datapoints[0].Average }}</strong> objetos persistentes.</p>
                    
                </div>
            </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
            <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar</button>
        </div>
    </form>
</ng-template>
<span (click)="open(content)" style="cursor: pointer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="36px" height="36px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 5v14H5V5h14m0-2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 9c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3zm0-4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm6 10H6v-1.53c0-2.5 3.97-3.58 6-3.58s6 1.08 6 3.58V18zm-9.69-2h7.38c-.69-.56-2.38-1.12-3.69-1.12s-3.01.56-3.69 1.12z"/></svg> {{ stitch.client.auth.user.profile.email }}</span>