<span *ngIf="administrador" style="cursor: pointer"><svg (click)="open(content)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H8V4h12v12zM10 9h8v2h-8zm0 3h4v2h-4zm0-6h8v2h-8z"/></svg></span>
<ng-template #content let-modal>
<form [formGroup]="documentoForm" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <h4 class="modal-title font-weight-light" id="modal-basic-title">Añadir Documento</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
    <div *ngIf="encargo._id === ''" class="form-row">
    <div class="form-group col-md-12">
        <label for="encargo">Selecciona un Proyecto:</label>
        <select formControlName="encargo" class="form-control"><option *ngFor="let encargo of encargosToSelect" value="{{encargo._id}}">{{encargo.properties.codi}} - {{encargo.properties.descripcio}}</option></select>
    </div>
    </div>
    <div *ngIf="entidadPare._id === ''" class="form-row">
        <div class="form-group col-md-12">
        <label for="titular">Selecciona una Entidad:</label>
        <select formControlName="entidad" class="form-control"><option *ngFor="let entidad of entidadesToSelect" value="{{entidad._id}}">{{entidad.Rao}}</option></select>
    </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-12">
            <div class="custom-file">
                <input type="file" class="custom-file-input" multiple formControlName="importFile" id="importFile" (change)="onFileChange($event.target.files)" [ngClass]="{ 'is-invalid': submitted && f.importFile.errors }">
                <label [innerHTML]="label" class="custom-file-label" #labelImport for="importFile"></label>
            </div>
            <div *ngIf="submitted && f.importFile.errors" class="invalid-feedback"><div *ngIf="f.importFile.errors.required">Se requiere un Archivo</div></div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-3 mb-3">
            <div class="input-group">
                <input class="form-control" formControlName="fecha" [ngClass]="{ 'is-invalid': submitted && f.fecha.errors }" placeholder="yyyy-mm-dd" value="{{ f.fecha.value | date:'yyy-MM-dd' }}" name="dp" ngbDatepicker #dp="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                </div>
                <div *ngIf="submitted && f.fecha.errors" class="invalid-feedback"><div *ngIf="f.fecha.errors.required">Introduce una fecha</div></div>
            </div>
        </div>
            <div class="form-group col-md-3 mb-3">
                <select class="form-control" formControlName="ambito" [ngClass]="{ 'is-invalid': submitted && f.ambito.errors }">
                <option value="" disabled selected>Ámbito</option>
                <option *ngIf="encargo._id !== 'null'">Proyecto</option>
                <option>Legales</option>
                <option *ngIf="encargo._id !== 'null'">Administracion</option>
            </select>
            <div *ngIf="submitted && f.ambito.errors" class="invalid-feedback"><div *ngIf="f.ambito.errors.required">Se requiere un Ámbito</div></div>
        </div>
        <div *ngIf="encargo._id !== 'null'" class="form-group col-md-3 mb-3">
            <select class="form-control" formControlName="area" [ngClass]="{ 'is-invalid': submitted && f.area.errors }">
                <option value="" disabled selected>Área</option>
                <option>Licencias</option>
                <option>Documentación</option>
                <option>Autorizaciones</option>
            </select>
            <div *ngIf="submitted && f.area.errors" class="invalid-feedback"><div *ngIf="f.area.errors.required">Se requiere un Área</div></div>
        </div>
        <div *ngIf="encargo._id !== 'null'" class="form-group col-md-3 mb-3">
            <select class="form-control" formControlName="tipo" [ngClass]="{ 'is-invalid': submitted && f.tipo.errors }">
                <option value="" disabled selected>Tipo</option>
                <option>Planos</option>
                <option>Memorias</option>
                <option>Mediciones</option>
                <option>Presupuestos</option>
                <option>Pliegos</option>
                <option>Certificados</option>
                <option>Informes</option>
                <option>Acreditaciones</option>
            </select>
            <div *ngIf="submitted && f.tipo.errors" class="invalid-feedback"><div *ngIf="f.tipo.errors.required">Se requiere un Tipo</div></div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-12">
            <input type="text" formControlName="descripcion" class="form-control" placeholder="Introduce un texto descriptivo del documento" [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors }"/>
            <div *ngIf="submitted && f.descripcion.errors" class="invalid-feedback"><div *ngIf="f.descripcion.errors.required">Se requiere una descripción</div></div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
        <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="upLoading" class="spinner-border spinner-border-sm mr-1"></span>Añadir</button>
    </div>
</div>
</form>
</ng-template>