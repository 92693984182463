<span *ngIf="administrador" style="cursor: pointer" ><svg (click)="open(content)" [ngClass]="{'icon': ticket?._id !== ''}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="36px" height="36px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zM3 12c0-2.61 1.67-4.83 4-5.65V4.26C3.55 5.15 1 8.27 1 12s2.55 6.85 6 7.74v-2.09c-2.33-.82-4-3.04-4-5.65z"/></svg></span>

<ng-template #content let-modal>
    <form [formGroup]="ticketForm" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <h4 class="modal-title font-weight-light" id="modal-basic-title">Ticket</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-row">
            <div class="form-group col-md-3">
                <label for="fecha">Fecha</label>
                <div class="input-group">
                    <input class="form-control" formControlName="fecha" placeholder="yyyy-mm-dd" value="{{ f.fecha.value | date:'yyy-MM-dd' }}" [ngClass]="{ 'is-invalid': submitted && f.fecha.errors }" name="dp" ngbDatepicker #dp="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                    </div>
                    <div *ngIf="submitted && f.fecha.errors" class="invalid-feedback"><div *ngIf="f.fecha.errors.required">Debe introducir una fecha</div></div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-12">
                <label for="encargo">Selecciona un Encargo:</label>
                <select formControlName="encargo" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.encargo.errors }">
                    <option value="" disabled selected>...</option>
                    <option *ngFor="let encargo of encargosToSelect" value="{{encargo._id}}">{{encargo.properties.codi}} - {{encargo.properties.descripcio}}</option>
                </select>
                <div *ngIf="submitted && f.encargo.errors" class="invalid-feedback"><div *ngIf="f.encargo.errors.required">Se requiere un Encargo</div></div>
            </div>
        </div>
        <div class="form-group">
            <label for="tarea">Tarea</label>
            <input type="text" formControlName="tarea" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.tarea.errors }"/>
            <div *ngIf="submitted && f.tarea.errors" class="invalid-feedback"><div *ngIf="f.tarea.errors.required">Debe introducir al menos una tarea</div></div>
        </div>
        <div class="form-group">
            <label for="texto">Texto</label>
            <textarea formControlName="texto" class="form-control" rows="6"></textarea>
        </div>
        <hr class="my-4">
        <h4 class="modal-title font-weight-light">Gestión ticket</h4>
        <div *ngIf="this.ticket._id != ''">
            <span style="cursor: pointer"><svg (click)="borraTicket(f._id.value)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zm2-8h6v8H5v-8zm5-6H6L5 5H2v2h12V5h-3z"/></svg></span>
            <span style="cursor: pointer"><svg (click)="pausaTicket(f._id.value)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="36px" height="36px"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16h2V8H9v8zm3-14C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-4h2V8h-2v8z"/></svg></span>
            <span style="cursor: pointer"><svg (click)="cierraTicket(ticket)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="36px" height="36px"><path d="M0 0h24v24H0z" fill="none"/><path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg></span>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancelar</button>
        <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Guardar</button>
    </div>
    </form>
    </ng-template>