import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AwsServiceClient, AwsRequest } from 'mongodb-stitch-browser-services-aws';
import { BSON, RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

import { Documento } from '../_models';
import { stitch } from './stitch.service';
import { SortDirection } from '../_helpers/sortable.directive';
import { environment } from '../environments/environment';

interface CercaResult {
    documentos: any[];
    total: number;
}
  
interface Estado {
    page: number;
    pageSize: number;
    searchTerm: string;
    ambitos: string[];
    areas: string[];
    tipos: string[];
    sortColumn: string;
    sortDirection: SortDirection;
}

function compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}
  
function sort(encargos: any[], column: string, direction: string): any[] {
    if (direction === '') {
        return encargos;
    } else {
        return [...encargos].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
        });
    }
}

function matchesDocumento(text: String, documento: any){
    const term = text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return documento.descripcion.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || documento.descripcioMatriz.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term);
}

@Injectable()
export class DocumentosService {

    user: any;
    administrador: Boolean;
    db: any;
    existeFactura: Boolean;
    regexFactura = /^F.{3}\/.{2}$/;

    constructor(
        public http: HttpClient,
        public stitch: stitch,
    ){
        this.user = this.stitch.client.auth.user;
        this.db = this.stitch.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(environment.mongoDb);
        this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
    }

    _search$ = new Subject<void>();
    _documentos$ = new BehaviorSubject<any[]>([]);
    _total$ = new BehaviorSubject<number>(0);
    _loading$ = new BehaviorSubject<boolean>(true);
    _estado: Estado = {
        page: 1,
        pageSize: 25,
        searchTerm: '',
        ambitos: ['Proyecto', 'Legales', 'Administracion'],
        areas: ['Documentación', 'Licencias', 'Instalaciones', 'Autorizaciones'],
        tipos: ['Planos', 'Acreditaciones', 'Informes', 'Certificados', 'Memorias', 'Presupuestos', 'Pliegos'],
        sortColumn: '',
        sortDirection: ''
    };
    
    documentos: any[];

    get totalDocumentos() { return this.documentos.length; }
    get documentos$() { return this._documentos$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._estado.page; }
    get pageSize() { return this._estado.pageSize; }
    get searchTerm() { return this._estado.searchTerm; }
    get ambitos() { return this._estado.ambitos; }
    get areas() { return this._estado.areas; }
    get tipos() { return this._estado.tipos; }

    set page(page: number) { this._set({page}); }
    set pageSize(pageSize: number) { this._set({pageSize}); }
    set searchTerm(searchTerm: string) { this._set({searchTerm}); }
    set ambitos(ambitos: string[]) { this._set({ambitos}); }
    set areas(areas: string[]) { this._set({areas}); }
    set tipos(tipos: string[]) { this._set({tipos}); }
    set sortColumn(sortColumn: string) { this._set({sortColumn}); }
    set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }
    
    _set(patch: Partial<Estado>) {
        Object.assign(this._estado, patch);
        this._search$.next();
    }

    _search(): Observable<CercaResult>{
        const {sortColumn, sortDirection, pageSize, page, searchTerm, ambitos, areas, tipos} = this._estado;
        //1. sort
        let documentos = sort(this.documentos, sortColumn, sortDirection);
        //2. filter
        documentos = documentos.filter(documento => matchesDocumento(searchTerm, documento) && ambitos.includes(documento.ambito));
        //documentos = documentos.filter(documento => matchesDocumento(searchTerm, documento) && (ambitos.includes(documento.ambito) || tipos.includes(documento.tipo)));
        const total = documentos.length;
        //3. paginate
        documentos = documentos.slice((page-1)*pageSize, (page-1)*pageSize + pageSize);
        return of({documentos, total});
    }

    /**
    * Obtiene las entidades existentes.
    * @return {Array} - Conjunt d'entitats enregistrades.
    */
    getDocumentos (): Observable<Documento[]>{
        const documentosUrl = 'https://webhooks.mongodb-realm.com/api/client/v2.0/app/' + environment.appId + '/service/' + environment.webhookService + '/incoming_webhook/Documentos';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: new HttpParams()
            .set('arg1', environment.mongoDb)
            .set('arg2', this.stitch.client.auth.user.profile.email)
        };
        return this.http.get<Documento[]>(documentosUrl, httpOptions);
    }

    /**
    * Obtiene los documentos de la entidad.
    * @param {String} id - L'identificador de l'entitat.
    * @return {Array} - Conjunt de documents de la entitat.
    */
    async getDocumentosEntidadById(id): Promise<any[]> {
        const _id = new BSON.ObjectId(id);
        return await this.db.collection('Documentos').find(
            { _idMatriz: _id },
            { sort: { fecha: -1 }}
        ).toArray()
    }

    /**
    * Obtiene los documentos de la entidad.
    * @param {String} id - L'identificador de l'encàrrec.
    * @return {Array} - Conjunt de documents de l'èncàrrec.
    */
    async getDocumentosEncargoById(id): Promise<any[]> {
        const _id = new BSON.ObjectId(id);
        return await this.db.collection('Documentos').find(
            { $and: [
                { _idMatriz: _id },
                { 'clasificacion.tipo': { $not: { $regex: "Planos" }}}
            ]},
            { sort: { fecha: -1 }}
        ).toArray()
    }

    /**
    * Obtiene los documentos de la entidad.
    * @param {String} id - L'identificador de l'encàrrec.
    * @return {Array} - Conjunt de plànols de l'encàrrec.
    */
   async getPlanosEncargoById(id): Promise<any[]> {
       const _id = new BSON.ObjectId(id);
        return await this.db.collection('Documentos').find(
            { $and: [
                {_idMatriz: _id },
                { "clasificacion.tipo": "Planos" }
            ]},
            { sort: { fecha: -1 }}
        ).toArray()
    }

    async awsDocUpload(file, entidad, documento): Promise<void> {
        // Grab the file from the input element
        //const file = document.getElementById("file-input").files[0];
        if (!file) {
        return;
        }
        // Process the image file
        const fileBinary = await this.convertImageToBSONBinaryObject(file)
        // Upload the image binary to S3
        const aws = this.stitch.client.getServiceClient(AwsServiceClient.factory, "AWS");
        const key = 'admin/' + entidad._id.toString() + '/doc/' + this.eliminarAcentos(file.name);
        //const key = `${this.stitch.client.auth.user.id}-${file.name}`;
        const bucket = environment.awsBucket;
        const url = `https://${bucket}.s3.amazonaws.com/${encodeURIComponent(key)}`;

        const request = new AwsRequest.Builder()
        .withService("s3")
        .withAction("PutObject")
        .withRegion("us-east-2")
        .withArgs({
            ACL: "public-read",
            Bucket: bucket,
            ContentType: file.type,
            Key: key,
            Body: fileBinary
        });
        try {
            await aws.execute(request.build())
            .then(async () => {
                const fecha1 = new Date(documento.fecha.value.year, documento.fecha.value.month - 1, documento.fecha.value.day);
                const fecha2 = fecha1.toISOString();
                const documentos = this.db.collection('Documentos');
                if (!entidad._idPare) {
                    //desde entidad
                    //comprueba que el documento es una factura
                    if (this.regexFactura.test(documento.descripcion.value)){
                        //comprueba que el registro de la factura está creado
                        //si está creado añádele la url, si no avisa
                        const existeFactura = await this.db.collection('Facturas').findOne({codifactura: documento.descripcion.value});
                        if (!existeFactura){
                            alert ('Antes de añadir una factura debe crearse el registro en el encargo correspondiente');
                            return;
                        } else {
                            this.addUrlFacturaByCodi(existeFactura.codifactura, url);
                        }
                    };
                    //inserta el documento en S3
                    await documentos.insertOne({
                        owner_id: this.stitch.client.auth.user.id,
                        owner_name: this.stitch.client.auth.user.profile.email,
                        fecha: fecha2,
                        descripcion: documento.descripcion.value,
                        clasificacion: {
                            ambito: documento.ambito.value,
                            area: documento.area.value,
                            tipo: documento.tipo.value
                        },
                        url,
                        file: {
                            name: file.name,
                            type: file.type
                        },
                        s3: {
                            bucket,
                            key
                        },
                        _idMatriz: entidad._id,
                        emailsAutorizados: environment.administradores.concat([entidad.email]),
                        ts: new Date()
                    });
                    } else {
                        //desde encargo
                        await documentos.insertOne({
                        owner_id: this.stitch.client.auth.user.id,
                        owner_name: this.stitch.client.auth.user.profile.email,
                        fecha: fecha2,
                        descripcion: documento.descripcion.value,
                        clasificacion: {
                            ambito: documento.ambito.value,
                            area: documento.area.value,
                            tipo: documento.tipo.value
                        },
                        url,
                        file: {
                            name: file.name,
                            type: file.type
                        },
                        s3: {
                            bucket,
                            key
                        },
                        _idMatriz: entidad._id,
                        emailsAutorizados: entidad.emailsAutorizados,
                        ts: new Date()
                        })
                    };
                });
        } catch (e) {
            console.log(e);
        }
    }

    convertImageToBSONBinaryObject(file) {
        return new Promise(resolve => {
            var fileReader = new FileReader();
            fileReader.onload = event => {
                resolve({
                    $binary: {
                        base64: (<string>fileReader.result).split(",")[1],
                        subType: "00"
                    }
                });
            }
            fileReader.readAsDataURL(file);
        })
    }
    /**
     * Elimina acentos del nombre de un archivo
     * @param {String} nombreArchivo - Nombre del archivo con acentos
     * @return {String} - Nombre del archivo sin acentos
     */
    eliminarAcentos(nombreArchivo) {
        return nombreArchivo.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    /**
   * Actualiza un document del projecte.
   * @param {String} owner_name - Identificació del propietari del document, correu elecrtònic.
   * 
   */
  async setDocumento(owner_name, documento){
    if(owner_name != this.stitch.client.auth.user.profile.email){
      alert('Los documentos del Encargo no son modificables.');
      return;
    };
    return await this.db.collection('Documentos').updateOne(
        { _id: documento[0] },
        { $set: {
            fecha: documento[1],
            descripcion: documento[2],
            "clasificacion.ambito": documento[3],
            "clasificacion.area": documento[4],
            "clasificacion.tipo": documento[5]
        }},
        { upsert: true }
    )
    .catch(e => {
       alert('Documento no se ha podido actualizar debido a: ' + e);
     });
  }

   /**
   * Elimina un documento de l'encàrrec.
   * @param {String} owner_name - L'identificador del propietari del document.
   * @param {String} key - La clau de l'arxiu al bucket d'AWS.
   * @param {String} id - L'identificador del document.
   */
  async delDocumento(owner_name, key, id) {
    if(owner_name != this.stitch.client.auth.user.profile.email){
      alert('Los documentos del Encargo no son modificables.');
      return;
    };
    const aws = this.stitch.client.getServiceClient(AwsServiceClient.factory, "AWS");
    const bucket = environment.awsBucket;
    const request = new AwsRequest.Builder()
      .withService("s3")
      .withAction("DeleteObject")
      .withRegion("us-east-2")
      .withArgs({
        Bucket: bucket,
        Key: key,
      });
      if(confirm('Esta acción NO es recuperable. \nDesea eliminar el documento?')) {
        const archivo = await this.db.collection('Documentos').findOne({'s3.key': key});
        try {
            await aws.execute(request.build())
            .then(async () => {
                await this.db.collection('Documentos').deleteOne(
                    {_id: id}
                )
                if(this.regexFactura.test(archivo.descripcion)){
                    this.removeUrlFacturaByCodi(archivo.descripcion);
                }
            });
        } catch (e) {
          console.log(e);
        }
      }
    }

    /**
     * Afegeix l'url a una factura.
     * @param {String} codiFactura - L'objecte factura.
     * @param {String} url - L'usuari que modifica l'actor.
    */
    async addUrlFacturaByCodi(codiFactura, url): Promise<void> {
        await this.db.collection('Facturas').updateOne(
                { codifactura: codiFactura},
                { $set: {
                    'url': url
                }},
                {upsert : true}
                )
                .catch( e => {alert('Factura no se ha podido modificar debido a: ' + e)})
    }

    /**
     * Afegeix l'url a una factura.
     * @param {String} codiFactura - L'objecte factura.
     * @param {String} url - L'usuari que modifica l'actor.
    */
    async removeUrlFacturaByCodi(codiFactura): Promise<void> {
        await this.db.collection('Facturas').updateOne(
                { codifactura: codiFactura},
                { $set: {
                    'url': ''
                }},
                {upsert : true}
                )
                .catch( e => {alert('Factura no se ha podido modificar debido a: ' + e)})
    }
}

@Injectable()
export class DocumentosTableService extends DocumentosService {

    constructor(http: HttpClient, stitch: stitch){
        super(http, stitch)
        this.getDocumentos().subscribe(result => {
            this.documentos = result;
            this._search$.pipe(
                tap(() => this._loading$.next(true)),
                switchMap(() => this._search()),
                tap(() => this._loading$.next(false))
            ).subscribe(result => {
                this._documentos$.next(result.documentos);
                this._total$.next(result.total);
            });
            this._search$.next();
        });
    }
}