import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Encargo, Entidad } from '../../../_models';
import { EncargosService } from '../../../_services';

@Component({
    selector: 'formTitularidad',
    templateUrl: 'formTitularidad.component.html',
    providers: [EncargosService],
    styleUrls: ['formTitularidad.component.css']
})

export class FormTitularidadComponent {
    
    @Input() encargo: Encargo;
    @Input() entidadTitular: Entidad;
    @Output() titularidadSet = new EventEmitter<any[]>();

    titularidadForm : FormGroup;
    submitted = false;
    encargosToSelect: any;

    constructor(
        public encargoService: EncargosService,
        public modalService: NgbModal,
        public fb: FormBuilder
        ){
            encargoService.getProyectosToSelect()
            .then(result => {
                this.encargosToSelect = result;
            });
        }

    open(content) {
        this.submitted = false;
        if (this.encargo._id === ''){
            this.titularidadForm = this.fb.group({
                titular: ['', Validators.required]
            });
        } else {
            this.drawTitularidadForm();
        }
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result;
    }

    get f() { return this.titularidadForm.controls; }

    onSubmit() {
        this.submitted = true;
        if (this.titularidadForm.invalid) {
            return;
        }
        this.encargoService.addTitularidadEntidad(this.f.titular.value, this.entidadTitular._id)
        .then(() => { 
            this.titularidadSet.emit();
            this.submitted = false;
            this.modalService.dismissAll();
        });
    }

    drawTitularidadForm(){
        this.titularidadForm = this.fb.group({
            titular: ['', Validators.required]
          });
    }
}