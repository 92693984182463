import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AwsServiceClient, AwsRequest } from 'mongodb-stitch-browser-services-aws';

import { Encargo, Entidad, Documento } from '../_models';
import { stitch } from './stitch.service';

import { SortDirection } from '../_helpers/sortable.directive';
import { RemoteMongoClient, BSON } from 'mongodb-stitch-browser-sdk';
import { environment } from '../environments/environment';

interface CercaResult {
    entidades: Entidad[];
    total: number;
}
  
interface Estado {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: string;
    sortDirection: SortDirection;
}

function compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}
  
function sort(entidades: Entidad[], column: string, direction: string): Entidad[] {
    if (direction === '') {
        return entidades;
    } else {
        return [...entidades].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
        });
    }
}

function matchesEntidad(text: String, entidad: Entidad){
    const term = text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return entidad.Rao.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || entidad.CifNif.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || entidad.Direccion.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || entidad.Localidad.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || entidad.Municipio.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term)
    || entidad.email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(term);
}

@Injectable()

export class EntidadesService {

    user: any;
    administrador: Boolean;
    superUser: Boolean;
    db: any;

    _loading$ = new BehaviorSubject<boolean>(true);
    _search$ = new Subject<void>();
    _entidades$ = new BehaviorSubject<Entidad[]>([]);
    _total$ = new BehaviorSubject<number>(0);
    _estado: Estado = {
        page: 1,
        pageSize: 25,
        searchTerm: '',
        sortColumn: '',
        sortDirection: ''
    };

    entidades: Entidad[] = [];

    get totalEntidades() { return this.entidades.length; }
    get entidades$() { return this._entidades$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._estado.page; }
    get pageSize() { return this._estado.pageSize; }
    get searchTerm() { return this._estado.searchTerm; }

    set page(page: number) { this._set({page}); }
    set pageSize(pageSize: number) { this._set({pageSize}); }
    set searchTerm(searchTerm: string) { this._set({searchTerm}); }
    set sortColumn(sortColumn: string) { this._set({sortColumn}); }
    set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }
    
    _set(patch: Partial<Estado>) {
        Object.assign(this._estado, patch);
        this._search$.next();
    }

    _search(): Observable<CercaResult>{
        const {sortColumn, sortDirection, pageSize, page, searchTerm} = this._estado;
        //1. sort
        let entidades = sort(this.entidades, sortColumn, sortDirection);
        //2. filter
        entidades = entidades.filter(entidad => matchesEntidad(searchTerm, entidad));
        const total = entidades.length;
        //3. paginate
        entidades = entidades.slice((page-1)*pageSize, (page-1)*pageSize + pageSize);
        return of({entidades, total});
    }

    constructor(
        public http: HttpClient,
        public stitch: stitch
    ){
        this.user = this.stitch.client.auth.user;
        this.db = this.stitch.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(environment.mongoDb);
        this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
        this.superUser = environment.superUsers.includes(this.stitch.client.auth.user.profile.email);
    }

    /**
    * Obtiene las entidades existentes.
    * @return {Array} - Conjunt d'entitats enregistrades.
    */
    getEntidades (): Observable<Entidad[]>{
        const entidadesUrl = 'https://webhooks.mongodb-realm.com/api/client/v2.0/app/' + environment.appId + '/service/' + environment.webhookService + '/incoming_webhook/Entidades';
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: new HttpParams()
            .set('arg1', environment.mongoDb)
            .set ('arg2', this.user.profile.email)
        };
        return this.http.get<Entidad[]>(entidadesUrl, httpOptions);
    }

    /**
     * Obté la informació relativa a l'entitat.
     * @param {String} id - El seu id.
     * @return {Entidad} - Objecte entitat. 
    */
    async getEntidadById(id): Promise<any> {
        var _id = new BSON.ObjectId(id)
        return await this.db.collection('Entidades').findOne(
            { _id: _id }
        )
        .catch(e => {
            alert('Fallo en la conexión al servidor función getEntidadById: ' + e)
        });
    }

    /**
     * Registra una nueva entidad.
     * @param {Entidad} entidad - L'objecte entitat.
     * @param {String} user - L'usuari que elimina la entidad.
    */
    async setEntidad(entidad): Promise<void> {
        if(!this.superUser){
            alert('No tiene permisos para modificar registros.');
            return;
        } else {
        await this.db.collection('Entidades').updateOne(
            { _id: entidad[0]},
            { $set: {
                'Rao': entidad[1],
                "CifNif": entidad[2],
                "DQE": entidad[3],
                "Direccion": entidad[4],
                "Cp": entidad[5],
                "Localidad": entidad[6],
                "Municipio": entidad[7],
                "Telefono": entidad[8],
                "email": entidad[9],
                "web": entidad[10],
                "emailsAutorizados": environment.administradores.concat([entidad[9]])
            }},
            {upsert : true}
        )
        .catch (e => {
            alert('La entidad no se ha podido registrar debido a: ' + e)
        });
        }
    }

    /**
     * Elimina una entidad.
     * @param {ObjectId} id - El id de la entidad.
    */
    async delEntidadById(id): Promise<void> {
        if(!this.superUser){
        alert('No tiene permisos para eliminar registros.');
        return;
        } else {
            if(confirm('Esta acción eliminará todos el personal, documentos y encargos de la entidad y NO es recuperable. \nDesea eliminar la entidad?')) {
                //Elimina Encargos
                await this.db.collection('Proyectos').find(
                    {_idPare: id}
                ).toArray()
                .then((encargos: Encargo[]) => {
                    encargos.forEach(async (encargo: Encargo) => {
                        await this.stitch.client.callFunction('delEncargoById', [encargo._id, environment.mongoDb]);
                        //this.encargoService.delEncargoById(encargo._id, user);
                    });
                })
                .then(async () => {
                //Elimina Actores
                    await this.db.collection('Actors').deleteMany(
                        {_idEntidad: id}
                    );
                })
                .then(async () => {
                //Elimina Documentos
                await this.db.collection('Documentos').find(
                    {_idMatriz: id}
                ).toArray()
                .then((docs: Documento[]) => {
                    docs.forEach(async (doc: Documento) => {
                        const docRequest = new AwsRequest.Builder()
                        .withService("s3")
                        .withAction("DeleteObject")
                        .withRegion("us-east-2")
                        .withArgs({
                            Bucket: doc.s3.bucket,
                            Key: doc.s3.key
                        });
                        await this.stitch.client.getServiceClient(AwsServiceClient.factory, "AWS").execute(docRequest.build())
                        .then( async () => {
                            await this.db.collection('Documentos').deleteOne(
                                {_id: doc._id}
                            );
                        });
                    });
                })    
                })
                .then(async () => {
                    await this.db.collection('Entidades').deleteOne(
                        {_id: id}
                    )
                    .catch(e => {
                        alert('Entidad no se ha podido eliminar debido a: ' + e);
                    });
                });
            }
        }
    }

    /**
    * Obté les dades d'una relació d'entitats que participen en el projecte.
    * @param {Array} participantes - Relació (Array) d'entitats.
    */
    async getEntidadesParticipantes(participantes): Promise<any[]> {
        var result = [];
        participantes.forEach(async (item) => {
            result.push({
                _idEntidad: await this.db.collection('Entidades').findOne({_id: item._id}, {projection: {_id: 1}}),
                rol: item.rol,
                entidad: await this.db.collection('Entidades').findOne({_id: item._id}, {projection: {
                    Rao: 1,
                    Telefono: 1,
                    email:1,
                    web:1,
                    topic:1
                }})
            })
        });
        return result; 
    }

    /**
    * Obté els valors de les entitats per a seleccionar relacions.
    * @param {Array} participantes - Relació (Array) d'entitats.
    */
   async getEntidadesToSelect(): Promise<any[]> {
        return await this.db.collection('Entidades').aggregate([
            { $project: {
                Rao: 1
            }},
            { $sort: { Rao: 1 }}
        ]).toArray()
    }
}

@Injectable()
export class EntidadesTableService extends EntidadesService {

    constructor(http: HttpClient, stitch: stitch){
        super(http, stitch)
        this.getEntidades().subscribe(result => {
            this.entidades = result;
            this._search$.pipe(
                tap(() => this._loading$.next(true)),
                switchMap(() => this._search()),
                tap(() => this._loading$.next(false))
            ).subscribe(result => {
                this._entidades$.next(result.entidades);
                this._total$.next(result.total);
            });
            this._search$.next();
        });
    }   
}