import { BSON } from 'mongodb-stitch-browser-sdk';
export class Foto {
    constructor(
        public _id: BSON.ObjectId,
        public owner_id: string,
        public owner_name: string,
        public fecha: Date,
        public texto: string,
        public ambitos: string[],
        public url: String,
        public file: {
            name: String,
            type: String
        },
        public s3: {
            bucket: String,
            key: String
        },
        public _idEncargo: BSON.ObjectId,
        public ts: Date,
        public emailsAutorizados: String[]
        )
    {}
}