import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    Stitch,
    RemoteMongoClient,
    UserPasswordCredential
} from 'mongodb-stitch-browser-sdk';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root'})

export class stitch {
    client = Stitch.initializeDefaultAppClient(environment.appId);    
    public currentUserSubject = new BehaviorSubject<any>(null);
    public currentUser: Observable<any>;
    public db: any;
    error: string;

    constructor () {
        if (!Stitch.hasAppClient(environment.appId)){
            this.client = Stitch.initializeDefaultAppClient(environment.appId);
        } else {
            this.client = Stitch.defaultAppClient;
        }
        //this.db = this.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(this.client.auth.user.customData.mongoDb);
        this.currentUserSubject.next(this.client.auth.user);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue() {
        return this.currentUserSubject.value;
    }
    
    async login(email, password) {
        const credential = new UserPasswordCredential(email, password);
        try {
            await this.client.auth.loginWithCredential(credential).then(user => {
                this.currentUserSubject.next(user);
                return user;
            })
        } catch (err) {
            alert('Error en el proceso de autenticación: ' + err.message);
        }
    }

    logout() {
        this.currentUserSubject.next(null);
        this.client.auth.logout();
    }

    async setCurrentUser(currentUser): Promise<void> {
        try {
            await this.client.callFunction("setStitchUser", [currentUser]).then(user => {
                console.log(user);
                this.currentUserSubject.next(user);
                return user;
            })
        } catch (err) {
            alert('Error en el proceso de actualización del usuario' + err.message);
        }
    }
}