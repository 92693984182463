import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Encargo, Entidad, Actor, Documento } from '../../../_models';
import { stitch, EntidadesService, EncargosService, DocumentosService } from '../../../_services';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'formDocumento',
    templateUrl: 'formDocumento.component.html',
    providers: [EntidadesService, EncargosService, DocumentosService],
    styleUrls: ['formDocumento.component.css']
})

export class FormDocumentoComponent {

    administrador: Boolean;
    
    @Input() documento: Documento;
    @Input() user: Actor;
    @Input() encargo: Encargo;
    @Input() entidadPare: Entidad;
    @Input() entidadTitular: Entidad;
    @Output() documentoSet = new EventEmitter<any>();

    documentoForm : FormGroup;
    submitted = false;
    encargosToSelect: any;
    entidadesToSelect: any;
    matriz: Entidad;
    docToUpload: File = null;
    upLoading = false;
    label = '<i class="fas fa-search"></i> Selecciona Archivo';

    constructor(
        public stitch: stitch,
        public entidadService: EntidadesService,
        public encargoService: EncargosService,
        public documentoService: DocumentosService,
        public modalService: NgbModal,
        public fb: FormBuilder,
        ){
            this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
            this.encargoService.getProyectosToSelect()
            .then(result => {
                this.encargosToSelect = result;
            });
            this.entidadService.getEntidadesToSelect()
            .then(result => {
                this.entidadesToSelect = result;
            });
        }

    open(content) {
        this.submitted = false;
        this.drawDocumentoForm();
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'}).result;
    }

    get f() { return this.documentoForm.controls; }

    onSubmit() {
        if( this.encargo._id === 'null'){
            this.documentoForm.controls["area"].setErrors(null);
            this.documentoForm.controls["tipo"].setErrors(null);
        }
        this.upLoading = true;
        this.submitted = true;
        if (this.documentoForm.invalid) {
            this.upLoading = false;
            return;
        }
        this.documentoForm.disable();
        if(this.encargo._id !== 'null'){
            //desde proyecto
            this.documentoService.awsDocUpload(this.docToUpload, this.encargo, this.documentoForm.controls)
            .then(() => {
                this.upLoading = false;
                this.label = '<i class="fas fa-search"></i> Selecciona Archivo';
                this.documentoSet.emit();
                this.submitted = false;
                this.modalService.dismissAll();
            });                    
        } else {
            //desde entidad
            this.documentoService.awsDocUpload(this.docToUpload, this.entidadPare, this.documentoForm.controls)
            .then(() => {
                this.upLoading = false;
                this.label = '<i class="fas fa-search"></i> Selecciona Archivo';
                this.documentoSet.emit();
                this.submitted = false;
                this.modalService.dismissAll();
            });
        }
    }

    /**
   * Inicialitzar formulari participació.
   * @assign Assigna valors buits a la variable @participacionForm
   */
    drawDocumentoForm(){
        this.documentoForm = this.fb.group({
            entidad: [{value: this.entidadPare._id, disabled: true}],
            encargo: [{value: this.encargo._id, disabled: false}],
            _id: [{ value: '', disabled: true }],
            fecha: ['', Validators.required],
            importFile: ['', Validators.required],
            descripcion: ['', Validators.required],
            ambito: ['', Validators.required],
            area: ['', Validators.required],
            tipo: ['', Validators.required]
        });
    }

    onFileChange(files: FileList) {
        this.label = Array.from(files)
        //this.labelImport.nativeElement.innerText = Array.from(files)
          .map(f => f.name)
          .join(', ');
        this.docToUpload = files.item(0);
      }
}