import { Directive, HostListener, Self, OnDestroy, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[euro]'
})
export class EuroDirective implements OnDestroy{
    
    public formatterES: Intl.NumberFormat;
    public destroy$ = new Subject<void>();
    public lastFormattedValue: string; // Almacena el último valor formateado
    public isFocused:boolean = false;
    
    constructor(@Self() public ngControl: NgControl, private el: ElementRef) {
        this.formatterES = new Intl.NumberFormat('es-ES', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 2
        });

        // Asegurarse de que el control del formulario esté disponible
        if (this.ngControl && this.ngControl.valueChanges) {
            // Suscribirse a los cambios de valor del control del formulario
            this.ngControl.valueChanges.pipe(
                takeUntil(this.destroy$) // Usar takeUntil para desuscribirse automáticamente
            ).subscribe(value => {
                // Intencionalmente vacío: Manejamos el formateo en los eventos de focus y blur.
            });
        }
    }

    @HostListener('focus') onFocus(){
        this.isFocused = true;
        // Almacenamos el valor formateado actual para poder restaurarlo si no hay cambios.
        this.lastFormattedValue = this.ngControl.value;
        console.log(this.lastFormattedValue);
    }

    @HostListener('blur') onBlur(){
        this.isFocused = false;
        const currentValue = this.ngControl.value;
        // Si el valor actual es igual al último valor formateado, significa que no hubo edición.
        // En este caso, no hacemos nada para evitar añadir decimales innecesarios.
        // Solo aplicamos el formateo si el valor ha sido modificado.
        if (currentValue !== this.lastFormattedValue && currentValue !== '') {
            this.formatInputValue(currentValue);
        }
    }

    @HostListener('input', ['$event']) onInput(event: Event) {
        const input = event.target as HTMLInputElement;
        let value = input.value;
        // Filtrar el valor para permitir solo números y coma.
        value = value.replace(/[^\d,]/g, '');
        // Limitar la entrada a un solo separador decimal (coma)
        const parts = value.split(',');
        if (parts.length > 2) { // Si hay más de una coma, reconstruir la cadena correctamente.
            value = `${parts.shift()},${parts.join('')}`;
        }
        // Establecer el valor filtrado sin emitir el evento para evitar bucles infinitos de actualización.
        // Aquí no formateamos el valor, solo lo filtramos para mantener la coherencia del input.
        this.ngControl.control.setValue(value, { emitEvent: false });
        input.value = value;

    }

    private formatInputValue(value: string){
        if(value) {
            const numericValue = Number(this.unformat(value));
            if (!isNaN(numericValue)) {
                // Aquí convertimos el valor numérico a una cadena que utiliza coma como separador decimal antes de formatearlo.
                const formattedValue = this.formatterES.format(numericValue).replace('.', ',');
                this.ngControl.control.setValue(formattedValue);
                this.lastFormattedValue = formattedValue; // Actualizamos el último valor formateado con el nuevo valor
            }
        }
    }

    private unformat(value: string): string {
        return value.replace(/[^\d,]/g, '').replace(',', '.');
    }

    ngOnDestroy(){
        this.destroy$.next();
        this.destroy$.complete();
    }
}