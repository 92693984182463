import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
    Stitch,
    UserPasswordAuthProviderClient
} from 'mongodb-stitch-browser-sdk';

@Component({
    selector: 'sendResetPasswordEmail',
    templateUrl: 'sendResetPasswordEmail.component.html'
})

export class SendResetPasswordEmailComponent implements OnInit {

    client = Stitch.defaultAppClient;    
    emailPasswordClient = this.client.auth.getProviderClient(UserPasswordAuthProviderClient.factory);
    sendEmailForm : FormGroup;
    submitted = false;

    constructor(
      public router: Router,
      public fb: FormBuilder,
    ) {}

    async ngOnInit() {
      this.drawsendEmailForm();
  }

  get f() { return this.sendEmailForm.controls; }

  async onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.sendEmailForm.invalid) {
        return;
    }
    try {
        await this.emailPasswordClient.sendResetPasswordEmail(this.f.email.value).then(() => {
            alert('Solicitud de restauración de contraseña realizada correctamente. Recibirá en su buzón de entrada un correo para completarla.');
            this.router.navigate(['/']);
        }); 
    } catch (e) {
        alert('El usuario introducido no está registrado en el sistema. Por favor, introduzca un usuario válido.');
    }
}

drawsendEmailForm(){
  this.sendEmailForm =  this.fb.group({
    email: ['', Validators.required]
  });
}



}