import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Encargo, Ticket } from '../../../_models';
import { stitch, TicketsService, EncargosService, ActorsService } from '../../../_services';
import { environment } from '../../../environments/environment';
import { BSON } from 'mongodb-stitch-browser-sdk';

@Component({
    selector: 'formTicket',
    templateUrl: 'formTicket.component.html',
    providers: [TicketsService, EncargosService, ActorsService],
    styleUrls: ['formTicket.component.css']
})

export class FormTicketComponent {
    
    @Input() ticket: Ticket;
    @Input() user: any;
    @Input() encargo: Encargo;
    @Output() ticketSet = new EventEmitter<Ticket>();

    ticketForm : FormGroup;
    submitted = false;
    loading = false;
    fechaTicket: any;
    _id: any;
    encargosToSelect: any[];
    adminId: any;
    time: any;
    administrador: any;

    constructor(
        public stitch: stitch,
        public ticketService: TicketsService,
        public encargoService: EncargosService,
        public actorService: ActorsService,
        public modalService: NgbModal,
        public router: Router,
        public fb: FormBuilder
        ){
            this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);          
            encargoService.getProyectosToSelect()
            .then(result => {
                this.encargosToSelect = result;
            });
            actorService.getActorByEmail(this.stitch.client.auth.user.profile.email)
            .then(result => {   
                this.adminId = result._id;
            });
        }

    open(content) {
        this.submitted = false;
        if (this.ticket._id === ''){
            this.ticketForm = this.fb.group({
                _id: [{value: '', disabled: true}],
                encargo: ['', Validators.required],
                fecha: ['', Validators.required],
                tarea: ['', Validators.required],
                texto: [''],
                user: [this.user.profile.data.email]
              });
              
        } else {
            this.drawTicketForm();
        };
        if (this.ticketForm.controls.user.value != ''){
            if(this.f.user.value != this.user.profile.data.email){
            this.ticketForm.disable();
            }
        };
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result;
    }

    get f() { return this.ticketForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.loading = true;
        const ahora = Date.now();
        // stop here if form is invalid
        if (this.ticketForm.invalid) {
            this.loading = false;
            return;
        }
        if (this.f._id.value === ''){
            this._id = new BSON.ObjectId();
        } else {
            this._id = this.f._id.value;
        };
        if ( this.f.fecha.value.length === 24 ) {
            this.fechaTicket = this.f.fecha.value;
            this.time = ahora - new Date(this.ticket.fecha).getTime();
        } else {
            const fecha1 = new Date(this.f.fecha.value.year, this.f.fecha.value.month - 1, this.f.fecha.value.day, new Date().getHours(), new Date().getMinutes(), new Date().getSeconds());
            this.fechaTicket = fecha1.toISOString();
            this.time = 0;
        };
        this.encargoService.getCodiEncargo(this.f.encargo.value)
        .then((codi) => {
            this.ticket = { 
                _id: this._id,
                fecha: this.fechaTicket,
                estat: 'Obert',
                admin: {
                    _id: this.adminId,
                    email: this.user.profile.data.email,
                },
                encargo: {
                    _idEncargo: this.f.encargo.value,
                    codi: codi.codi
                },
                temps: this.time,
                tarea: this.f.tarea.value,
                texto: this.f.texto.value
            };
            this.ticketService.setTicketById(this.ticket, this.user.profile.email, this.f.user.value)
            .then(() => {
                this.loading = false;
                this.ticketSet.emit(this.ticket);
                this.submitted = false;
                this.modalService.dismissAll();
            });
        });
    }

    async borraTicket(id){
        this.loading = true;
        await this.ticketService.borraTicket(id, this.stitch.client.auth.user.profile.email, this.ticket.admin.email)
        .then(() => {
            this.loading = false;
            this.ticketSet.emit();
            this.submitted = false;
            this.modalService.dismissAll();
        });
    }

    async cierraTicket(ticket){
        await this.ticketService.cierraTicket(ticket, this.stitch.client.auth.user.profile.email, this.ticket.admin.email)
        .then(() => {
            this.loading = false;
            this.ticketSet.emit();
            this.submitted = false;
            this.modalService.dismissAll();
        });
    }

    pausaTicket() {
        alert('Funcionalidad no implementada');
    }

    drawTicketForm(){
        this.ticketForm =  this.fb.group({
            _id: [{value: this.ticket._id, disabled: true}],
            encargo: [this.ticket.encargo._idEncargo],
            fecha: [{value: this.ticket.fecha, disabled: true}],
            tarea: [this.ticket.tarea, Validators.required],
            texto: [this.ticket.texto],
            user: [this.user.profile.data.email]
        });
    }
}