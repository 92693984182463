<div class="container">
        <div class="row">
        <div class="col-sm-8 offset-sm-2">
                <h1 class="display-4">Restablecer contraseña</h1>
                <hr class="my-4">
                <h4 class="lead">Una vez introducida la nueva contraseña y enviado el formulario, recibirá un correo electrónico para completar su restablecimiento.</h4>
                <form *ngIf="resetForm" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                        <label for="password">Contraseña</label>
                        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Mínimo 6 caracteres"/>
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Se requiere una contraseña válida</div>
                        </div>
                </div>
                <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Restaurar
                </button>
                </div>
                </form>
                </div>
        </div>
</div>