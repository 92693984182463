<div class="container-fluid mh-100">
    <div class="row">
        <div class="col-8">
            <h3 class="font-weight-light">Localización geográfica del Encargo</h3>
            <hr class="my-4">
            <div *ngIf="!proyecto">
                <button type="button" (click)="setFiltre('Redaccion')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Redacción</button>
                <button type="button" (click)="setFiltre('Ejecucion')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Ejecución</button>
                <button type="button" (click)="setFiltre('Tramite')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Trámite</button>
                <button type="button" (click)="setFiltre('Archivado')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Archivado</button>
                <button type="button" (click)="setFiltre('Anulado')" class="btn btn-ROL active mr-1 mb-2" data-toggle="button" aria-pressed="true">Anulado</button>
            </div>
            <div #map style="width: 100%; height:800px;"></div>
            <h6 *ngIf="!proyecto" class="font-weight-light mt-2">Mostrando {{ puntsFiltrats }} registros de un total de {{ totalFeatures  }}</h6>
        </div>
        <div class="col-4">
            <h3 class="font-weight-light">Servicios de Información Externos</h3>
            <hr class="my-4">
            <button type="button" (click)="setCatastro()" class="btn btn-ROL mr-1 mb-2" data-toggle="button" aria-pressed="true">Catastro</button>
            <button type="button" (click)="setUrbanismo()" class="btn btn-ROL mr-1 mb-2" data-toggle="button" aria-pressed="true">Urbanismo</button>
            <button type="button" (click)="setUnidadesAdministrativas()" class="btn btn-ROL mr-1 mb-2" data-toggle="button" aria-pressed="true">Límites</button>
            <h5 class="font-weight-light"><p>
                La información que se muestra a continuación procede de los servicios Web del Catastro. <a href="http://www.catastro.meh.es/ayuda/lang/castellano/servicios_web.htm" target="_blank">Servicios Web del Catastro</a>.
            </p>
                      
            Referencia Catastral: <a href='https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?RC1={{pc1}}&RC2={{pc2}}&RC3=&RC4=&esBice=&RCBice1=&RCBice2=&DenoBice=&pest=rc&final=&RCCompleta={{referenciaCatastral}}&from=OVCBusqueda&tipoCarto=nuevo&ZV=NO&ZR=NO' target="_blank">{{ referenciaCatastral }}</a></h5>
            <!--<a href="https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCConCiud.aspx?UrbRus=R&RefC={{referenciaCatastral}}&esBice=&RCBice1=&RCBice2=&DenoBice=&from=OVCBusqueda&pest=rc&RCCompleta={{ referenciaCatastral }}&final=&del={{ provinciaCatastro }}&mun={{ municipioCatastro }}" target="_blank">{{ referenciaCatastral }}</a>!-->
            <h5 class="font-weight-light">Localización servicio: {{ direccionCatastro }}</h5>
        </div>
</div>
<div *ngIf="loading" class="loadingwrap">
    <div *ngIf="loading"class="spinner-border text-warning" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div><br>
    <p class="lead"> Cargando datos...</p>
</div>  
</div>