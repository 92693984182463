import { Component, TemplateRef } from '@angular/core';
import { TicketsService } from 'src/_services/tickets.service';


@Component({
    selector: 'app-tickets',
    templateUrl: 'tickets-container.component.html',
    host: {'[class.ngb-toasts]': 'true'}
})

export class TicketsContainerComponent {
    
    constructor(
        public ticketService: TicketsService
    ) {}

    isTemplate(ticket) { return ticket.textOrTpl instanceof TemplateRef; }

}