import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

import {
    Stitch,
    UserPasswordAuthProviderClient
} from 'mongodb-stitch-browser-sdk';

@Component({
    selector: 'confirmRegistro',
    templateUrl: 'confirmRegistro.component.html'
})

export class ConfirmRegistroComponent implements OnInit {

    apppId = environment.appId;
    client = Stitch.defaultAppClient;
    
    params = new URLSearchParams(window.location.search);
    token = this.params.get('token');
    tokenId = this.params.get('tokenId');
    emailPasswordClient = this.client.auth.getProviderClient(UserPasswordAuthProviderClient.factory);

    constructor(
      public router: Router
    ) {}

    ngOnInit () {

    this.emailPasswordClient.confirmUser(this.token, this.tokenId)
    .then(() => {
      
    })
    .catch(err => console.log(err));
  }
    
    displayResult(result, err) {
        const message = document.getElementById("message");
        if (result === "success") {
          message.innerText = "Successfully confirmed quickstart user. You may close this page.";
        }
        else if (result === "error") {
          message.innerText = "Unable to register user.\n\n" + err;
        }
      }

}