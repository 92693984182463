import { Component, ElementRef, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';

import { stitch, EncargosService, InteraccionesService, FacturasService, ActorsService, CommentsService, EntidadesService, DocumentosService, FotosService, TicketsService } from '../../../_services';
import { Entidad, Encargo, Actor, Factura, Comment, Interaccion, Documento, Ticket } from '../../../_models';
import { environment } from '../../../environments/environment';
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

@Component({
  selector: 'encargoDetalle',
  templateUrl: './encargoDetalle.component.html',
  providers: [InteraccionesService, FacturasService, ActorsService, CommentsService, EntidadesService, EncargosService, DocumentosService, FotosService, TicketsService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../../../_helpers/components.component.css', 'encargoDetalle.component.css']
})
export class EncargoDetalleComponent implements OnDestroy {

  administrador: Boolean;
  superUser: Boolean;
  public subscription: Subscription;


  @ViewChild('labelImport', {static: true}) labelImport: ElementRef;
 
  //Variables globals
    id: any;
    user: any;
    encargo: Encargo;
    db: any;
    env: any;
  //Objetos relativos al encargo
    pareEncargo: Entidad;
    titularEncargo: Entidad;
    actorExistent: Actor;
    facturasProyecto: Factura[];
    commentsProyecto: Comment[];
    interaccionesProyecto: Interaccion[];
    fotosProyecto: any[];
    ambitosFotosEncargo: string[];
    documentosProyecto: Documento[];
    planosProyecto: Documento[];
    participantesProyecto: Entidad[];
    actoresAutorizados: Actor[];
    ticketsEncargo: Ticket[];
  //formularis
    fotoForm: FormGroup;
    editFotoForm: FormGroup;
    documentoForm: FormGroup;
    editDocumentoForm: FormGroup;
  //ajudes
    entidadesToSelect: any;
    submitted: Boolean = false;
    loading: Boolean = false;
    showSpinner = true;
    idParticipante: any;
    raoParticipante: any;
    _idTemp: any;
    ambitosFoto$ = new BehaviorSubject<string[]>([]);
    owner_nameTemp: any;
    fechaDocumento: any;
  //Facturación  
    totalFacturado: any;
    totalTickets: any;
    totalDescuento: any;
    totalAlCobro: any;
  //pagination
    page = 1;
    pageSize = 15;
    
  constructor(
    public route: ActivatedRoute,
    public fb: FormBuilder,
    public modalService: NgbModal,
    public stitch: stitch,
    //Servicios
    public entidadService: EntidadesService,
    public encargoService: EncargosService,
    public documentoService: DocumentosService,
    public facturaService: FacturasService,
    public actorService: ActorsService,
    public interaccionService: InteraccionesService,
    public commentService: CommentsService,
    public fotoService: FotosService,
    public ticketService: TicketsService
  ) {
    this.administrador = environment.administradores.includes(this.stitch.client.auth.user.profile.email);
    this.superUser = environment.superUsers.includes(this.stitch.client.auth.user.profile.email);
    this.user = this.stitch.client.auth.user.profile.email;
    this.id = this.route.snapshot.paramMap.get('_id');
    this.db = this.stitch.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(environment.mongoDb);
    this.env = environment;

    this.drawFotoForm();
    this.drawEditFotoForm();
    this.drawDocumentoForm();
    this.drawEditDocumentoForm();

    this.encargoService.getEncargoById(this.id)
    .then(result => {
      this.encargo = result;
      this.entidadService.getEntidadById(this.encargo._idPare.toString())
      .then(result => {
        this.pareEncargo = result;
      });
      this.entidadService.getEntidadById(this.encargo._idTitular.toString())
      .then(result => {
        this.titularEncargo = result;
      });      
      this.interaccionService.getInteraccionesEncargoById(this.encargo._id.toString())
      .then(result => {
        this.interaccionesProyecto = result;
        this.showSpinner = false;
      });
      
      this.fotoService.getFotosEncargoById(this.encargo._id.toString())
      .then(result => {
        this.fotosProyecto = result;
      }).then(() => {
        const result = [];
        for(const foto of this.fotosProyecto){
          for (let i=0; i<foto.ambitos.length; i++){
            if(!result.includes(foto.ambitos[i])){
              result.push(foto.ambitos[i]);
            };
          };
        };
        this.ambitosFotosEncargo = result;
      });
      this.documentoService.getDocumentosEncargoById(this.encargo._id.toString())
      .then(result => {
        this.documentosProyecto = result;
      });
      this.documentoService.getPlanosEncargoById(this.encargo._id.toString())
      .then(result => {
        this.planosProyecto = result;
      });
      this.commentService.getCommentsEncargoById(this.encargo._id.toString())
      .then(result => {
        this.commentsProyecto = result;
      });

      //Només omplir amb les facturasProyecto si està autoritzat
      this.getFacturasEncargo();
      
      //Només omplir participantesProyecto i entidades
      //si l'usuari és l'administrador.
      if(this.administrador){
        this.entidadService.getEntidadesParticipantes(this.encargo.participantes)
        .then(result => {
          this.participantesProyecto = result;
        });
        this.actorService.getActoresAutorizados(this.encargo.emailsAutorizados.filter(email => !environment.administradores.includes(email)))
        .then(result => {
          this.actoresAutorizados = result;
        });
        this.entidadService.getEntidadesToSelect()
        .then(result => {
          this.entidadesToSelect = result;
        });
        this.ticketService.getTicketsEncargoById(this.encargo._id)
        .then(result => {
          this.ticketsEncargo = result;
          this.totalTickets = 0;
          if(this.ticketsEncargo.length != 0){
            var ticketsProyecto = this.ticketsEncargo.map(a => a.temps);
            this.totalTickets = ticketsProyecto.reduce((acumulator, currentvalue) => acumulator + currentvalue);
          }
        });
      }
    });
    this.actorService.getActorByEmail(this.user)
    .then( result => {
      this.actorExistent = result;
    });
    //Inicializa stream para cambios en tickets.
    if(environment.features.tickets) {
      this.watcher();
    }
  }

  async watcher() {
    var tickets = this.db.collection('Tickets');
    const changeStream = await tickets.watch();
    changeStream.onNext(next => {
        this.ticketService.getTicketsEncargoById(this.encargo._id)
        .then(result => {
          this.ticketsEncargo = result;
          this.totalTickets = this.ticketsEncargo.map(a => a.temps).reduce((acumulator, currentvalue) => acumulator + currentvalue);
        }); 
    });
  }

  ngOnDestroy(){

  }

  /**
   * Inicializar datos facturas.
   * @assign Assigna valors buits a la variable @fotoForm
   */
  getFacturasEncargo() {
    this.facturaService.getFacturasEncargoById(this.encargo._id.toString())
    .then(result => {
      this.facturasProyecto = result;
      this.totalFacturado = 0;
      this.totalDescuento = 0;
      this.totalAlCobro = 0;
      if(this.facturasProyecto.length != 0)
      var facturasEncargo = this.facturasProyecto.map(a => parseFloat(a.baseimponible));
      var Descuentos = this.facturasProyecto.filter(a => a.codifactura.includes("D")).map(a => parseFloat(a.baseimponible));
      if (Descuentos.length !== 0)
        this.totalDescuento = Descuentos.reduce((acumulator, currentvalue) => acumulator + currentvalue);
        this.totalFacturado = facturasEncargo.reduce((acumulator, currentvalue) => acumulator + currentvalue);
        this.totalAlCobro = this.totalFacturado - this.totalDescuento;
      });

  }

  procesaSetEncargo(){
    this.encargoService.getEncargoById(this.id)
    .then(result => {
      this.encargo = result;
      this.entidadService.getEntidadById(this.encargo._idPare.toString())
      .then(result => {
        this.pareEncargo = result;
      });
      this.entidadService.getEntidadById(this.encargo._idTitular.toString())
      .then(result => {
        this.titularEncargo = result;
      });
    });
  }

  procesaSetInteraccion(){
    this.interaccionService.getInteraccionesEncargoById(this.encargo._id.toString())
    .then(result => {
      this.interaccionesProyecto = result;
      this.showSpinner = false;
    });
  }

  procesaSetParticipacion(){
    this.encargoService.getEncargoById(this.id)
    .then(result => {
      this.entidadService.getEntidadesParticipantes(result.participantes)
      .then(result => {
        this.participantesProyecto = result;
      });
    });
  }

  procesaSetAutorizado(){
    this.encargoService.getEncargoById(this.id)
    .then(result => {
      this.encargo = result;
      this.actorService.getActoresAutorizados(result.emailsAutorizados.filter(email => !environment.administradores.includes(email)))
      .then(result => {
        this.actoresAutorizados = result;
      });
    });
  }

  procesaSetComment() {
    this.commentService.getCommentsEncargoById(this.encargo._id.toString())
    .then(result => {
      this.commentsProyecto = result;
    });
  }

  procesaSetFactura() {
    this.getFacturasEncargo();
  }

  procesaSetDocumento() {
    this.documentoService.getDocumentosEncargoById(this.encargo._id.toString())
    .then(result => {
      this.documentosProyecto = result;
    });

    this.documentoService.getPlanosEncargoById(this.encargo._id.toString())
    .then(result => {
      this.planosProyecto = result;
    });
  }

  procesaSetFoto(){
    this.fotoService.getFotosEncargoById(this.encargo._id.toString())
    .then(result => {
      this.fotosProyecto = result;
    }).then(() => {
      const result = [];
      for(const foto of this.fotosProyecto){
        for (let i=0; i<foto.ambitos.length; i++){
          if(!result.includes(foto.ambitos[i])){
            result.push(foto.ambitos[i]);
          };
        };
      };
      this.ambitosFotosEncargo = result;
    });
  }

  procesaSetTicket() {
    this.ticketService.getTicketsEncargoById(this.encargo._id)
        .then(result => {
          this.ticketsEncargo = result;
    });
  }

  open(content){
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'foto-modal'}).result;
  }

  setAmbitoFoto(ambito, ambitos$){
    const ambitos = ambitos$.value;
    if(ambitos.includes(ambito)){
        const index = ambitos.indexOf(ambito);
        if( index > -1) {
            ambitos.splice(index,1);
        }
        this.ambitosFoto$.next(ambitos);
    } else {
        ambitos.push(ambito);
        this.ambitosFoto$.next(ambitos);
    }
  }

  setButtonStyle(ambito) {
    if (this.ambitosFoto$.value.includes(ambito)) {
      return {
        'btn': true,
        'btn-ROL': true,
        'active': true,
        'mr-1': true,
        'mb-2': true
      };
    } else {
      return {
        'btn': true,
        'btn-ROL': true,
        'active': false,
        'mr-1': true,
        'mb-2': true
      }
    }
  }

  openToEditFoto(editarFoto, foto){
    this.editFotoForm.enable();
    this.editFotoForm.setValue({_id: foto._id, url: foto.url, owner_name: foto.owner_name, texto: foto.texto, ambitos: foto.ambitos});
    if (foto.owner_name != this.user){
      this.editFotoForm.disable();
    }
    this._idTemp = foto._id;
    this.ambitosFoto$.next(foto.ambitos);
    this.owner_nameTemp = foto.owner_name;
    this.modalService.open(editarFoto, {ariaLabelledBy: 'modal-basic-title', size: 'xl'}).result;
  }

  openToEditDocumento(editarDocumento, lineaDocumento){
    this.editDocumentoForm.enable();
    this.editDocumentoForm.setValue({
      _id: lineaDocumento._id,
      key: lineaDocumento.s3.key,
      url: lineaDocumento.url,
      owner_name: lineaDocumento.owner_name,
      fecha: lineaDocumento.fecha,
      descripcion: lineaDocumento.descripcion,
      ambito: lineaDocumento.clasificacion.ambito,
      area: lineaDocumento.clasificacion.area,
      tipo: lineaDocumento.clasificacion.tipo
    });
    if(lineaDocumento.owner_name != this.user){
      this.editDocumentoForm.disable();
    }
    this._idTemp = lineaDocumento._id;
    this.owner_nameTemp = lineaDocumento.owner_name;
    this.modalService.open(editarDocumento, {ariaLabelledBy: 'modal-basic-title', size: 'xl'}).result;
  }

  onEditFoto() {
    this.fotoService.setFoto(this._idTemp, this.owner_nameTemp, this.editFotoForm.controls.texto.value, this.ambitosFoto$.value)
    .then( () => {
      this.drawFotoForm();
      this.modalService.dismissAll();
      this.fotoService.getFotosEncargoById(this.encargo._id.toString())
      .then(result => {
        this.fotosProyecto = result;
      });
      })
      .catch(e => {
        alert('Fotografía no se ha podido actualizar debido a: ' + e);
      });
  }

  onEditDocumento() {
    if ( this.editDocumentoForm.controls.fecha.value.length === 24 ) {
       this.fechaDocumento = this.editDocumentoForm.controls.fecha.value;
    } else {
      const fecha1 = new Date(this.editDocumentoForm.controls.fecha.value.year, this.editDocumentoForm.controls.fecha.value.month - 1, this.editDocumentoForm.controls.fecha.value.day);
      this.fechaDocumento = fecha1.toISOString();
    }
    const documento = [
      this._idTemp,
      this.fechaDocumento,
      this.editDocumentoForm.controls.descripcion.value,
      this.editDocumentoForm.controls.ambito.value,
      this.editDocumentoForm.controls.area.value,
      this.editDocumentoForm.controls.tipo.value
    ]
    this.documentoService.setDocumento(this.owner_nameTemp, documento)
    .then(() => {
      this.drawDocumentoForm();
      this.modalService.dismissAll();
      this.documentoService.getDocumentosEncargoById(this.encargo._id.toString())
      .then(result => {
        this.documentosProyecto = result;
      });
      this.documentoService.getPlanosEncargoById(this.encargo._id.toString())
      .then(result => {
        this.planosProyecto = result;
      });
     })
     .catch(e => {
       alert('Documento no se ha podido actualizar debido a: ' + e);
     });
  }

  delFoto(owner_name, key, id) {
    this.fotoService.delFoto(owner_name, key, id)
    .then(() => {
      this.modalService.dismissAll();
      this.fotoService.getFotosEncargoById(this.encargo._id.toString())
      .then(result => {
        this.fotosProyecto = result;
      });  
    });
  }

  delDocumento(owner_name, key, id) {
    this.editDocumentoForm.disable();
    this.loading = true;
    this.documentoService.delDocumento(owner_name, key, id)
    .then(() => {
      this.loading = false;
      this.modalService.dismissAll();
      this.documentoService.getDocumentosEncargoById(this.encargo._id.toString())
      .then(result => {
        this.documentosProyecto = result;
      });  
      this.documentoService.getPlanosEncargoById(this.encargo._id.toString())
      .then(result => {
        this.planosProyecto = result;
      });
    })
    .catch(e => {
      console.log(e);
    });
  }

  /**
   * Inicialitzar formulari de fotografia.
   * @assign Assigna valors buits a la variable @fotoForm
   */
  drawFotoForm(){
    this.fotoForm = this.fb.group({
      _id: [{value: '', disabled: true}],
      owner_name: [{value: '', disabled: true}],
      importFile: ['', Validators.required],
      texto: [''],
      ambitos: ['']
    });
  }

  drawEditFotoForm(){
    this.editFotoForm = this.fb.group({
      _id: [{value: '', disabled: true}],
      url: [''],
      owner_name: [{value: '', disabled: true}],
      texto: [''],
      ambitos: []
    });
  }

  /**
   * Inicialitzar formulari de document.
   * @assign Assigna valors buits a la variable @documentoForm
   */
  drawDocumentoForm(){
    this.documentoForm = this.fb.group({
      _id: [{value: '', disabled: true}],      
      fecha: ['', Validators.required],
      importFile: ['', Validators.required],
      descripcion: [''],
      ambito: [''],
      area: [''],
      tipo: ['']
    });
  }

  /**
   * Inicialitzar formulari de document.
   * @assign Assigna valors buits a la variable @documentoForm
   */
  drawEditDocumentoForm(){
    this.editDocumentoForm = this.fb.group({
      _id: [{value: '', disabled: true}],
      key: [{value: '', disabled: true}],
      url: [''],
      owner_name: [{value: '', disabled: true}],
      fecha: ['', Validators.required],
      descripcion: [''],
      ambito: [''],
      area: [''],
      tipo: ['']
    });
  }

  copyElementToClipBoard(element) {
    navigator.clipboard.writeText(element)
    alert ('Texto copiado al portapapeles');
  }
}